import { FC } from 'react';
import { Route } from 'react-router-dom';
import { useRemitReportingEntrypoint } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { PrimaryRemitReportsOverview } from 'src/pages/reporting/remit-reports/overview';
import { Forbidden } from 'src/utils/errors';

export const ReportingPrimaryRemitReports: FC<{}> = ({}) => {
  const remitReportingEntrypoint = useRemitReportingEntrypoint();

  if (!remitReportingEntrypoint.loaded) return <PageSpinner />;

  if (!remitReportingEntrypoint.response?.data._links?.['find-primary-reports'])
    throw new Forbidden();

  return (
    <RootRoutes>
      <Route
        path="/reporting/remit-reports/*"
        element={<PrimaryRemitReportsOverview />}
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
