import { toZonedTime } from 'date-fns-tz';
import { padStart } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { InputTime } from 'src/components/form/datepicker/utils';
import { FormModeProvider } from 'src/components/form/form-mode-context';
import { SimpleSelect } from 'src/components/form/select';
import { timeZone } from 'src/utils/date-time-format';
import styled from 'styled-components';

export function useTime(value: Date | null) {
  const valueInBerlin = value && toZonedTime(value.toISOString(), timeZone);
  const [hours, setHours] = useState<string>(
    valueInBerlin ? padStart(String(valueInBerlin.getHours()), 2, '0') : ''
  );

  const [minutes, setMinutes] = useState<string>(
    valueInBerlin ? padStart(String(valueInBerlin.getMinutes()), 2, '0') : ''
  );

  // Sync time when value is updated from outside
  useEffect(() => {
    if (!valueInBerlin) {
      setHours('');
      setMinutes('');
      return;
    }

    setHours(padStart(String(valueInBerlin.getHours()), 2, '0'));
    setMinutes(padStart(String(valueInBerlin.getMinutes()), 2, '0'));
  }, [valueInBerlin]);

  return {
    hours,
    minutes,
    setHours,
    setMinutes,
  };
}

const TimeSelectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
  width: 100%;
`;

const hoursOptions = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

const minutesOptions = ['00', '15', '30', '45'];

// Add minutes from value when not included in the default options.
const useMinutesOptions = (value: Date | null) =>
  useMemo(() => {
    if (!value) {
      return minutesOptions;
    }

    const minutes = padStart(String(value.getMinutes()), 2, '0');

    if (minutesOptions.includes(minutes)) {
      return minutesOptions;
    }

    return [minutes, ...minutesOptions];
  }, [value]);

type TimeSelectorProps = {
  namePrefix?: 'start' | 'end';
  value: Date | null;
  hours: string;
  minutes: string;
  onHoursChange: (hours: string) => void;
  onMinutesChange: (minutes: string) => void;
  disabled?: boolean;
  time?: Exclude<InputTime, false>;
};

export const TimeSelector: FC<TimeSelectorProps> = (props) => {
  const {
    hours,
    onHoursChange,
    minutes,
    onMinutesChange,
    value,
    namePrefix,
    disabled,
    time,
  } = props;
  const prefix = namePrefix ? `${namePrefix}-` : '';

  const minutesOptions = useMinutesOptions(value);

  return (
    <FormModeProvider mode="regular">
      <TimeSelectContainer>
        <SimpleSelect
          name={`${prefix}hours`}
          placeholder={
            time === 'gas-month' || time === 'gas-day'
              ? '06'
              : time === 'day-end'
                ? '23'
                : 'HH'
          }
          value={hours}
          options={hoursOptions.map((hour) => ({ label: hour, value: hour }))}
          onChange={onHoursChange}
          disabled={
            disabled ||
            time === 'gas-day' ||
            time === 'day-end' ||
            time === 'gas-month'
          }
        />

        <SimpleSelect
          name={`${prefix}minutes`}
          placeholder={
            time === 'full-hour' || time === 'gas-month' || time === 'gas-day'
              ? '00'
              : time === 'day-end'
                ? '59'
                : 'mm'
          }
          value={minutes}
          options={minutesOptions.map((minute) => ({
            label: minute,
            value: minute,
          }))}
          onChange={onMinutesChange}
          disabled={
            disabled ||
            time === 'full-hour' ||
            time === 'gas-day' ||
            time === 'day-end' ||
            time === 'gas-month'
          }
        />
      </TimeSelectContainer>
    </FormModeProvider>
  );
};
