import { FC, useEffect, useState } from 'react';
import { Modal } from 'src/components/overlay/modal';
import { useAccounts } from 'src/hooks/use-accounts';
import {
  AuthMessage,
  useAuthSessionChannel,
} from 'src/hooks/use-auth-session-channel';
import { useModal2 } from 'src/hooks/use-modal';
import { locationReload } from 'src/utils/location-usage';

export const AuthSessionMonitor: FC = () => {
  const accounts = useAccounts();
  const [message, setMessage] = useState<AuthMessage | null>(null);

  const modal = useModal2(
    message
      ? {
          modal: (props) => <WarningModal message={message} {...props} />,
          trigger: () => null,
        }
      : null
  );

  useEffect(() => {
    if (message) {
      modal?.open();
    } else {
      modal?.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useAuthSessionChannel((message) => {
    // check if the auth session changed to a new state or was restored to the original state of this tab
    const restoredInitialAuthSession =
      (message.event === 'login' &&
        accounts.current?.cognitoUserName === message.cognitoUserName) ||
      (message.event === 'logout' && !accounts.current);

    // hide the modal if the auth session was restored and
    // show the modal if the auth session changed to a new state
    if (restoredInitialAuthSession) setMessage(null);
    else setMessage(message);
  });

  return modal?.modal;
};

const WarningModal: FC<{
  message: AuthMessage;
  close: () => void;
}> = ({ message, close }) => {
  const accounts = useAccounts();
  const wasLoggedIn = Boolean(accounts.current);
  return (
    <Modal
      data-testid="auth-session-modal"
      title={wasLoggedIn ? 'Session Outdated' : 'New Session'}
      onDismiss={close}
      size="middle"
      footer={{
        confirmButton: {
          label: 'Reload Page',
          dataTestId: 'reload-page',
          onClick: () => locationReload(),
        },
        closeButton: {
          label: 'Dismiss',
        },
      }}
    >
      {message.event === 'login' && wasLoggedIn ? (
        <p>
          This page is using an outdated session because you've signed in with{' '}
          <strong>{message.email}</strong> in another tab.
          <br />
          Consider reloading this page to sync with the correct account.
        </p>
      ) : message.event === 'login' && !wasLoggedIn ? (
        <p>
          You've signed in with <strong>{message.email}</strong> in another tab.
          <br />
          Consider reloading this page to sync with the correct account.
        </p>
      ) : (
        <p>
          The session in this tab is no longer valid and it has changed because
          you have logged out in another tab.
          <br />
          Consider reloading this page to sync with the correct state.
        </p>
      )}
    </Modal>
  );
};
