import {
  addMonths,
  endOfMonth,
  startOfMonth,
  setHours,
  startOfDay,
} from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import { useMemo } from 'react';
import { useNow } from 'src/hooks/use-now';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getMyPortfolioPageParamsSchema } from 'src/pages/transport/network-points/details/my-portfolio-overview/schema';
import { timeZone } from 'src/utils/date-time-format';

export function useMyPortfolioPageParams() {
  const now = useNow();

  const { startDefault, endDefault } = useMemo(() => {
    const zonedNow = toZonedTime(now, timeZone);

    const start = setHours(startOfMonth(zonedNow), 6);
    const end = setHours(startOfDay(endOfMonth(addMonths(zonedNow, 1))), 6);

    return {
      startDefault: fromZonedTime(start, timeZone).toISOString(),
      endDefault: fromZonedTime(end, timeZone).toISOString(),
    };
  }, [now]);

  return usePageParamsFromSchema(
    getMyPortfolioPageParamsSchema({ startDefault, endDefault }),
    {
      filters: {
        granularity: { label: 'Time Unit' },
        runtimeIntersectionFilterStart: { label: 'Runtime' },
        runtimeIntersectionFilterEnd: { label: 'Runtime' },
      },
    }
  );
}

export type MyPortfolioPageParams = ReturnType<typeof useMyPortfolioPageParams>;
