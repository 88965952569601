import { FC, ReactNode } from 'react';
import { useFullwidth } from 'src/hooks/use-fullwidth';
import { Breakpoints } from 'src/styles';
import styled, { css } from 'styled-components';

const fullwidthStyle = css`
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`;

const responsiveStyle = css`
  width: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media (${Breakpoints.minTablet}) {
    width: 75rem;
  }

  @media (${Breakpoints.minDesktop}) {
    width: 97rem;
  }

  @media (${Breakpoints.minWideDesktop}) {
    width: 117rem;
  }
`;

const Styled = styled.div<{ fullwidth: boolean }>`
  margin-right: auto;
  margin-left: auto;

  transition: width 300ms ease-out;

  ${({ fullwidth }) => (fullwidth ? fullwidthStyle : responsiveStyle)};

  /* Force full width on print */
  @media print {
    ${fullwidthStyle};
  }
`;

export type ContainerProps = { ignoreFullwidth?: boolean; children: ReactNode };

export const Container: FC<ContainerProps> = ({
  ignoreFullwidth,
  children,
}) => {
  const [fullwidth] = useFullwidth();
  return (
    <Styled fullwidth={ignoreFullwidth ? false : fullwidth}>{children}</Styled>
  );
};
