import { fromShipperDocumentsSortByValue } from 'src/apis/document-exchange/mappings';
import {
  formDescSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { z } from 'zod';

export const fromShipperDocumentsPageParamsSchema = z
  .object({
    sortBy: z.enum(fromShipperDocumentsSortByValue).default('changedAt'),
    sortDirection: formDescSortDirectionSchema,
  })
  .merge(getPaginationSchema());
