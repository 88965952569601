import { FC } from 'react';
import { OperatorRequest } from 'src/apis/contract-management/use-operator-by-tso';
import { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Card } from 'src/components/data-display/card';
import { DataItems, DataList } from 'src/components/data-display/data-list';
import { Stack } from 'src/components/layout/stack';
import { Heading } from 'src/components/text/heading';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { CancelOrganizationAssignmentButton } from 'src/pages/operators/assignments/details/components/cancel-organization-assignment-button';
import { AssignmentStatusDisplayMonolith } from 'src/pages/operators/assignments/overview/components/assignment-status-monolith';
import { AssignmentsOrganisationItem } from 'src/pages/operators/assignments/overview/types';

type Props = {
  orgAssignment: AssignmentsOrganisationItem & { transferCompanyData: boolean };
  caMaOperator?: OperatorRequest;
  tsoId: string;
  operatorDetails: PublicOperatorDetailsResponse;
  showAssignmentsButton?: boolean;
  title: string;
  onCancelSuccess?: () => void;
};

export const AssignmentDetailsOrganisationStatus: FC<Props> = ({
  orgAssignment,
  caMaOperator,
  tsoId,
  operatorDetails,
  showAssignmentsButton = true,
  title,
  onCancelSuccess,
}) => {
  const currentUser = useAuthenticatedMonolithUser();
  return (
    <Card data-testid="organisation-assignment-card">
      <Stack gap={1} alignItems="center">
        <Stack gap={1} autoColumns="1fr auto" flow="column">
          <Heading mode="card">{title}</Heading>
          {showAssignmentsButton
            ? currentUser.role === 'SHIPPER_ADMIN' &&
              (orgAssignment.status === 'APPROVED' ||
              orgAssignment.status === 'NOT_APPROVED' ? (
                <CancelOrganizationAssignmentButton
                  tsoId={tsoId}
                  operatorName={operatorDetails.registeredAddress.companyName}
                  onCancelSuccess={onCancelSuccess}
                />
              ) : null)
            : null}
        </Stack>
        <Stack flow="row" gap={1} justifyContent="space-between">
          <AssignmentStatusDisplayMonolith
            value={orgAssignment.status}
            {...(orgAssignment.marketAccess && {
              marketAccess: orgAssignment.marketAccess,
            })}
            data-testid="organisation-assignment-status"
          />

          {(caMaOperator?.response?.data ||
            orgAssignment.status === 'NOT_APPROVED') && (
            <div data-testid="organisation-assignment-info">
              <DataList>
                <DataItems>
                  {!!caMaOperator?.response?.data.allowsNomination && (
                    <>
                      <span>Nomination</span>
                      <span>Allowed</span>
                    </>
                  )}
                </DataItems>
              </DataList>
            </div>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
