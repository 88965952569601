import { FC, HTMLAttributes, ReactNode } from 'react';
import { Stack } from 'src/components/layout/stack';
import { Hint } from 'src/components/text/hint';
import { Colors } from 'src/styles';
import styled, { css } from 'styled-components';

export const cardGap = '1.5rem';

const CardStyle = styled.div<{ mode?: 'default' | 'highlight' }>`
  padding: ${cardGap};
  border: 0.1rem solid ${Colors.brandLight3};
  background-color: white;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);

  ${({ mode }) =>
    mode === 'highlight' &&
    css`
      border: none;
      background-color: ${Colors.brandLight5};
      box-shadow: none;
    `}
`;

export const interactiveCardStyle = css`
  &:hover,
  &:focus-visible {
    background-color: ${Colors.brandLight4};
    cursor: pointer;
  }
`;

type CardComponentProps = {
  children: ReactNode;
  hint?: string;
  mode?: 'default' | 'highlight';
} & HTMLAttributes<HTMLDivElement>;

export const Card: FC<CardComponentProps> = (props) => {
  const { children, hint, ...rest } = props;
  return (
    <Stack gap={hint ? 1 : 0} alignItems="stretch" templateRows="1fr auto">
      <CardStyle {...rest}>{children}</CardStyle>
      {hint && <Hint mode="regular" children={hint} />}
    </Stack>
  );
};

export const InteractiveCard = styled(Card)`
  ${interactiveCardStyle};
  border-color: ${(props) => props['aria-checked'] && Colors.brand};
`;

export const SelectableCard = styled(InteractiveCard)<{ isSelected: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${Colors.brand};
    `}
`;
