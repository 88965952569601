import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { DialogProps } from '@reach/dialog';
import { FC } from 'react';
import { CancelAssignmentRequest } from 'src/apis/monolith/use-cancel-assignment';
import { Modal } from 'src/components/overlay/modal';
import { useCountdown } from 'src/hooks/use-countdown';

type Props = Pick<DialogProps, 'onDismiss'> & {
  cancelAssignment: CancelAssignmentRequest;
  operatorName: string;
  userName: string;
};

export const CancelAssignmentModal: FC<Props> = ({
  onDismiss,
  cancelAssignment,
  userName,
  operatorName,
}) => {
  const seconds = useCountdown();
  return (
    <Modal
      title="Cancel Assignment"
      icon={faBan}
      onDismiss={onDismiss}
      footer={{
        confirmButton: {
          label: seconds
            ? `Wait ${seconds}s to proceed...`
            : cancelAssignment.pending
              ? 'Processing...'
              : 'Cancel Assignment',
          onClick: () => cancelAssignment.execute(),
          dataTestId: seconds === 0 ? 'cancel-assignment-submit' : undefined,
          disabled: cancelAssignment.pending || seconds !== 0,
        },
      }}
    >
      <p data-testid="cancel-assignment-modal-description">
        Please, confirm that you want to cancel assignment of{' '}
        <strong>{userName}</strong> to <strong>{operatorName}</strong>.
      </p>
    </Modal>
  );
};
