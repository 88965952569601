/* eslint-disable rulesdir/safe-local-storage */
import { createContext, useContext } from 'react';

// localStorage can be unavailable for several reasons
// (e.g. see https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/)
let isSupported = true;
try {
  const key = '____________test_local_storage';
  localStorage.setItem(key, '');
  localStorage.getItem(key);
  localStorage.removeItem(key);
} catch (err) {
  // catch "SecurityError: The operation is insecure.", if cookies are blocked
  // or "Cannot read property 'getItem' of null", if storage is not enabled on Android
  // or quote exceeded
  // or...
  isSupported = false;
}

export const isLocalStorageSupported = isSupported;

// this is just a dumb mock of the storage interface
export class MemoryStorage implements Storage {
  private data: Record<string, string> = {};

  getItem(key: string) {
    return this.data[key] ?? null;
  }

  setItem(key: string, value: string) {
    this.data[key] = value;
  }

  removeItem(key: string) {
    delete this.data[key];
  }

  clear() {
    this.data = {};
  }

  get length() {
    return Object.keys(this.data).length;
  }

  key(index: number) {
    return Object.keys(this.data)[index] ?? null;
  }
}

/**
 * This is only exported for unit tests currently to replace `localStorage`.
 * You likely don't want to use it directly.
 */
export const memoryStorage = new MemoryStorage();

const internalLocalStorage = isSupported ? localStorage : memoryStorage;

const LocalStorageContext = createContext(internalLocalStorage);
export function useSafeLocalStorage() {
  return useContext(LocalStorageContext);
}
export const LocalStorageProvider = LocalStorageContext.Provider;

const SessionStorageContext = createContext(sessionStorage);
export function useSessionStorage() {
  return useContext(SessionStorageContext);
}
export const SessionStorageProvider = SessionStorageContext.Provider;
