import { useEffect } from 'react';
import { RequiredDocumentsResponse } from 'src/apis/assignment-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useRequiredDocuments(url: string) {
  const requiredDocuments = useAxios(
    (axios, baseConfig) =>
      axios.request<RequiredDocumentsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    requiredDocuments.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return requiredDocuments;
}

export type RequiredDocumentsRequest = ReturnType<typeof useRequiredDocuments>;
