import { FC, forwardRef, InputHTMLAttributes, useId } from 'react';
import { ErrorMessage } from 'src/components/form/error-message';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import { FormFieldProps } from 'src/components/form/form-field-props';
import { FormOption } from 'src/components/form/form-option';
import { FieldLabel } from 'src/components/form/label';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';

import { centeredIconOffset, Colors, focusStyle } from 'src/styles';
import styled, { css } from 'styled-components';

export const radioSize = 1.5;
const innerSize = 1.4;
const innerCircle = 0.6;

const HiddenInput = styled.input`
  clip: rect(0 0 0 0);
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const RadioIndicator = styled.div<{
  showError?: boolean;
  disabledState?: boolean;
}>`
  cursor: pointer;
  width: ${radioSize}rem;
  height: ${radioSize}rem;
  background-color: ${Colors.brand};
  border-radius: 50%;
  display: inline-block;
  vertical-align: -${centeredIconOffset};

  &::after {
    background-color: white;
    width: ${innerSize}rem;
    height: ${innerSize}rem;
    border-radius: 50%;
    content: '';
    display: block;
    transform: translate(
      ${(radioSize - innerSize) / 2}rem,
      ${(radioSize - innerSize) / 2}rem
    );
    ${({ showError }) =>
      showError &&
      `
      background-color: ${Colors.errorLighter};
    `}
    ${({ disabledState }) =>
      disabledState &&
      `
      background-color: white;
      border: ${Colors.inactive};
    `}
  }

  ${HiddenInput}:checked ~ &::after {
    background-color: white;
    width: ${innerCircle}rem;
    height: ${innerCircle}rem;
    border-radius: 50%;
    content: '';
    display: block;
    transform: translate(
      ${(radioSize - innerCircle) / 2}rem,
      ${(radioSize - innerCircle) / 2}rem
    );
  }

  ${HiddenInput}:focus ~ & {
    ${focusStyle}
  }

  ${({ showError }) =>
    showError &&
    `
      background-color: ${Colors.error};
    `}

  ${({ disabledState }) =>
    disabledState &&
    `
      background-color: ${Colors.inactive};
      cursor: not-allowed;
    `}
`;

export const RadioLabel = styled.span<{
  showError?: boolean;
  disabled?: boolean;
}>`
  cursor: pointer;
  user-select: none;
  color: ${Colors.brand};
  ${({ showError }) =>
    showError &&
    css`
      color: ${Colors.error};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${Colors.inactive};
    `};
`;

type RadioInputProps = InputHTMLAttributes<HTMLInputElement> & {
  showError: boolean;
};

export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  (props, ref) => {
    const { disabled, showError, ...rest } = props;

    return (
      <span>
        <HiddenInput type="radio" ref={ref} {...rest} disabled={disabled} />
        <RadioIndicator showError={showError} disabledState={disabled} />
      </span>
    );
  }
);

type SimpleRadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  showError: boolean;
};

export const SimpleRadio = forwardRef<HTMLInputElement, SimpleRadioProps>(
  (props, ref) => {
    const { showError, label, ...inputProps } = props;
    return (
      <Stack
        flow="column"
        as="label"
        gap={0.5}
        inline
        alignItems="baseline"
        justifyContent="start"
      >
        {/* empty span needed for firefox */}
        <span>
          <RadioInput ref={ref} showError={showError} {...inputProps} />
        </span>
        <RadioLabel
          showError={showError}
          disabled={inputProps.disabled}
          style={inputProps.style}
        >
          {label}
        </RadioLabel>
      </Stack>
    );
  }
);

type RadiosProps = {
  options: FormOption<string>[];
  stacked?: boolean;
  stackedOptions?: boolean;
} & FormFieldProps &
  InputHTMLAttributes<HTMLInputElement>;

/**
 * Use this if you have a short list of options (e.g. 2-5) where the user can only select one.
 *
 * If you have a long list of options, consider using the [Single Select](../?path=/docs/components-form-single-select--docs) component.
 *
 * If you have a long list of options where you want to search, consider using the [Searchable Single Select](../?path=/docs/components-form-searchable-single-select--docs) component.
 */
export const Radios: FC<RadiosProps> = (props) => {
  const { isStacked } = useDefaultStacked();
  const {
    label,
    name,
    options,
    checked,
    stacked = isStacked,
    autoFocus,
    stackedOptions,
    hint,
    hideLabel = false,
    info,
    markAsRequired,
    disabledMessage,
    ...inputProps
  } = props;
  const fieldId = useId();

  const [field, showError, error] = useCustomField(name, label);
  const focusRef = useFocusManager();

  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;

  return (
    <FieldLayout stacked={stacked}>
      {!hideLabel && (
        <FieldLabel
          name={name}
          displayLabel={displayLabel}
          fieldId={fieldId}
          info={info}
          showError={showError}
        />
      )}

      <FieldItem>
        {/* div is needed for layout */}
        <div>
          <Stack
            gap={stackedOptions ? 0.5 : 3.5} // same as checkbox group
            inline
            alignItems="start"
            flow={stackedOptions ? 'row' : 'column'}
            data-testid={field.name}
            data-input-type="radio" // used in unit tests
            data-input-value={field.value} // used in unit tests
          >
            {options.map((option, index) => (
              <Tooltip
                content={
                  inputProps.disabled && disabledMessage
                    ? disabledMessage
                    : undefined
                }
                key={option.value}
              >
                {(targetProps) => (
                  <SimpleRadio
                    // @ts-expect-error
                    ref={focusRef}
                    label={option.label}
                    showError={showError}
                    autoFocus={index === 0 && autoFocus}
                    id={index === 0 ? fieldId : undefined}
                    data-testid={`${field.name}:${option.value}`}
                    {...field}
                    {...inputProps}
                    value={option.value}
                    checked={option.value === field.value}
                    disabled={inputProps.disabled}
                    {...targetProps}
                  />
                )}
              </Tooltip>
            ))}
          </Stack>
        </div>
        {hint && (
          <Hint disabled={props.disabled} mode="formInput" children={hint} />
        )}
        {showError && error && (
          <ErrorMessage
            data-testid={`${name}Error`}
            error={error}
            label={label}
          />
        )}
      </FieldItem>
    </FieldLayout>
  );
};
