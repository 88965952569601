// inspired by https://github.com/streamich/react-use/blob/master/src/useMeasure.ts
import { useLayoutEffect, useMemo, useState } from 'react';

export type RectReadOnly = Pick<
  DOMRectReadOnly,
  'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>;

const defaultState: RectReadOnly = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

export function useMeasure() {
  const [element, ref] = useState<HTMLDivElement | null>(null);
  const [rect, setRect] = useState(defaultState);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        if (entries[0]) {
          setRect(entries[0].target.getBoundingClientRect());
        }
      }),
    []
  );

  useLayoutEffect(() => {
    if (!element) return;
    setRect(element.getBoundingClientRect());
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  return [ref, rect] as const;
}
