import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { GroupWrap } from 'src/components/group-wrap';
import { ActiveSection } from 'src/components/layout/header/types';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useCognitoUser } from 'src/hooks/use-cognito-user';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';

export const isLngNewActive = (
  pathname: string,
  hash: string,
  newLngUsed: boolean
) =>
  newLngUsed &&
  (pathname.startsWith('/lng') ||
    (pathname.startsWith('/platform') && hash.startsWith('#/lng')));

export const SecondaryNavLngNew: FC<{ activeSection: ActiveSection }> = ({
  activeSection,
}) => {
  const { hash } = useLocation();
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const cognitoUser = useCognitoUser();
  const isAuthenticated = Boolean(cognitoUser);
  const { minTablet } = useBreakpoints();

  if (!activeSection.lngNew) return null;

  return (
    <GroupWrap
      gap={[1, 2]}
      data-testid="secondary-nav"
      alignItems={!minTablet ? 'stretch' : 'center'}
      flexDirection={!minTablet ? 'column' : 'row'}
    >
      <Link mode="secondary" to="/lng/offers">
        LNG Offers
      </Link>

      {/* !!! old links listed below as a temporary solution */}

      {(!isAuthenticated || monolithUser?.isShipper) && (
        <ExternalLink
          mode="secondary"
          href={`${PRISMA_CONFIG.angularUrl}/#/lng/offers`}
          isActive={hash.startsWith('#/lng/offers')}
        >
          Archive
        </ExternalLink>
      )}

      {monolithUser?.isSso && (
        <>
          <ExternalLink
            mode="secondary"
            href={`${PRISMA_CONFIG.angularUrl}/#/lng/offers`}
            isActive={
              hash.startsWith('#/lng/offers') &&
              !hash.startsWith('#/lng/offers/create')
            }
          >
            Archive
          </ExternalLink>

          {/* locations left out intentionally */
          /*
          <ExternalLink
            mode="secondary"
            href={`${PRISMA_CONFIG.angularUrl}/#/lng/locations`}
          >
            Locations
          </ExternalLink> */}
        </>
      )}
    </GroupWrap>
  );
};
