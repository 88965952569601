import { FC, lazy } from 'react';
import { ExternalTradeGuard } from 'src/pages/reporting/secondary-tradings/external-trades/common/guards';

const Page = lazy(() => import('./page'));

export const EnterExternalTrade: FC = () => {
  return (
    <ExternalTradeGuard>
      <Page />
    </ExternalTradeGuard>
  );
};
