import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldInputProps, useFormikContext } from 'formik';
import { flattenDeep, uniq } from 'lodash';
import { FC, ReactElement, Fragment, InputHTMLAttributes, useId } from 'react';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { SimpleCheckbox } from 'src/components/form/checkbox';
import { ErrorMessage } from 'src/components/form/error-message';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import { FormFieldProps } from 'src/components/form/form-field-props';
import { FormOption } from 'src/components/form/form-option';
import { FieldLabel } from 'src/components/form/label';
import {
  applyGroupSortBy,
  applySortBy,
  SortByOptions,
} from 'src/components/form/select/utils';
import { useCustomField } from 'src/components/form/use-custom-field';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';

export type CheckboxOption<Value extends string = string> =
  FormOption<Value> & {
    disabled?: boolean;
    disabledMessage?: ReactElement | string;
    hint?: ReactElement | string;
    /**
     * Use this in case the hint is used _somewhere else_ besides the regular usage.
     * E.g. in a `SearchableMultiSelect` the hint is used to be shown as a tooltip
     * for the selected item.
     */
    hideHint?: boolean;
    info?: ReactElement | string;
  };

export type GroupedFormOption<T extends string = string> = {
  label: string;
  disabled?: boolean;
  disabledMessage?: ReactElement | string;
  options: CheckboxOption<T>[];
  hint?: ReactElement | string;
  info?: ReactElement | string;
};

type CheckboxGroupProps = {
  options: CheckboxOption<string>[] | GroupedFormOption<string>[];
  stacked?: boolean;
  stackedOptions?: boolean;
  toggleAll?: boolean;
  sortBy?: SortByOptions;
} & FormFieldProps &
  InputHTMLAttributes<HTMLInputElement>;

/**
 * Use this if you have a short list of options (e.g. 2-5) or enough screen space where the user can select multiple values.
 *
 * If you have a long list of options or need to save screen space, consider using the [Multi Select](../?path=/docs/components-form-multi-select--docs) component.
 *
 * If you have a long list of options where you want to search, consider using the [Suggestions Input](../?path=/docs/components-form-suggestions-input--docs) component.
 */
export const CheckboxGroup: FC<CheckboxGroupProps> = (props) => {
  const { isStacked } = useDefaultStacked();
  const {
    label,
    name,
    options,
    stacked = isStacked,
    stackedOptions = false,
    autoFocus,
    toggleAll = false,
    hideLabel = false,
    hint,
    info,
    markAsRequired = false,
    sortBy,
    ...inputProps
  } = props;

  const fieldId = useId();

  const { setFieldValue } = useFormikContext<any>();
  const [field, showError, error] = useCustomField<string[]>(name, label);

  const { optionGroups, allChecked, toggleAllChecked } = normalizeGroupOptions(
    field.value,
    options,
    sortBy
  );

  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;

  const fieldItem = (
    <FieldItem>
      {/* div is needed for layout */}
      {options.length > 0 ? (
        <div>
          <Stack gap={2.5} inline alignItems="start">
            {toggleAll && (
              <strong>
                <SimpleCheckbox
                  showError={showError}
                  label={allChecked ? 'Unselect All' : 'Select All'}
                  checked={toggleAllChecked}
                  onChange={() => {
                    if (toggleAllChecked !== true) {
                      setFieldValue(
                        field.name,
                        flattenDeep(
                          optionGroups.map((optionGroup) =>
                            optionGroup.options.map((option) => option.value)
                          )
                        )
                      );
                    } else {
                      setFieldValue(field.name, []);
                    }
                  }}
                />
              </strong>
            )}

            {optionGroups.map((optionGroup, index) => (
              <Fragment key={optionGroup.label}>
                <OptionGroup
                  name={name}
                  optionGroup={optionGroup}
                  stackedOptions={optionGroup.label ? true : stackedOptions}
                  field={field}
                  showError={showError}
                  id={index === 0 ? fieldId : undefined}
                  {...inputProps}
                />
              </Fragment>
            ))}
          </Stack>
        </div>
      ) : (
        <EmptyValue label="No Options Available" />
      )}

      {hint && (
        <Hint disabled={props.disabled} mode="formInput" children={hint} />
      )}

      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </FieldItem>
  );

  return hideLabel ? (
    fieldItem
  ) : (
    <FieldLayout stacked={stacked}>
      <FieldLabel
        name={name}
        displayLabel={displayLabel}
        fieldId={fieldId}
        info={info}
        showError={showError}
      />

      {fieldItem}
    </FieldLayout>
  );
};

export function normalizeGroupOptions<Value extends string = string>(
  value: Value[],
  unsortedOptions: CheckboxOption<Value>[] | GroupedFormOption<Value>[],
  sortBy: SortByOptions = 'label'
) {
  const unsortedOptionGroups = flattenGroupOptions(unsortedOptions);

  const optionGroups = applyGroupSortBy(sortBy, unsortedOptionGroups).map(
    (optionGroup) => ({
      ...optionGroup,
      options: applySortBy(sortBy, optionGroup.options),
    })
  );

  const checks = optionGroups.map((optionGroup) =>
    optionGroup.options.map((option) =>
      value.some((value) => value === option.value)
    )
  );

  const allChecked = flattenDeep(checks).every((check) => check);

  const someChecked = flattenDeep(checks).some((check) => check);

  const toggleAllChecked = allChecked
    ? true
    : someChecked
      ? ('mixed' as const)
      : false;

  return {
    optionGroups,
    allChecked,
    toggleAllChecked,
  };
}

function flattenGroupOptions<Value extends string | string>(
  options: CheckboxOption<Value>[] | GroupedFormOption<Value>[]
): GroupedFormOption<Value>[] {
  if (!options.length) return [{ label: '', options: [] }];

  if ('options' in options[0]) return options as GroupedFormOption<Value>[];

  return [{ label: '', options }] as GroupedFormOption<Value>[];
}

type OptionGroupProps = {
  name: string;
  optionGroup: GroupedFormOption<string>;
  stackedOptions?: boolean;
  field: FieldInputProps<string[]>;
  showError: boolean;
  disabledMessage?: ReactElement | string;
} & InputHTMLAttributes<HTMLInputElement>;

const OptionGroup: FC<OptionGroupProps> = (props) => {
  const {
    optionGroup: {
      options,
      label: label,
      disabled: groupDisabled,
      disabledMessage: groupDisabledMessage,
      hint,
      info,
    },
    name,
    stackedOptions = false,
    field,
    showError,
    disabledMessage,
    ...inputProps
  } = props;

  const { setFieldValue } = useFormikContext<any>();

  const childChecked = options.map((option) => {
    return Boolean(field.value.find((value) => value === option.value));
  });

  const allChildChecks = childChecked.every((check) => check);
  const someChildChecks = childChecked.some((check) => check);

  const toggleAllChildChecked = allChildChecks
    ? true
    : someChildChecks
      ? ('mixed' as const)
      : false;

  return (
    <div>
      <Stack
        gap={stackedOptions ? 0.5 : 3.5} // same as radios
        inline
        alignItems="start"
        flow={stackedOptions ? 'row' : 'column'}
      >
        {label && (
          <div>
            <div>
              <strong>
                <SimpleCheckbox
                  label={label}
                  data-testid={label}
                  checked={toggleAllChildChecked}
                  onChange={() => {
                    if (toggleAllChildChecked !== true) {
                      setFieldValue(
                        field.name,
                        uniq([
                          ...field.value,
                          ...options.map(({ value }) => value),
                        ])
                      );
                    } else {
                      setFieldValue(
                        field.name,
                        field.value.filter(
                          (value) =>
                            !options.some((option) => option.value === value)
                        )
                      );
                    }
                  }}
                  showError={showError}
                  disabled={inputProps.disabled || groupDisabled}
                  disabledMessage={
                    inputProps.disabled && disabledMessage
                      ? disabledMessage
                      : groupDisabled && groupDisabledMessage
                        ? groupDisabledMessage
                        : undefined
                  }
                />
              </strong>
              {info && (
                <>
                  {' '}
                  <Tooltip content={info}>
                    {(targetProps) => (
                      <FontAwesomeIcon {...targetProps} icon={faInfoCircle} />
                    )}
                  </Tooltip>
                </>
              )}
            </div>

            {hint && (
              <Hint
                disabled={inputProps.disabled || groupDisabled}
                mode="formInput"
                children={hint}
              />
            )}
          </div>
        )}

        {options.map((option, index) => (
          <div key={option.value}>
            <div>
              <WrappedCheckbox
                option={option}
                checked={childChecked[index]}
                field={field}
                showError={showError}
                data-testid={`${field.name}:${option.value}`}
                {...inputProps}
                disabled={
                  inputProps.disabled || groupDisabled || option.disabled
                }
                disabledMessage={
                  inputProps.disabled && disabledMessage
                    ? disabledMessage
                    : groupDisabled && groupDisabledMessage
                      ? groupDisabledMessage
                      : option.disabled && option.disabledMessage
                        ? option.disabledMessage
                        : undefined
                }
              />

              {option.info && (
                <>
                  {' '}
                  <Tooltip content={option.info}>
                    {(targetProps) => (
                      <FontAwesomeIcon {...targetProps} icon={faInfoCircle} />
                    )}
                  </Tooltip>
                </>
              )}
            </div>

            {option.hint && !option.hideHint && (
              <Hint
                children={option.hint}
                mode="formInput"
                disabled={
                  inputProps.disabled || groupDisabled || option.disabled
                }
                data-testid={`${field.name}:${option.value}Hint`}
              />
            )}
          </div>
        ))}
      </Stack>
    </div>
  );
};

type WrappedCheckboxProps = {
  option: CheckboxOption<string>;
  checked?: boolean;
  field: FieldInputProps<string[]>;
  showError: boolean;
  disabledMessage?: ReactElement | string;
} & InputHTMLAttributes<HTMLInputElement>;

const WrappedCheckbox: FC<WrappedCheckboxProps> = ({
  option,
  checked,
  field,
  showError,
  disabledMessage,
  ...inputProps
}) => {
  const { setFieldValue } = useFormikContext<any>();
  return (
    <SimpleCheckbox
      label={option.label}
      {...field}
      {...inputProps}
      data-value={option.value}
      value={option.value}
      checked={checked}
      onChange={() => {
        if (!checked) {
          setFieldValue(field.name, [...field.value, option.value]);
        } else {
          const index = field.value.findIndex(
            (value) => value === option.value
          );
          setFieldValue(field.name, [
            ...field.value.slice(0, index),
            ...field.value.slice(index + 1),
          ]);
        }
      }}
      showError={showError}
      disabledMessage={disabledMessage}
    />
  );
};
