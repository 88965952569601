import { lazy } from 'react';
import { useSurrenderRequest } from 'src/apis/surrender-service/use-surrender-request';
import { useSurrenderRoot } from 'src/apis/surrender-service/use-surrender-root';
import { PageSpinner } from 'src/components/spinner-container';

const Page = lazy(() => import('./page'));

export const SurrenderRequestDetail = () => {
  const surrenderRoot = useSurrenderRoot();
  const surrenderRequest = useSurrenderRequest(surrenderRoot);

  if (!surrenderRoot.response || !surrenderRequest.response)
    return <PageSpinner />;

  return (
    <Page surrenderRoot={surrenderRoot} surrenderRequest={surrenderRequest} />
  );
};
