import { FC, ReactNode } from 'react';
import { useRemitReportingEntrypoint } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { PageSpinner } from 'src/components/spinner-container';
import { Forbidden } from 'src/utils/errors';

export const ExternalTradeGuard: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const remitReportingEntrypoint = useRemitReportingEntrypoint();

  if (!remitReportingEntrypoint.loaded) return <PageSpinner />;

  if (
    !remitReportingEntrypoint.response?.data._links?.['create-external-trade']
  ) {
    throw new Forbidden();
  }

  return <>{children}</>;
};
