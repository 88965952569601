import { DirectFcfsOrganizationApiNotificationConfig } from 'src/apis/direct-fcfs/types';
import { useAxios } from 'src/hooks/use-axios';

const organisationApiUrl =
  PRISMA_CONFIG.directFcfs + '/organisation-api-configuration';

export function useOrganizationApiNotificationConfigUpdate() {
  return useAxios(
    (axios, baseConfig, data: DirectFcfsOrganizationApiNotificationConfig) =>
      axios.request<void>({
        ...baseConfig,
        url: organisationApiUrl,
        data,
        method: 'POST',
      }),
    {
      neededOnPageLoad: false,
    }
  );
}

export type OrganizationApiNotificationConfigUpdateRequest = ReturnType<
  typeof useOrganizationApiNotificationConfigUpdate
>;
