import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { storageOffersPageParamsSchema } from 'src/pages/storage/offers/schema';

export function usePageParams() {
  return usePageParamsFromSchema(storageOffersPageParamsSchema, {
    filters: {
      id: { label: 'Offer ID' },
      offerLabel: { label: 'Offer Label' },
      storageLocationName: { label: 'Storage Location' },
      tsoShortName: { label: 'SSO' },
      productType: { label: 'Product Type' },
      runtimeFrom: { label: 'Runtime' },
      runtimeTo: { label: 'Runtime' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;
