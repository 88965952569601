import { useEffect } from 'react';
import { DirectFcfsOrganizationApiNotificationConfig } from 'src/apis/direct-fcfs/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

const organisationApiUrl =
  PRISMA_CONFIG.directFcfs + '/organisation-api-configuration';

export function useOrganizationApiNotificationConfig() {
  const organizationApiNotificationConfig = useAxios(
    (axios, baseConfig) =>
      axios.request<DirectFcfsOrganizationApiNotificationConfig>({
        ...baseConfig,
        url: organisationApiUrl,
        method: 'GET',
      }),
    {
      neededOnPageLoad: false,
      onError(error) {
        if (isServerError(error, 404)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    organizationApiNotificationConfig.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return organizationApiNotificationConfig;
}

export type OrganizationApiNotificationConfigRequest = ReturnType<
  typeof useOrganizationApiNotificationConfig
>;
