import { FC } from 'react';
import { ShipperUser } from 'src/apis/monolith/types';
import { ShipperUserRequest } from 'src/apis/monolith/use-shipper-user';
import { UserAssignmentRequest } from 'src/apis/monolith/use-user-assignment';
import { Card } from 'src/components/data-display/card';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { Alert } from 'src/components/feedback/alert';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { Successful } from 'src/hooks/use-axios';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useSaveReferrer } from 'src/hooks/use-referrer';
import { CancelAssignmentButton } from 'src/pages/operators/assignments/details/components/cancel-assignment-button';
import { AssignmentStatusDisplayMonolith } from 'src/pages/operators/assignments/overview/components/assignment-status-monolith';
import { AssignmentsOrganisationItem } from 'src/pages/operators/assignments/overview/types';
import { StrictOmit } from 'src/utils/utility-types';

type Props = {
  tsoId: string;
  userId: string;
  orgAssignment: AssignmentsOrganisationItem;
  userAssignment: Successful<UserAssignmentRequest>;
  shipperUser: ShipperUserRequest;
  operatorName: string;
  onCancelSuccess: () => void;
};

export const AssignmentDetailsUserStatus: FC<Props> = ({
  shipperUser,
  ...rest
}) => {
  const currentUser = useAuthenticatedMonolithUser();

  if (currentUser.userId!.toString() === rest.userId) {
    return <AssignmentDetailsWithAuth {...rest} title="My User Assignment" />;
  } else {
    return (
      <AssignmentDetailsWithUserQuery
        {...rest}
        title="My User Assignment"
        shipperUser={shipperUser}
      />
    );
  }
};

const AssignmentDetailsWithUserQuery: FC<Props & { title: string }> = ({
  shipperUser,
  ...rest
}) => {
  if (shipperUser.error) {
    return (
      <Card>
        <Heading mode="card">User Status</Heading>
        <Alert type="warning">This user was deactivated.</Alert>
      </Card>
    );
  }

  if (!(shipperUser.response || shipperUser.error)) {
    return <Card>Loading user data...</Card>;
  }

  return (
    <AssignmentDetailsUserStatusComponent
      user={{
        referenceId: shipperUser.response!.data.referenceId,
        firstName: shipperUser.response!.data.firstName,
        lastName: shipperUser.response!.data.lastName,
      }}
      {...rest}
    />
  );
};

const AssignmentDetailsWithAuth: FC<
  StrictOmit<Props, 'shipperUser'> & { title: string }
> = (props) => {
  const currentUser = useAuthenticatedMonolithUser();

  return (
    <AssignmentDetailsUserStatusComponent
      user={{
        referenceId: currentUser.userReferenceId!,
        firstName: currentUser.firstName!,
        lastName: currentUser.lastName!,
      }}
      {...props}
    />
  );
};

export const AssignmentDetailsUserStatusComponent: FC<{
  user: Pick<ShipperUser, 'referenceId' | 'firstName' | 'lastName'>;
  tsoId: string;
  userId: string;
  userAssignment: Successful<UserAssignmentRequest>;
  operatorName: string;
  title: string;
  showAssignmentsButton?: boolean;
  onCancelSuccess?: () => void;
}> = ({
  showAssignmentsButton = true,
  title,
  user,
  tsoId,
  userId,
  operatorName,
  userAssignment,
  onCancelSuccess,
}) => {
  const currentUser = useAuthenticatedMonolithUser();
  const savedReferrer = useSaveReferrer({ label: 'Operator Details' });

  const isPreviewingSelf = currentUser.userReferenceId === user.referenceId;

  return (
    <Card data-testid="user-assignment-card">
      <Stack gap={1}>
        <Stack gap={1} autoColumns="1fr auto" flow="column">
          <Heading mode="card">
            {isPreviewingSelf
              ? title
              : `${user.firstName} ${user.lastName}'s Assignment`}
          </Heading>

          {currentUser.role === 'SHIPPER_ADMIN' && showAssignmentsButton ? (
            userAssignment.response.data.state !== 'CANCELED' &&
            userAssignment.response.data.state !== 'NOT_REQUESTED' ? (
              <CancelAssignmentButton
                user={{ firstName: user.firstName, lastName: user.lastName }}
                userId={userId}
                tsoId={tsoId}
                operatorName={operatorName}
                onCancelSuccess={onCancelSuccess}
              />
            ) : (
              <Link
                data-testid="apply-for-assignment-btn"
                {...savedReferrer.getLinkProps({
                  pathname: '/operators/assignments/apply-for-assignment',
                  state: {
                    tsoId,
                    shipperUserId: userId,
                    shipperUserRefId: user.referenceId,
                  },
                })}
                size="small"
                mode="button-primary"
              >
                Apply for Assignment
              </Link>
            )
          ) : null}
        </Stack>

        {currentUser.role === 'SHIPPER_USER' && (
          <Stack flow="column" gap={1} justifyContent="space-between">
            {userAssignment.response.data.state === 'NOT_REQUESTED' ? (
              <EmptyValue label="You are not assigned to this Operator. Please contact your administrator in case you want to apply for an assignment." />
            ) : (
              <AssignmentStatusDisplayMonolith
                value={userAssignment.response.data.state}
                data-testid="user-assignment-status"
              />
            )}
          </Stack>
        )}

        {currentUser.role === 'SHIPPER_ADMIN' && (
          <Stack flow="column" gap={1} justifyContent="space-between">
            {userAssignment.response.data.state === 'NOT_REQUESTED' ? (
              <EmptyValue
                label={
                  <>
                    You are not assigned to this Operator.
                    {!showAssignmentsButton && (
                      <>
                        {' '}
                        You can go to the{' '}
                        <Link
                          {...savedReferrer.getLinkProps({
                            pathname: '/operators/assignments/overview',
                            state: {
                              tsoId,
                              shipperUserId: userId,
                              shipperUserRefId: user.referenceId,
                            },
                          })}
                          mode="default-underlined"
                        >
                          Assignments
                        </Link>{' '}
                        section to apply for an Assignment.
                      </>
                    )}
                  </>
                }
              />
            ) : (
              <AssignmentStatusDisplayMonolith
                value={userAssignment.response.data.state}
                data-testid="user-assignment-status"
              />
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
