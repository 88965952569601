import { FC } from 'react';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { useDirectFcfsFlags } from 'src/hooks/use-direct-fcfs-flags';
import { useTitle } from 'src/hooks/use-title';
import { ApiNotificationsForm } from 'src/pages/settings/direct-fcfs/form';
import { Forbidden } from 'src/utils/errors';

export const DirectFcfsSettings: FC = () => {
  const directFcfsFlags = useDirectFcfsFlags();
  if (!directFcfsFlags.data?._links?.['read-configuration'])
    throw directFcfsFlags.error || new Forbidden();

  useTitle('Settings - Direct FCFS');

  return (
    <Stack gap={2}>
      <PageHeader title="Direct FCFS" />
      <Stack gap={1}>
        <ApiNotificationsForm />
      </Stack>
    </Stack>
  );
};
