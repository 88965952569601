import { useEffect } from 'react';
import { GetDownloadUrlResponse } from 'src/components/form/file-upload';
import { useAxios } from 'src/hooks/use-axios';

export const useDownloadRequiredInformation = () => {
  const download = useAxios(
    (axios, baseConfig, { url }: { url: string }) =>
      axios.request<GetDownloadUrlResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: false }
  );

  useEffect(() => {
    if (download.response) {
      window.location.href = download.response.data.downloadUrl;
    }
  }, [download.response]);

  return download;
};
