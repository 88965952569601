import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation, To, Location } from 'react-router-dom';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { setLocationHref } from 'src/utils/location-usage';

type WithOptionalReferrerState = { referrer?: ReferrerState } | void;

type ReferrerLocation = Partial<Location<WithOptionalReferrerState>>;

export type ReferrerState = {
  location: ReferrerLocation | string;
  label: string;
};

export type Referrer = ReferrerState & {
  /**
   * Creates a "Back to ..." link that points to the parent page.
   */
  backLink: JSX.Element;
  /**
   * Creates a cancel "button" that points to the parent page.
   */
  cancelLink: JSX.Element;
  /**
   * Navigate to the parent page.
   */
  goTo: () => void;
  /**
   * Merges the referrer state of the parent page into the given location descriptor to be used in a link.
   *
   * Note: While we usually work with a "location" object, links handle
   * the state separate from all other fields. Those other fields are called
   * a "to" object.
   */
  getLinkProps: (location: Partial<Location<{}>> | string) => {
    to: To;
    state: { referrer: ReferrerState }; // note: we will also merge `location.state` into this
  };
};

/**
 * The referrer refers to the _parent page_ (if such a page exists).
 * You usually use this to go back to the parent page via different ways (e.g. back link, cancel button).
 *
 * Imagine the following use case:
 * - The user is on **Page A** which shows a table which can be filtered.
 * - The user sets some filters and is now on **Page A with search params**.
 * - The user clicks on an item an comes to **Page B**.
 * - On **Page B** is a "Back to Page A" link which should take you back to **Page A**,
 * but _with_ the previously configured search params.
 * - On **Page B** you can also select another action which gets you to **Page C**.
 * - On **Page C** is a "Back to Page B" link which should take you back to **Page B**.
 * - **Page C** also contains a form which will take you back to **Page B**, if you
 * cancel or submit the form as well.
 * - If the user is back on **Page B** and now clicks on "Back to Page A" we'll get back
 * to Page A, but _with_ the previously configured search params.
 *
 * Imagine the additional problem that you can also get to **Page B** from **Page D**
 * and that the back link on **Page B** should now say "Back to Page D" and _not_
 * "Back to Page A".
 *
 * That's what the hooks `useSaveReferrer()` and `useReferrer()` try to solve.
 * - Via `useSaveReferrer()` you can keep track of your current referrer, give it a label
 * and also keep track of parent page referrers.
 * - Via `useReferrer()` you can access a referrer and set a default, if needed.
 *
 * It works with `<Link/>`, `<Navigate/>`, `useNavigate` - anything which takes a `Location`.
 *
 * If you're interested in a real world example:
 * - Page A is "My Transactions"
 * - Page B is "Allocation to Balancing Groups" (for trades or deals)
 * - Page C is "Add/Edit Allocation to Balancing Groups"
 * - Page D is "Portfolio Overview"
 *
 * **Note**: This does not mean that you have to use referrers now _everywhere_. From _any_ page
 * to _any_ page. This is only about pages with one common user flow and explicit parent-child-relationships
 * of pages. This could be "Overview <-> Detail" pages or "Detail <-> Edit" pages for example.
 */
export function useReferrer(defaultReferrer: ReferrerState): Referrer {
  const { state } = useLocation<WithOptionalReferrerState>();
  const referrerState = state?.referrer ?? defaultReferrer;

  // additional span is used, so it can be safely used as a child of <Stack flow="row"/>
  // without making the link go over the whole page
  const backLink = (
    <span>
      {isExternal(referrerState.location) ? (
        <ExternalLink href={referrerState.location}>
          <FontAwesomeIcon icon={faChevronCircleLeft} aria-hidden /> Back to{' '}
          {referrerState.label}
        </ExternalLink>
      ) : (
        <Link
          to={referrerState.location}
          state={referrerState.location.state}
          data-testid="referrer-back-link"
        >
          <FontAwesomeIcon icon={faChevronCircleLeft} aria-hidden /> Back to{' '}
          {referrerState.label}
        </Link>
      )}
    </span>
  );

  const cancelLink = isExternal(referrerState.location) ? (
    <ExternalLink href={referrerState.location} mode="button-secondary">
      Cancel
    </ExternalLink>
  ) : (
    <Link
      to={referrerState.location}
      state={referrerState.location.state}
      mode="button-secondary"
    >
      Cancel
    </Link>
  );

  const navigate = useNavigate();

  const goTo = () => {
    if (isExternal(referrerState.location)) {
      setLocationHref(referrerState.location);
    } else {
      navigate(referrerState.location, { state: referrerState.location.state });
    }
  };

  const getLinkProps = (location: Partial<Location<{}>> | string) => {
    if (typeof location === 'string') {
      return {
        to: location,
        state: { referrer: referrerState },
      };
    } else {
      const { state, ...to } = location;
      return {
        to,
        state: { ...state, referrer: referrerState },
      };
    }
  };

  return { ...referrerState, backLink, cancelLink, goTo, getLinkProps };
}

type UseSaveReferrerOptions = {
  label: string;
  // TODO: Add support for anchor in a follow-up merge request.
  // anchor: string;
};

export type SavedReferrer = ReferrerState & {
  /**
   * Merges the referrer state of the current page into the given location descriptor to be used in a link.
   *
   * Note: While we usually work with a "location" object, links handle
   * the state separate from all other fields. Those other fields are called
   * a "to" object.
   */
  getLinkProps: (location: Partial<Location<{}>> | string) => {
    to: To;
    state: { referrer: ReferrerState }; // note: we will also merge `location.state` into this
  };
};

/**
 * The saved referrer refers to the current page.
 * You usually use this to attach the current page as a reference when navigating to a child page.
 */
export function useSaveReferrer({
  label,
}: UseSaveReferrerOptions): SavedReferrer {
  const location = useLocation<WithOptionalReferrerState>();
  const referrerState: ReferrerState = { location, label };

  const getLinkProps = (location: Partial<Location<{}>> | string) => {
    if (typeof location === 'string') {
      return {
        to: location,
        state: { referrer: referrerState },
      };
    } else {
      const { state, ...to } = location;
      return {
        to,
        state: { ...state, referrer: referrerState },
      };
    }
  };

  return { ...referrerState, getLinkProps };
}

function isExternal(location: ReferrerLocation | string): location is string {
  return (
    typeof location === 'string' &&
    (location.startsWith('https://') || location.startsWith('http://'))
  );
}
