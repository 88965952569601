import { faFilter as farFilter } from '@fortawesome/pro-regular-svg-icons';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import { isEqual, sortBy } from 'lodash';
import {
  FC,
  Fragment,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { ExpandableCard } from 'src/components/data-display/expandable-card';
import {
  Tag,
  TagCollection,
  TagDateRange,
  TagRange,
} from 'src/components/data-display/tag';
import { Form } from 'src/components/form/form';
import { FocusManager } from 'src/components/form/use-focus-manager';
import { GroupWrap } from 'src/components/group-wrap';
import { PageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { ConstraintViolation } from 'src/utils/is-constraint-violation';
import {
  FilterKeys,
  InferFilters,
  InferValues,
  PageParams,
  ParamDef,
} from 'src/utils/page-params';
import { z } from 'zod';

const FilterHeader: FC<{ tags: ReactNode[] }> = ({ tags }) => {
  return (
    <GroupWrap gap={0.5}>
      <span>Active Filter:</span>
      <span> </span>
      {tags.length ? (
        tags.map((tag, index) => <Fragment key={index}>{tag}</Fragment>)
      ) : (
        <EmptyValue label="No Active Filter" />
      )}
    </GroupWrap>
  );
};

type Props = {
  isExpanded: boolean;
  setExpanded: (expanded: boolean) => void;
  tags: ReactNode[];
  children: ReactNode;
};

const ExpandableFilters: FC<Props> = ({ tags, ...props }) => {
  const truthyTags = tags.filter(Boolean);
  return (
    <ExpandableCard
      {...props}
      header={<FilterHeader tags={truthyTags} />}
      icon={<FontAwesomeIcon icon={truthyTags.length ? faFilter : farFilter} />}
    />
  );
};

type TagTypes<FilterKey> =
  | null
  | { type: 'value'; mappedValue?: string }
  | { type: 'date-time'; end: FilterKey }
  | { type: 'date'; end: FilterKey }
  | { type: 'range'; end: FilterKey }
  | { type: 'list'; mappedValue?: string[] };

/**
 * @deprecated Please use `usePageParamsFromSchema` and `<FilterCard />` instead. (Exception:
 * `optionalDefault` and grouped page params aren't supported yet.)
 */
export function ActiveFilters<
  Defs,
  Tags extends Record<
    RelevantFilterKeys<Defs, Tags>,
    TagTypes<FilterKeys<Defs>>
  >,
>({
  children,
  pageParams,
  tags,
  initiallyExpanded = false,
}: {
  children: ReactNode;
  pageParams: PageParams<Defs>;
  tags: Tags;
  initiallyExpanded?: boolean;
}) {
  const { filterLabels } = pageParams;
  const [focusField, setFocusField] = useState<string | null>(null);
  const [isExpanded, setExpanded] = useState(initiallyExpanded);

  const handleTagClick = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      fieldName: FilterKeys<Defs>
    ) => {
      if (!isExpanded) {
        setExpanded(true);
      }
      setFocusField(fieldName as string);
      e.stopPropagation();
    },
    [isExpanded, setExpanded, setFocusField]
  );

  const handleTagRemoveClick = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      params: Partial<InferFilters<Defs>>
    ) => {
      pageParams.set(params as Partial<InferValues<Defs>>);
      e.stopPropagation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageParams.set]
  );

  const activeTags = Object.keys(tags).map((filterName) => {
    const key = filterName as RelevantFilterKeys<Defs, Tags>;
    const tag: TagTypes<FilterKeys<Defs>> = tags[key];
    if (tag === null) return;

    const currentValue = pageParams.value[key];
    const currentDef = pageParams.defs[key] as unknown as ParamDef;

    const commonProps = {
      key: key as string,
      label: filterLabels[key],
      testid: `filter-${key as string}`,
    };

    switch (tag.type) {
      case 'value':
        if (
          currentValue === '' ||
          currentValue === null ||
          currentValue === false
        )
          return;

        return (
          <>
            {currentDef.default === currentValue &&
            !currentDef.optionalDefault ? (
              <Tag
                {...commonProps}
                value={tag.mappedValue ?? (currentValue as string)}
                onClick={(e) => handleTagClick(e, key)}
              />
            ) : (
              <Tag
                {...commonProps}
                value={tag.mappedValue ?? (currentValue as string)}
                onClick={(e) => handleTagClick(e, key)}
                onRemove={(e) => {
                  handleTagRemoveClick(e, {
                    [key]:
                      currentDef.default !== undefined &&
                      !currentDef.optionalDefault
                        ? currentDef.default
                        : null,
                  } as Partial<InferFilters<Defs>>);
                }}
              />
            )}
          </>
        );
      case 'date':
      case 'date-time': {
        const endKey = tag.end;
        const endValue = pageParams.value[endKey];
        const endDef = pageParams.defs[endKey] as unknown as ParamDef;

        if (currentValue === null && endValue === null) return;

        const currentValueIsNonOptionalDefault =
          currentDef.default === currentValue && !currentDef.optionalDefault;
        const endValueIsNonOptionalDefault =
          endDef.default === endValue && !endDef.optionalDefault;

        return (
          <>
            {(currentValueIsNonOptionalDefault &&
              endValueIsNonOptionalDefault) ||
            (currentValueIsNonOptionalDefault && endValue === null) ||
            (currentValue === null && endValueIsNonOptionalDefault) ? (
              <TagDateRange
                {...commonProps}
                type={tag.type}
                from={pageParams.value[key] as string | null}
                to={pageParams.value[endKey] as string | null}
                onClick={(e) => handleTagClick(e, key)}
              />
            ) : (
              <TagDateRange
                {...commonProps}
                type={tag.type}
                from={pageParams.value[key] as string | null}
                to={pageParams.value[endKey] as string | null}
                onClick={(e) => handleTagClick(e, key)}
                onRemove={(e) =>
                  handleTagRemoveClick(e, {
                    [key]:
                      currentDef.default !== undefined &&
                      !currentDef.optionalDefault
                        ? currentDef.default
                        : null,
                    [endKey]:
                      endDef.default !== undefined && !endDef.optionalDefault
                        ? endDef.default
                        : null,
                  } as Partial<InferFilters<Defs>>)
                }
              />
            )}
          </>
        );
      }
      case 'range': {
        const endKey = tag.end;
        const endValue = pageParams.value[endKey];
        const endDef = pageParams.defs[endKey] as unknown as ParamDef;

        if (currentValue === null && endValue === null) return;

        const currentValueIsNonOptionalDefault =
          currentDef.default === currentValue && !currentDef.optionalDefault;
        const endValueIsNonOptionalDefault =
          endDef.default === endValue && !endDef.optionalDefault;

        return (
          <>
            {(currentValueIsNonOptionalDefault &&
              endValueIsNonOptionalDefault) ||
            (currentValueIsNonOptionalDefault && endValue === null) ||
            (currentValue === null && endValueIsNonOptionalDefault) ? (
              <TagRange
                {...commonProps}
                min={pageParams.value[key] as number | null}
                max={pageParams.value[endKey] as number | null}
                onClick={(e) => handleTagClick(e, key)}
              />
            ) : (
              <TagRange
                {...commonProps}
                min={pageParams.value[key] as number | null}
                max={pageParams.value[endKey] as number | null}
                onClick={(e) => handleTagClick(e, key)}
                onRemove={(e) =>
                  handleTagRemoveClick(e, {
                    [key]:
                      currentDef.default !== undefined &&
                      !currentDef.optionalDefault
                        ? currentDef.default
                        : null,
                    [endKey]:
                      endDef.default !== undefined && !endDef.optionalDefault
                        ? endDef.default
                        : null,
                  } as Partial<InferFilters<Defs>>)
                }
              />
            )}
          </>
        );
      }
      case 'list':
        const currentList = currentValue as Array<unknown>;

        if (currentList.length === 0) return;

        return (
          <>
            {currentDef.default !== undefined &&
            !currentDef.optionalDefault &&
            isEqual(
              sortBy(currentDef.default as unknown[]),
              sortBy(currentList)
            ) ? (
              <TagCollection
                {...commonProps}
                collection={
                  tag.mappedValue ?? (pageParams.value[key] as string[])
                }
                onClick={(e) => handleTagClick(e, key)}
              />
            ) : (
              <TagCollection
                {...commonProps}
                collection={
                  tag.mappedValue ?? (pageParams.value[key] as string[])
                }
                onClick={(e) => handleTagClick(e, key)}
                onRemove={(e) =>
                  handleTagRemoveClick(e, {
                    [key]:
                      currentDef.default !== undefined &&
                      !currentDef.optionalDefault
                        ? currentDef.default
                        : [],
                  } as Partial<InferFilters<Defs>>)
                }
              />
            )}
          </>
        );
      default:
        // small guard to make sure no one forgets to handle new tag types
        // @ts-expect-error
        throw new Error(`Found unhandled tag type called "${tag.type}".`);
    }
  });

  return (
    <FocusManager
      focusField={focusField}
      resetFocusField={() => setFocusField(null)}
    >
      <ExpandableFilters
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        tags={activeTags}
      >
        {children}
      </ExpandableFilters>
    </FocusManager>
  );
}

type RelevantFilterKeys<Defs, Tags> = Exclude<
  FilterKeys<Defs>,
  InferOmittableFilterKeys<Tags>
>;

type InferOmittableFilterKeys<Tags> = InferOmittableFilterKey<Tags[keyof Tags]>;

type InferOmittableFilterKey<Tag> = Tag extends {
  type: 'date-time' | 'range' | 'date';
  end: infer OmittableFilterKey;
}
  ? IsString<OmittableFilterKey>
  : never;

type IsString<Key> = Key extends string ? Key : never;

type Field<Values> =
  | ReactNode
  | ((formikProps: FormikProps<Values>) => ReactNode);

type ZodFilter<FilterKey, Values> =
  | null
  | {
      type: 'value';
      mappedValue?: string;
      field: Field<Values>;
    }
  | {
      type: 'date-time';
      end: FilterKey;
      field: Field<Values>;
    }
  | {
      type: 'date';
      end: FilterKey;
      field: Field<Values>;
    }
  | {
      type: 'range';
      end: FilterKey;
      field: Field<Values>;
    }
  | {
      type: 'list';
      mappedValue?: string[];
      field: Field<Values>;
    };

export function FilterCard<
  PageParamsSchema extends z.AnyZodObject,
  PageParamsValues extends z.output<PageParamsSchema>,
  FilterKeys extends keyof PageParamsValues,
  CurrentPageParams extends PageParamsFromSchema<
    PageParamsSchema,
    PageParamsValues,
    FilterKeys
  >,
  FilterValues extends z.input<CurrentPageParams['filterSchema']>,
  Filters extends Record<
    Exclude<
      keyof CurrentPageParams['filter'],
      InferOmittableFilterKeys<Filters>
    >,
    ZodFilter<keyof CurrentPageParams['filter'], FilterValues>
  >,
>({
  pageParams,
  filters,
  initiallyExpanded = false,
  resetTestId = 'reset-filters',
  submitTestId = 'submit-filters',
  constraintViolation,
}: {
  pageParams: CurrentPageParams;
  /**
   * The order of filters should match the order of the columns in your overview.
   */
  filters: Filters;
  initiallyExpanded?: boolean;
  resetTestId?: string;
  submitTestId?: string;
  /**
   * Please use constraint violations, if your API supports them.
   *
   * If you want to handle constraint violations in the initial request, you need to handle
   * the "empty state" manually as you will not have a proper result.
   *
   * But you can easily opt-into handling constraint violations only for subsequent requests.
   * The pattern for this looks like this:
   *
   * ```tsx
   * onError(error) {
   *   // handle constraint violations for subsequent requests
   *   if (isConstraintViolation(error) && yourRequest.response) {
   *     return error;
   *   } else {
   *     throw error;
   *   }
   * },
   * ```
   */
  constraintViolation: ConstraintViolation | null;
}) {
  const filterKeys = Object.keys(filters) as (keyof typeof filters)[];
  return (
    <ActiveFiltersForZod<
      PageParamsSchema,
      PageParamsValues,
      FilterKeys,
      CurrentPageParams,
      Filters
    >
      initiallyExpanded={initiallyExpanded}
      pageParams={pageParams}
      tags={filters}
    >
      <Form
        mode="filter"
        initialValues={pageParams.filterValues}
        schema={pageParams.filterSchema}
        onSubmit={pageParams.setFilters}
        constraintViolation={constraintViolation}
        cancelOrResetButton={
          pageParams.hasChangedFilters && (
            <Button
              mode="secondary"
              onClick={pageParams.resetFilters}
              data-testid={resetTestId}
            >
              Reset
            </Button>
          )
        }
        submitButton={
          <Button type="submit" data-testid={submitTestId}>
            Filter
          </Button>
        }
      >
        {(formikProps) =>
          filterKeys
            .filter((key) => filters[key] !== null)
            .map((key) => (
              <Fragment key={key as string}>
                {typeof filters[key]?.field === 'function'
                  ? filters[key].field(
                      formikProps as unknown as FormikProps<FilterValues>
                    )
                  : filters[key]?.field}
              </Fragment>
            ))
        }
      </Form>
    </ActiveFiltersForZod>
  );
}

function ActiveFiltersForZod<
  T extends z.AnyZodObject,
  Values extends z.output<T>,
  FilterKeys extends keyof Values,
  CurrentPageParams extends PageParamsFromSchema<T, Values, FilterKeys>,
  Tags extends Record<
    Exclude<keyof CurrentPageParams['filter'], InferOmittableFilterKeys<Tags>>,
    TagTypes<keyof CurrentPageParams['filter']>
  >,
>({
  children,
  pageParams,
  tags,
  initiallyExpanded = false,
}: {
  children: ReactNode;
  pageParams: CurrentPageParams;
  tags: Tags;
  initiallyExpanded?: boolean;
}) {
  const [focusField, setFocusField] = useState<string | null>(null);
  const [isExpanded, setExpanded] = useState(initiallyExpanded);

  const handleTagClick = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      fieldName: keyof CurrentPageParams['filter']
    ) => {
      if (!isExpanded) {
        setExpanded(true);
      }
      setFocusField(fieldName as string);
      e.stopPropagation();
    },
    [isExpanded, setExpanded, setFocusField]
  );

  const handleTagRemoveClick = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      params: Partial<CurrentPageParams['filterValues']>
    ) => {
      pageParams.setFilters(params);
      e.stopPropagation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageParams.set]
  );

  const activeTags = Object.keys(tags).map((filterName) => {
    const key = filterName as keyof CurrentPageParams['filter'];
    const tag: TagTypes<keyof CurrentPageParams['filter']> = tags[key];
    if (tag === null) return;

    const currentValue = pageParams.value[key];
    const currentDef = pageParams.filter[key as FilterKeys];

    const commonProps = {
      key: key as string,
      label: currentDef.label,
      testid: `filter-${key as string}`,
    };

    switch (tag.type) {
      case 'value':
        if (
          currentValue === '' ||
          currentValue === null ||
          currentValue === false
        )
          return;

        const value = tag.mappedValue ?? (currentValue as ReactElement);
        const normalizedValue = isValidElement(value) ? value : String(value);

        return (
          <>
            {currentDef.default === currentValue &&
            !currentDef.optionalDefault ? (
              <Tag
                {...commonProps}
                value={normalizedValue}
                onClick={(e) => handleTagClick(e, key)}
              />
            ) : (
              <Tag
                {...commonProps}
                value={normalizedValue}
                onClick={(e) => handleTagClick(e, key)}
                onRemove={(e) => {
                  handleTagRemoveClick(e, {
                    [key]:
                      currentDef.default !== undefined &&
                      !currentDef.optionalDefault
                        ? currentDef.default
                        : null,
                  } as Partial<CurrentPageParams['filterValues']>);
                }}
              />
            )}
          </>
        );
      case 'date':
      case 'date-time': {
        const endKey = tag.end;
        const endValue = pageParams.value[endKey];
        const endDef = pageParams.filter[endKey as FilterKeys];

        if (currentValue === null && endValue === null) return;

        const currentValueIsNonOptionalDefault =
          currentDef.default === currentValue && !currentDef.optionalDefault;
        const endValueIsNonOptionalDefault =
          endDef.default === endValue && !endDef.optionalDefault;

        return (
          <>
            {(currentValueIsNonOptionalDefault &&
              endValueIsNonOptionalDefault) ||
            (currentValueIsNonOptionalDefault && endValue === null) ||
            (currentValue === null && endValueIsNonOptionalDefault) ? (
              <TagDateRange
                {...commonProps}
                type={tag.type}
                from={pageParams.value[key] as string | null}
                to={pageParams.value[endKey] as string | null}
                onClick={(e) => handleTagClick(e, key)}
              />
            ) : (
              <TagDateRange
                {...commonProps}
                type={tag.type}
                from={pageParams.value[key] as string | null}
                to={pageParams.value[endKey] as string | null}
                onClick={(e) => handleTagClick(e, key)}
                onRemove={(e) =>
                  handleTagRemoveClick(e, {
                    [key]:
                      currentDef.default !== undefined &&
                      !currentDef.optionalDefault
                        ? currentDef.default
                        : null,
                    [endKey]:
                      endDef.default !== undefined && !endDef.optionalDefault
                        ? endDef.default
                        : null,
                  } as Partial<CurrentPageParams['filterValues']>)
                }
              />
            )}
          </>
        );
      }
      case 'range': {
        const endKey = tag.end;
        const endValue = pageParams.value[endKey];
        const endDef = pageParams.filter[endKey as FilterKeys];

        if (currentValue === null && endValue === null) return;

        const currentValueIsNonOptionalDefault =
          currentDef.default === currentValue && !currentDef.optionalDefault;
        const endValueIsNonOptionalDefault =
          endDef.default === endValue && !endDef.optionalDefault;

        return (
          <>
            {(currentValueIsNonOptionalDefault &&
              endValueIsNonOptionalDefault) ||
            (currentValueIsNonOptionalDefault && endValue === null) ||
            (currentValue === null && endValueIsNonOptionalDefault) ? (
              <TagRange
                {...commonProps}
                min={pageParams.value[key] as number | null}
                max={pageParams.value[endKey] as number | null}
                onClick={(e) => handleTagClick(e, key)}
              />
            ) : (
              <TagRange
                {...commonProps}
                min={pageParams.value[key] as number | null}
                max={pageParams.value[endKey] as number | null}
                onClick={(e) => handleTagClick(e, key)}
                onRemove={(e) =>
                  handleTagRemoveClick(e, {
                    [key]:
                      currentDef.default !== undefined &&
                      !currentDef.optionalDefault
                        ? currentDef.default
                        : null,
                    [endKey]:
                      endDef.default !== undefined && !endDef.optionalDefault
                        ? endDef.default
                        : null,
                  } as Partial<CurrentPageParams['filterValues']>)
                }
              />
            )}
          </>
        );
      }
      case 'list':
        const currentList = currentValue as Array<unknown>;

        if (currentList.length === 0) return;

        return (
          <>
            {currentDef.default !== undefined &&
            !currentDef.optionalDefault &&
            isEqual(
              sortBy(currentDef.default as unknown[]),
              sortBy(currentList)
            ) ? (
              <TagCollection
                {...commonProps}
                collection={
                  tag.mappedValue ?? (pageParams.value[key] as string[])
                }
                onClick={(e) => handleTagClick(e, key)}
              />
            ) : (
              <TagCollection
                {...commonProps}
                collection={
                  tag.mappedValue ?? (pageParams.value[key] as string[])
                }
                onClick={(e) => handleTagClick(e, key)}
                onRemove={(e) =>
                  handleTagRemoveClick(e, {
                    [key]:
                      currentDef.default !== undefined &&
                      !currentDef.optionalDefault
                        ? currentDef.default
                        : [],
                  } as Partial<CurrentPageParams['filterValues']>)
                }
              />
            )}
          </>
        );
      default:
        // small guard to make sure no one forgets to handle new tag types
        // @ts-expect-error
        throw new Error(`Found unhandled tag type called "${tag.type}".`);
    }
  });

  return (
    <FocusManager
      focusField={focusField}
      resetFocusField={() => setFocusField(null)}
    >
      <ExpandableFilters
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        tags={activeTags}
      >
        {children}
      </ExpandableFilters>
    </FocusManager>
  );
}
