import { labelsToValues } from 'src/utils/labels-to-values';

type FromShipperDocumentsSortBy = 'changedAt' | 'fileName' | 'category';

const fromShipperDocumentsSortByLabels: Record<
  FromShipperDocumentsSortBy,
  string
> = {
  changedAt: 'Date',
  fileName: 'Name',
  category: 'Type',
};

export const fromShipperDocumentsSortByValue = labelsToValues(
  fromShipperDocumentsSortByLabels
);

type ShipperDocumentsSortBy = 'CHANGED_AT';

const shipperDocumentsSortByLabels: Record<ShipperDocumentsSortBy, string> = {
  CHANGED_AT: 'Date',
};

export const shipperDocumentsSortByValues = labelsToValues(
  shipperDocumentsSortByLabels
);
