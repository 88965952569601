import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { correctionsMonthlyStatementsSchema } from 'src/pages/reporting/monthly-statements/corrections/schema';

export function usePageParams() {
  return usePageParamsFromSchema(correctionsMonthlyStatementsSchema, {
    filters: {
      creationDateStart: { label: 'Time of Creation' },
      creationDateEnd: { label: 'Time of Creation' },
      billingPeriodStart: { label: 'Billing Period' },
      billingPeriodEnd: { label: 'Billing Period' },
      type: { label: 'Type' },
      shipperId: { label: 'Shipper' },
      contractId: { label: 'Contract Id' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;
