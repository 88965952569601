import { useEffect } from 'react';
import { RemitReportingEntrypoint } from 'src/apis/remit-reporting/types';
import { useAxios } from 'src/hooks/use-axios';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

export function useRemitReportingEntrypoint() {
  const remitReportingEntrypoint = useAxios(
    (axios, baseConfig) =>
      axios.request<RemitReportingEntrypoint>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.remitReporting}/api/platform/entrypoint`,
      }),
    {
      neededOnPageLoad: false,
      cacheKey: 'RemitReportingEntrypoint',
      onError(error) {
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
    }
  );

  useEffect(() => {
    remitReportingEntrypoint.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return remitReportingEntrypoint;
}

export type RemitReportingEntrypointRequest = ReturnType<
  typeof useRemitReportingEntrypoint
>;
