import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { ActionGroup } from 'src/components/buttons-and-actions/action-group';
import { Link } from 'src/components/navigation/link';
import { PageParams } from 'src/pages/storage/locations/use-page-params';

type Props = {
  pageParams: PageParams;
};

export const QuickFiltersBar: FC<Props> = ({ pageParams }) => {
  return (
    <div>
      <ActionGroup mode="quick-filter">
        <Link
          data-testid="quickFilter-showAll"
          isActive={!pageParams.value.favoritesOnly}
          to={{ search: pageParams.toSearchParams({ favoritesOnly: false }) }}
        >
          All
        </Link>

        <Link
          data-testid="quickFilter-favouritesOnly"
          isActive={!!pageParams.value.favoritesOnly}
          to={{ search: pageParams.toSearchParams({ favoritesOnly: true }) }}
        >
          <FontAwesomeIcon icon={faStar} /> Your SSO's only
        </Link>
      </ActionGroup>
    </div>
  );
};
