import {
  DeliveryMethodTypes,
  UploadRequiredDocuments,
} from 'src/apis/assignment-service/types';
import { requiredOutput } from 'src/components/form/zod-utilities';
import { urlRegex } from 'src/utils/validation';
import { z } from 'zod';

export type DocumentMethodType = 'URL' | 'UPLOAD';

export const shipperOrgDocumentTypes = [
  'showCompanyRegisterExtract',
  'showCurrentCompanyReport',
  'showMostRecentFullYearFinancialReport',
  'showMostRecentInterimFinancialReport',
  'showCreditSolvencyCheck',
  'showPOA',
  'showCreditRating',
] as const;

export const defaultDocumentMapping: Record<string, string> = {
  showCompanyRegisterExtract: 'companyRegisterExtract',
  showCurrentCompanyReport: 'currentCompanyReport',
  showMostRecentFullYearFinancialReport: 'mostRecentFullYearFinancialReport',
  showMostRecentInterimFinancialReport: 'mostRecentInterimFinancialReport',
  showCreditSolvencyCheck: 'creditSolvencyCheck',
  showPOA: 'poa',
  showCreditRating: 'creditRating',
};

export const standardDocumentLabels: Record<string, string> = {
  companyRegisterExtract: 'Company Register Extract',
  currentCompanyReport: 'Current Company Report',
  mostRecentFullYearFinancialReport: 'Most Recent Full Year Financial Report',
  mostRecentInterimFinancialReport: 'Most Recent Interim Financial Report',
  creditSolvencyCheck: 'Credit Solvency Check',
  poa: 'Power of Attorney',
  creditRating: 'Credit Rating Certification',
};

export const DeliveryMethodLabels: { [key in DeliveryMethodTypes]: string } = {
  DIGITAL: 'Digital (email)',
  POST: 'Post only (hard copy)',
};

const deliveryMethodTypes = z.enum(['DIGITAL', 'POST']);

const typeEnum = z.enum(['URL', 'UPLOAD']);

const requiredDocumentSchema = z.object({
  acceptedDeliveryMethods: z.array(deliveryMethodTypes),
});

const additionalRequiredDocumentSchema = z.discriminatedUnion(
  'documentLocation',
  [
    z.object({
      documentLocation: z.literal(null),
      name: z.string().nullable().transform(requiredOutput()),
      acceptedDeliveryMethods: z
        .array(deliveryMethodTypes)
        .min(1, 'Please, select at least one delivery method.'),
      url: z
        .string()
        .regex(
          urlRegex,
          'URL format is incorrect. Please enter a valid URL which starts with https://'
        )
        .optional()
        .nullable(),
      document: z
        .object({
          id: z.string(),
          fileName: z.string().nullable(),
        })
        .nullable(),
      downloadDocument: z.string().optional().nullable(),
    }),
    z.object({
      documentLocation: z.literal(typeEnum.Values.UPLOAD), // only needed on FE
      name: z.string().nullable().transform(requiredOutput()),
      acceptedDeliveryMethods: z
        .array(deliveryMethodTypes)
        .min(1, 'Please, select at least one delivery method.'),
      url: z
        .string()
        .regex(
          urlRegex,
          'URL format is incorrect. Please enter a valid URL which starts with https://'
        )
        .optional()
        .nullable(),
      document: z
        .object({
          id: z.string(),
          fileName: z.string().nullable(),
        })
        .nullable()
        .transform(requiredOutput({ message: 'Please, upload the document.' })),
      downloadDocument: z.string().optional().nullable(),
    }),
    z.object({
      documentLocation: z.literal(typeEnum.Values.URL), // only needed on FE
      name: z.string().nullable().transform(requiredOutput()),
      acceptedDeliveryMethods: z
        .array(deliveryMethodTypes)
        .min(1, 'Please, select at least one delivery method.'),
      url: z
        .string()
        .regex(
          urlRegex,
          'URL format is incorrect. Please enter a valid URL which starts with https://'
        )
        .optional()
        .nullable()
        .transform(requiredOutput()),
      document: z
        .object({
          id: z.string(),
          fileName: z.string().nullable(),
        })
        .nullable(),
      downloadDocument: z.string().optional().nullable(),
    }),
  ]
);

export const editRequiredInformationSchema = z
  .object({
    options: z.array(z.enum(shipperOrgDocumentTypes)),
    companyRegisterExtract: requiredDocumentSchema
      .nullable()
      .transform(requiredOutput()),
    currentCompanyReport: requiredDocumentSchema
      .nullable()
      .transform(requiredOutput()),
    mostRecentFullYearFinancialReport: requiredDocumentSchema
      .nullable()
      .transform(requiredOutput()),
    mostRecentInterimFinancialReport: requiredDocumentSchema
      .nullable()
      .transform(requiredOutput()),
    creditSolvencyCheck: requiredDocumentSchema
      .nullable()
      .transform(requiredOutput()),
    poa: requiredDocumentSchema.nullable().transform(requiredOutput()),
    creditRating: requiredDocumentSchema
      .extend({
        agency: z.string().optional().nullable(),
      })
      .nullable()
      .transform(requiredOutput()),
    additionalOrganisationDocuments: z
      .array(additionalRequiredDocumentSchema)
      .nullable()
      .transform(requiredOutput()),
    additionalUserDocuments: z
      .array(additionalRequiredDocumentSchema)
      .nullable()
      .transform(requiredOutput()),
  })
  .superRefine((data, ctx) => {
    data.options.forEach((option) => {
      const fieldName = defaultDocumentMapping[option];
      const fieldValue = data[fieldName as keyof typeof data] as
        | { acceptedDeliveryMethods: DeliveryMethodTypes[] }
        | null
        | undefined;

      if (fieldValue?.acceptedDeliveryMethods.length === 0) {
        ctx.addIssue({
          message: 'Please, select at least one delivery method',
          code: z.ZodIssueCode.custom,
          path: [fieldName, 'acceptedDeliveryMethods'],
        });
      }
    });
  });

export type EditRequiredInformationFormValues = z.input<
  typeof editRequiredInformationSchema
>;
export type ValidEditRequiredInformationFormValues = z.output<
  typeof editRequiredInformationSchema
>;

export const formValuesToWriteData = (
  values: ValidEditRequiredInformationFormValues
): UploadRequiredDocuments => {
  return values;
};

export const standartDocumentLabels: Record<string, string> = {
  companyRegisterExtract: 'Company Register Extract',
  currentCompanyReport: 'Current Company Report',
  mostRecentFullYearFinancialReport: 'Most Recent Full Year Financial Report',
  mostRecentInterimFinancialReport: 'Most Recent Interim Financial Report',
  creditSolvencyCheck: 'Credit Solvency Check',
  poa: 'POA',
  creditRating: 'Credit Rating Certification',
};
