import { FC } from 'react';
import { Contact } from 'src/apis/organisation-service/types';
import { EmptyValue } from 'src/components/data-display/empty-value';
import {
  lsoContactTypeLabelsForOverview,
  shipperContactTypeLabels,
  ssoContactTypeLabelsForOverview,
  tsoContactTypeLabelsForOverview,
} from 'src/pages/settings/organisation/contacts/labels';

export const ContactType: FC<{ contact: Contact }> = ({ contact }) => {
  let contacts: string[] = [];

  if (contact.shipperTypes) {
    contact.shipperTypes.map((type) =>
      contacts.push(shipperContactTypeLabels[type])
    );
  }

  if (contact.lsoTypes) {
    contact.lsoTypes.map((type) =>
      contacts.push(lsoContactTypeLabelsForOverview[type])
    );
  }
  if (contact.ssoTypes) {
    contact.ssoTypes.map((type) =>
      contacts.push(ssoContactTypeLabelsForOverview[type])
    );
  }
  if (contact.tsoTypes) {
    contact.tsoTypes.map((type) =>
      contacts.push(tsoContactTypeLabelsForOverview[type])
    );
  }

  if (!contacts.length) {
    return <EmptyValue label="None" />;
  }
  return (
    <p data-testid={`contact-type-${contact.contactId}`}>
      Type:{' '}
      {contacts.length > 0 && contacts.map((contact) => contact).join(', ')}
    </p>
  );
};
