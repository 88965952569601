import { FC } from 'react';
import {
  documentTypeLabels,
  documentTypeOptions,
} from 'src/apis/document-exchange/types';
import { OperatorDocumentsInboxRequest } from 'src/apis/document-exchange/use-operator-documents-inbox';
import { OperatorDocumentsOutboxRequest } from 'src/apis/document-exchange/use-operator-documents-outbox';
import { FilterCard } from 'src/components/data-display/expandable-filters';
import { DateRangeInput } from 'src/components/form/datepicker';
import { Input } from 'src/components/form/input';
import { MultiSelect } from 'src/components/form/select/multi-select';
import {
  documentStatusLabels,
  documentStatusOptions,
} from 'src/pages/shippers/documents/status';
import { PageParams } from 'src/pages/shippers/documents/use-page-params';

type FilterCardProps = {
  type: 'INBOX' | 'OUTBOX';
  pageParams: PageParams;
  documents: OperatorDocumentsOutboxRequest | OperatorDocumentsInboxRequest;
};

export const DocumentsFilterCard: FC<FilterCardProps> = ({
  type,
  pageParams,
  documents,
}) => {
  return (
    <FilterCard
      constraintViolation={null}
      pageParams={pageParams}
      filters={{
        changedAtStart: {
          type: 'date-time',
          end: 'changedAtEnd',
          field: (
            <DateRangeInput
              groupLabel={pageParams.filter.changedAtStart.label}
              fromName="changedAtStart"
              fromLabel="Time of Modification (from)"
              toName="changedAtEnd"
              toLabel="Time of Modification (until)"
              time
            />
          ),
        },
        documentName: {
          type: 'value',
          field: (
            <Input
              name="documentName"
              label={pageParams.filter.documentName.label}
              placeholder="Document Name"
            />
          ),
        },
        documentDescription: {
          type: 'value',
          field: (
            <Input
              name="documentDescription"
              label={pageParams.filter.documentDescription.label}
              placeholder="Document's Description"
            />
          ),
        },
        documentType: {
          type: 'list',
          mappedValue: pageParams.value.documentType.map(
            (type) => documentTypeLabels[type]
          ),
          field: (
            <MultiSelect
              name="documentType"
              label={pageParams.filter.documentType.label}
              options={documentTypeOptions}
              placeholder="E.g. Business Communication"
            />
          ),
        },
        documentStatus: {
          type: 'list',
          mappedValue: pageParams.value.documentStatus.map(
            (status) => documentStatusLabels[status]
          ),
          field: type === 'OUTBOX' && (
            <MultiSelect
              name="documentStatus"
              label={pageParams.filter.documentStatus.label}
              options={documentStatusOptions}
              placeholder="E.g. Published"
            />
          ),
        },
      }}
    />
  );
};
