import {
  offerReportingStatusValues,
  offersSortyByValues,
  serviceTypeValues,
} from 'src/apis/lng-marketing/mappings';
import {
  formDescSortDirectionSchema,
  formOptionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { z } from 'zod';

export function getLngReportingPageParamsSchema() {
  return z
    .object({
      sortBy: z.enum(offersSortyByValues).default('OFFER_BUSINESS_ID'),
      sortDirection: formDescSortDirectionSchema,
      ownOffersOnTop: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(false)
      ),
      offersWithAllocations: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(false)
      ),
      status: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(offerReportingStatusValues)).default([])
      ),
      serviceRuntimeStart: z.string().nullable().default(null),
      serviceRuntimeEnd: z.string().nullable().default(null),
      serviceType: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(serviceTypeValues)).default([])
      ),
      terminalId: z.preprocess(
        preprocessAsArray,
        z.array(formOptionSchema).default([])
      ),
      lsoOrganisationId: z.preprocess(
        preprocessAsArray,
        z
          .array(formOptionSchema.merge(z.object({ shortName: z.string() })))
          .default([])
      ),
      offerName: z.string().nullable().default(null),
      offerBusinessId: z
        .string()
        .regex(/^(LNO-\d+)$/, '{label} must start from "LNO-".')
        .nullable()
        .default(null),
    })
    .merge(getPaginationSchema());
}
