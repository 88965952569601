import { useEffect } from 'react';
import {
  ContactsParams,
  ShipperContacts,
} from 'src/apis/organisation-service/types';
import { AssignedShipperDetailsRequest } from 'src/apis/organisation-service/use-assigned-shipper-details';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type Options = {
  assignedShipperDetails: AssignedShipperDetailsRequest;
  params?: ContactsParams;
};

export function useOptionalShipperContacts({
  assignedShipperDetails,
  params,
}: Options) {
  const request = useAxios(
    (
      axios,
      baseConfig,
      { url, params }: { url: string; params?: ContactsParams }
    ) =>
      axios.request<ShipperContacts>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({}),
        params,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (
      !assignedShipperDetails.response?.data._links.getAssignedShipperContacts
    )
      return;
    const contactsUrl = urlTemplate
      .parse(
        assignedShipperDetails.response.data._links.getAssignedShipperContacts
          .href
      )
      .expand({});

    request.execute({ url: contactsUrl, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedShipperDetails.response, params]);

  if (!assignedShipperDetails.response?.data._links.getAssignedShipperContacts)
    return;

  return request;
}

export type OptionalShipperContactsRequest = ReturnType<
  typeof useOptionalShipperContacts
>;
