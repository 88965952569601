import { Colors } from 'src/styles';
import styled from 'styled-components';

export const HeaderPrimaryBackground = styled.div`
  background-color: ${Colors.brand};
`;

export const HeaderPrimaryCenter = styled.div`
  min-height: 4.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  padding: 0.6rem 0;
`;
