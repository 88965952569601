import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { storageLocationsPageParamsSchema } from 'src/pages/storage/locations/schema';

export function usePageParams() {
  return usePageParamsFromSchema(storageLocationsPageParamsSchema, {
    filters: {
      favoritesOnly: { label: 'Quick Filter' },
      name: { label: 'Location Name' },
      refId: { label: 'Location ID' },
      ssoShortName: { label: 'SSO' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;
