import { useBroadcastChannel } from 'src/hooks/use-broadcast-channel';

const channelName = 'prisma-auth';

export type AuthMessage =
  | {
      event: 'logout';
    }
  | {
      event: 'login';
      cognitoUserName: string;
      email: string;
    };

export const useAuthSessionChannel = (
  callback?: (message: AuthMessage) => void
) => {
  return useBroadcastChannel<AuthMessage>(channelName, callback, {
    ignoreCurrentWindow: true,
  });
};
