import { FC } from 'react';
import { usePremiumServicesBillingAddress } from 'src/apis/monolith/use-premium-services-billing-address';
import { useServicePackages } from 'src/apis/monolith/use-service-packages';
import { useShipperBriefInfo } from 'src/apis/monolith/use-shipper-brief-info';
import { useShipperPremiumServices } from 'src/apis/monolith/use-shipper-premium-services';
import {
  RemitReportingEntrypointRequest,
  useRemitReportingEntrypoint,
} from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { PageSpinner } from 'src/components/spinner-container';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useTitle } from 'src/hooks/use-title';
import Page from 'src/pages/settings/premium-services/page';

export const PremiumServices: FC = () => {
  const title = 'Premium Services';
  useTitle(title);
  const monolithUser = useAuthenticatedMonolithUser();
  const referenceId = monolithUser.referenceId!;
  const remitReportingEntrypointRequest = useRemitReportingEntrypoint();

  if (!remitReportingEntrypointRequest.loaded) {
    return <PageSpinner />;
  }

  return monolithUser.role === 'SHIPPER_ADMIN' ? (
    <PremiumServicesWithPrismaAPI
      remitReportingEntrypointRequest={remitReportingEntrypointRequest}
      referenceId={referenceId}
    />
  ) : (
    <Page
      remitReportingEntrypointRequest={remitReportingEntrypointRequest}
      referenceId={referenceId}
    />
  );
};

const PremiumServicesWithPrismaAPI: FC<{
  remitReportingEntrypointRequest: RemitReportingEntrypointRequest;
  referenceId: number;
}> = ({ remitReportingEntrypointRequest, referenceId }) => {
  const servicePackagesRequest = useServicePackages();
  const shipperPremiumServicesRequest = useShipperPremiumServices(referenceId);
  const shipperBriefInfoRequest = useShipperBriefInfo(referenceId);
  const premiumServicesBillingAddressRequest =
    usePremiumServicesBillingAddress(referenceId);

  if (
    !servicePackagesRequest.loaded ||
    !shipperPremiumServicesRequest.loaded ||
    !shipperBriefInfoRequest.loaded ||
    !premiumServicesBillingAddressRequest.loaded
  ) {
    return <PageSpinner />;
  }

  return (
    <Page
      remitReportingEntrypointRequest={remitReportingEntrypointRequest}
      servicePackagesRequest={servicePackagesRequest}
      shipperPremiumServicesRequest={shipperPremiumServicesRequest}
      shipperBriefInfoRequest={shipperBriefInfoRequest}
      premiumServicesBillingAddressRequest={
        premiumServicesBillingAddressRequest
      }
      referenceId={referenceId}
    />
  );
};
