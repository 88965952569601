import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAssignmentInfo } from 'src/apis/assignment-service/use-assignment-info';
import {
  AssignmentServiceRequest,
  useAssignmentServicePublic,
} from 'src/apis/assignment-service/use-assignment-service-root';
import { usePublicRequiredDocuments } from 'src/apis/assignment-service/use-public-required-documents';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { useOperatorByTso } from 'src/apis/contract-management/use-operator-by-tso';
import { useOldUserId } from 'src/apis/monolith/use-get-old-user-id';
import { useOperatorPlatformId } from 'src/apis/monolith/use-operator-platform-id';
import { useOrganisationAssignments } from 'src/apis/monolith/use-organisation-assignments';
import { useShipperUser } from 'src/apis/monolith/use-shipper-user';
import { useTso } from 'src/apis/monolith/use-tso';
import { useUserAssignment } from 'src/apis/monolith/use-user-assignment';
import { ContactsParams } from 'src/apis/organisation-service/types';
import { useOptionalContacts } from 'src/apis/organisation-service/use-optional-contacts';
import { useOrganisationServicePublic } from 'src/apis/organisation-service/use-organisation-service-public';
import {
  PublicOperatorDetailsRequest,
  usePublicOperatorDetails,
} from 'src/apis/organisation-service/use-public-operator-details';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { PageSpinner } from 'src/components/spinner-container';
import { isNetworkError, Successful } from 'src/hooks/use-axios';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { Page } from 'src/pages/operators/assignments/details/page';
import { Forbidden } from 'src/utils/errors';

export const AssigmentDetails = () => {
  const { operatorUuid, userUuid } = useParams<{
    operatorUuid: string;
    userUuid: string;
  }>();

  const tsoPlatformIds = useOperatorPlatformId(operatorUuid);
  const oldUserId = useOldUserId({ userUuid });
  const assignmentService = useAssignmentServicePublic();
  const organisationServicePublic = useOrganisationServicePublic();
  const operatorDetails = usePublicOperatorDetails({
    organisationServicePublic,
    operatorUuid,
  });

  if (
    !oldUserId.response ||
    !tsoPlatformIds.response ||
    !operatorDetails.response ||
    !assignmentService.response
  )
    return <PageSpinner />;

  if (oldUserId.response.data.customerUserId === undefined)
    throw new Forbidden();

  return (
    <AssignmentsDetailsWithOldId
      oldTsoId={tsoPlatformIds.response.data.referenceId.toString()}
      userId={oldUserId.response.data.customerUserId.toString()}
      operatorDetails={operatorDetails}
      operatorUuid={operatorUuid}
      assignmentService={assignmentService}
    />
  );
};

type Props = {
  oldTsoId: string;
  operatorUuid: string;
  userId: string;
  operatorDetails: Successful<PublicOperatorDetailsRequest>;
  assignmentService: Successful<AssignmentServiceRequest>;
};

const AssignmentsDetailsWithOldId: FC<Props> = ({
  oldTsoId,
  operatorUuid,
  userId,
  operatorDetails,
  assignmentService,
}) => {
  const tso = useTso(oldTsoId);
  const contractManagement = useContractManagement();
  const organisationAssignments = useOrganisationAssignments();
  const userAssignment = useUserAssignment({
    tsoId: oldTsoId,
    userId,
  });
  const shipperUser = useShipperUser(userId);
  const caMaOperator = useOperatorByTso({ tso });
  const assignmentInfo = useAssignmentInfo({
    assignmentService,
    operatorId: operatorUuid,
  });

  const pageParams = usePageParamsFromSchema(
    getPaginationSchema({ pageSize: 5 })
  );
  const params = useMemo(
    () =>
      ({
        limit: pageParams.value.pageSize,
        offset: pageParams.value.start,
      }) satisfies ContactsParams,
    [pageParams.value]
  );

  const contacts = useOptionalContacts({
    assignmentInfo,
    params,
  });

  const publicRequiredDocuments = usePublicRequiredDocuments({
    assignmentService,
    operatorId: operatorUuid,
  });

  if (
    !tso.response ||
    !shipperUser.response ||
    !organisationAssignments.response ||
    !publicRequiredDocuments.response ||
    contacts?.response === null ||
    !userAssignment.response ||
    (caMaOperator && !caMaOperator.loaded) ||
    (!contractManagement.response &&
      !isNetworkError(contractManagement.error)) ||
    !assignmentInfo?.response
  ) {
    return <PageSpinner />;
  }

  return (
    <Page
      tsoId={oldTsoId}
      userId={userId}
      tso={tso}
      shipperUser={shipperUser}
      contractManagement={contractManagement}
      organisationAssignments={organisationAssignments}
      userAssignment={userAssignment}
      publicRequiredDocuments={publicRequiredDocuments}
      caMaOperator={caMaOperator}
      operatorDetails={operatorDetails}
      assignmentInfo={assignmentInfo}
      contacts={contacts}
    />
  );
};
