import { FC, Suspense } from 'react';
import { Navigate, Route, useLocation, useParams } from 'react-router-dom';
import {
  ContractManagementRequest,
  useContractManagement,
} from 'src/apis/contract-management/use-contract-management';
import { Shipper } from 'src/apis/monolith/types';
import { useShipperById } from 'src/apis/monolith/use-shipper-by-id';
import {
  ShipperPlatformIdsRequest,
  useShipperPlatformIds,
} from 'src/apis/monolith/use-shipper-platform-ids';
import { useOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { isNetworkError, Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useReferrer } from 'src/hooks/use-referrer';
import { FinancialSecurityDetails } from 'src/pages/operators/assignments/details/financial-securities/details';
import { ShipperAdmins } from 'src/pages/shippers/details/admins';
import { ContactDetails } from 'src/pages/shippers/details/contact-details';
import { ShipperContracts } from 'src/pages/shippers/details/contracts';
import { ShippersContractDetails } from 'src/pages/shippers/details/contracts/contract-details';
import { EditCreditLimit } from 'src/pages/shippers/details/contracts/edit-credit-limit';
import { ShipperDocuments } from 'src/pages/shippers/details/documents';
import { ShipperFinancialSecurities } from 'src/pages/shippers/details/financial-securities';
import { FinancialSecurityAmendmentApproval } from 'src/pages/shippers/details/financial-securities/amendment-approval';
import { ShipperInformation } from 'src/pages/shippers/details/information';
import {
  ContractManagementShipperRequest,
  useContractManagementShipper,
} from 'src/pages/shippers/details/information/use-contract-management-shipper';
import { ShipperInformationSidebar } from 'src/pages/shippers/details/shipper-information-sidebar';
import { ParamsGuard } from 'src/utils/params-guard';

export const ShipperDetails: FC = () => {
  return (
    <RootRoutes>
      {/* old workaround for TAG-4209*/}
      <Route
        path="/mail/shippers/:shipperUuid/financial-securities/:financialSecurityType/:financialSecurityId/*"
        element={
          <ParamsGuard
            name="financialSecurityType"
            regExp={
              /^(cash-deposit|bank-guarantee|rating|parent-company-guarantee)$/
            }
          >
            <RedirectMailFinancialSecurity />
          </ParamsGuard>
        }
      />

      <Route
        path="/dashboard/shippers/:shipperUuid/financial-securities/cash-deposit/:financialSecurityId/amendment/approve/*"
        element={<FinancialSecurityAmendmentApproval type="CASH_DEPOSIT" />}
      />
      <Route
        path="/dashboard/shippers/:shipperUuid/financial-securities/bank-guarantee/:financialSecurityId/amendment/approve/*"
        element={<FinancialSecurityAmendmentApproval type="BANK_GUARANTEE" />}
      />
      <Route
        path="/dashboard/shippers/:shipperUuid/financial-securities/rating/:financialSecurityId/amendment/approve/*"
        element={<FinancialSecurityAmendmentApproval type="RATING" />}
      />
      <Route
        path="/dashboard/shippers/:shipperUuid/financial-securities/parent-company-guarantee/:financialSecurityId/amendment/approve/*"
        element={
          <FinancialSecurityAmendmentApproval type="PARENT_COMPANY_GUARANTEE" />
        }
      />

      <Route
        path="/dashboard/shippers/:shipperUuid/financial-securities/cash-deposit/:financialSecurityId/*"
        element={<FinancialSecurityDetails type="CASH_DEPOSIT" />}
      />
      <Route
        path="/dashboard/shippers/:shipperUuid/financial-securities/rating/:financialSecurityId/*"
        element={<FinancialSecurityDetails type="RATING" />}
      />
      <Route
        path="/dashboard/shippers/:shipperUuid/financial-securities/bank-guarantee/:financialSecurityId/*"
        element={<FinancialSecurityDetails type="BANK_GUARANTEE" />}
      />
      <Route
        path="/dashboard/shippers/:shipperUuid/financial-securities/parent-company-guarantee/:financialSecurityId/*"
        element={<FinancialSecurityDetails type="PARENT_COMPANY_GUARANTEE" />}
      />

      <Route
        path="/dashboard/shippers/:shipperUuid/*"
        element={<LoadShipperId />}
      />

      <Route
        path="/shippers/:shipperRefId/*"
        element={<RedirectShipperPages />}
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};

const LoadShipperId = () => {
  const { shipperUuid } = useParams<{ shipperUuid: string }>();
  const shipperPlatformIds = useShipperPlatformIds(shipperUuid);

  if (!shipperPlatformIds.response) return <PageSpinner />;

  return <RoutesWithShipper shipperPlatformIds={shipperPlatformIds} />;
};

const RoutesWithShipper: FC<{
  shipperPlatformIds: Successful<ShipperPlatformIdsRequest>;
}> = ({ shipperPlatformIds }) => {
  const shipper = useShipperById(
    shipperPlatformIds.response.data.referenceId.toString()
  );
  const contractManagement = useContractManagement();

  if (
    !shipper.response ||
    (!contractManagement.response && !isNetworkError(contractManagement.error))
  )
    return <PageSpinner />;

  return (
    <RootRoutes>
      <Route
        path="/dashboard/shippers/:shipperUuid/admins/*"
        element={<ShipperAdmins shipper={shipper} />}
      />

      <Route
        path="/dashboard/shippers/:shipperUuid/*"
        element={
          contractManagement.response?.data._links.shipper ? (
            <LoadContractManagementShipper
              shipper={shipper.response.data}
              contractManagement={contractManagement}
              shipperPlatformIds={shipperPlatformIds}
            />
          ) : (
            <PagesWithSidebar
              shipper={shipper.response.data}
              contractManagement={contractManagement}
              shipperPlatformIds={shipperPlatformIds}
            />
          )
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};

type LoadContractManagementShipperProps = {
  shipper: Shipper;
  contractManagement: ContractManagementRequest;
  shipperPlatformIds: Successful<ShipperPlatformIdsRequest>;
};

const LoadContractManagementShipper: FC<LoadContractManagementShipperProps> = ({
  shipper,
  contractManagement,
  shipperPlatformIds,
}) => {
  const contractManagementShipper = useContractManagementShipper({
    shipperUuid: shipper.organizationId,
    shipperUrl: contractManagement.response!.data._links.shipper!.href,
  });

  if (
    !contractManagementShipper.response?.data ||
    contractManagementShipper.pending
  )
    return <PageSpinner />;

  return (
    <PagesWithSidebar
      shipper={shipper}
      contractManagement={contractManagement}
      contractManagementShipper={contractManagementShipper}
      shipperPlatformIds={shipperPlatformIds}
    />
  );
};

type PagesWithSidebarProps = {
  shipper: Shipper;
  contractManagement: ContractManagementRequest;
  contractManagementShipper?: ContractManagementShipperRequest;
  shipperPlatformIds: Successful<ShipperPlatformIdsRequest>;
};

const PagesWithSidebar: FC<PagesWithSidebarProps> = ({
  shipper,
  contractManagement,
  contractManagementShipper,
  shipperPlatformIds,
}) => {
  const { minTablet } = useBreakpoints();
  const { shipperUuid } = useParams<{ shipperUuid: string }>();
  const organisationService = useOrganisationService();

  const referrer = useReferrer({
    label: 'Organisation',
    location: '/shippers/organisations',
  });
  if (!organisationService.response) return <PageSpinner />;

  return (
    <Stack gap={1}>
      <PageHeader title={shipper.name} backLink={referrer.backLink} />

      <Stack
        gap={[1, 3]}
        templateColumns={minTablet ? '1fr 5fr' : '1fr'}
        alignItems="start"
      >
        <ShipperInformationSidebar
          contractManagement={contractManagement.response?.data}
          contractManagementShipper={contractManagementShipper?.response?.data}
          shipperPlatformIds={shipperPlatformIds}
          organisationService={organisationService}
        />

        <div>
          <Suspense fallback={<PageSpinner />}>
            <RootRoutes>
              <Route
                path="/dashboard/shippers/:shipperUuid/contracts/:contractType/:contractId/details/*"
                element={
                  <ParamsGuard
                    name="contractType"
                    regExp={/^(fmct|dect|lect|fmct-offer|dect-offer)$/}
                  >
                    <ShippersContractDetails />
                  </ParamsGuard>
                }
              />

              <Route
                path="/dashboard/shippers/:shipperUuid/contracts/lect/:contractId/edit-credit-limit/*"
                element={<EditCreditLimit />}
              />

              <Route
                path="/dashboard/shippers/:shipperUuid/contracts/*"
                element={
                  <ShipperContracts
                    shipper={shipper}
                    contractManagement={contractManagement}
                  />
                }
              />

              <Route
                path="/dashboard/shippers/:shipperUuid/documents/*"
                element={
                  <ShipperDocuments
                    shipper={shipper}
                    shipperPlatformIds={shipperPlatformIds}
                  />
                }
              />
              <Route
                path="/dashboard/shippers/:shipperUuid/contact-details/"
                element={
                  <ContactDetails
                    shipperUuid={shipperUuid}
                    organisationService={organisationService}
                  />
                }
              />
              <Route
                path="/dashboard/shippers/:shipperUuid/information/*"
                element={
                  <ShipperInformation
                    shipper={shipper}
                    contractManagement={contractManagement}
                    shipperPlatformIds={shipperPlatformIds}
                    shipperUuid={shipperUuid}
                    organisationService={organisationService}
                  />
                }
              />

              <Route
                path="/dashboard/shippers/:shipperUuid/financial-securities/*"
                element={
                  <ShipperFinancialSecurities
                    shipper={shipper}
                    contractManagementShipper={contractManagementShipper}
                  />
                }
              />

              <Route
                path="/dashboard/shippers/:shipperUuid"
                element={
                  <Navigate
                    replace
                    to={`/dashboard/shippers/${shipperUuid}/information`}
                  />
                }
              />

              <Route path="*" element={<ThrowNotFound />} />
            </RootRoutes>
          </Suspense>
        </div>
      </Stack>
    </Stack>
  );
};

const RedirectShipperPages: FC = () => {
  const { shipperRefId } = useParams<{ shipperRefId: string }>();
  const { pathname, search, state } = useLocation();
  const shipper = useShipperById(shipperRefId);

  if (!shipper.response) return <PageSpinner />;

  return (
    <Navigate
      replace
      to={{
        pathname: pathname.replace(
          `/shippers/${shipperRefId}`,
          `/dashboard/shippers/${shipper.response.data.organizationId}`
        ),
        search,
      }}
      state={state}
    />
  );
};

const RedirectMailFinancialSecurity: FC = () => {
  const { shipperUuid, financialSecurityType, financialSecurityId } =
    useParams<{
      shipperUuid: string;
      financialSecurityType:
        | 'cash-deposit'
        | 'bank-guarantee'
        | 'rating'
        | 'parent-company-guarantee';
      financialSecurityId: string;
    }>();

  return (
    <Navigate
      replace
      to={`/dashboard/shippers/${shipperUuid}/financial-securities/${financialSecurityType}/${financialSecurityId}`}
    />
  );
};
