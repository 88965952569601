import { FC, ReactNode } from 'react';
import { RemitReportingSubscriptionInfoDetails } from 'src/apis/remit-reporting/types';
import { Card } from 'src/components/data-display/card';
import { FormatDate } from 'src/components/data-display/format-date';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { CustomizedToggle } from 'src/pages/settings/premium-services/customized-toggle';
import ReadOnlyOption from 'src/pages/settings/premium-services/read-only-option';
import { Colors } from 'src/styles';

const RemitSubscriptionCard: FC<{
  remitReportingSubscriptionInfoDetails: RemitReportingSubscriptionInfoDetails;
}> = ({ remitReportingSubscriptionInfoDetails }) => {
  const { minDesktop } = useBreakpoints();

  const active = remitReportingSubscriptionInfoDetails.active;
  const acerChannel =
    remitReportingSubscriptionInfoDetails.channels.includes(
      'SUBMISSION_TO_ACER'
    );
  const emailChannel =
    remitReportingSubscriptionInfoDetails.channels.includes('EMAIL');
  const issuerEic = remitReportingSubscriptionInfoDetails.issuerEic;
  const validFrom = remitReportingSubscriptionInfoDetails.validFrom;
  const email = remitReportingSubscriptionInfoDetails.email;
  const acerOnly = acerChannel && !emailChannel;
  const emailOnly = !acerChannel && emailChannel && !issuerEic;
  const acerAndEmail = acerChannel && emailChannel && !issuerEic;
  const emailEic = !acerChannel && emailChannel && !!issuerEic;

  return (
    <Card>
      <Stack gap={1} templateColumns={minDesktop ? '4fr 2fr' : '1fr'}>
        <div style={{ order: minDesktop ? 2 : 1, textAlign: 'right' }}>
          <CustomizedToggle
            isChecked={active}
            tooltip={`Please contact Customer Success to ${
              active ? 'deactivate' : 'activate'
            } the service.`}
          />
        </div>
        <div style={{ order: minDesktop ? 1 : 2 }}>
          <Heading mode="card">REMIT Reporting</Heading>
        </div>
      </Stack>
      <Stack gap={2}>
        {active ? (
          <>
            <p>
              If your company is using the REMIT Reporting Services of PRISMA
              for the Secondary Market, this section will provide you with an
              overview of all ACER Reporting Channels currently active for your
              company. Please note that, in case PRISMA shall not carry out the
              reporting to ACER on your behalf, you are obliged to organise the
              reporting on your own.
            </p>
            <p>
              To change the Reporting Channel, and/or find out your previous
              configuration, please contact our Customer Success Team.{' '}
              <ExternalLink
                mode="default-underlined"
                href="https://help.prisma-capacity.eu/support/solutions/articles/36000227907-1-reporting-models"
                target="_blank"
              >
                Learn&nbsp;more
              </ExternalLink>
              &nbsp;about REMIT Reporting in our Knowledge Base.
            </p>
            <p>
              Please note that if, according to the selected configuration,
              PRISMA is not supposed to carry out the reporting to ACER on your
              behalf, you are obliged to arrange for the subsequent reporting to
              ACER on your own.
            </p>
          </>
        ) : (
          <p>
            To place an order for PRISMA REMIT Transaction Data Reporting
            Services, please follow the steps described in our&nbsp;
            <ExternalLink
              mode="default-underlined"
              href="https://prisma-capacity.freshdesk.com/support/solutions/articles/36000260269"
              target="_blank"
            >
              Knowledge Base
            </ExternalLink>
            .
          </p>
        )}
        {active && (
          <Stack gap={2} data-testid="remit-subscription-info">
            <SubscriptionOption selected={acerOnly}>
              <span>PRISMA only sends the report to ACER</span>
              {acerOnly && (
                <Info>
                  <ServiceActivatedSpan validFrom={validFrom} />
                </Info>
              )}
            </SubscriptionOption>
            <SubscriptionOption selected={emailOnly}>
              <span>PRISMA only sends the report to you through E-mail</span>
              {emailOnly && (
                <Info>
                  <>
                    <ServiceActivatedSpan validFrom={validFrom} />
                    <EmailSpan email={email} />
                  </>
                </Info>
              )}
            </SubscriptionOption>
            <SubscriptionOption selected={acerAndEmail}>
              <span>PRISMA sends the report to both ACER and you</span>
              {acerAndEmail && (
                <Info>
                  <>
                    <ServiceActivatedSpan validFrom={validFrom} />
                    <EmailSpan email={email} />
                  </>
                </Info>
              )}
            </SubscriptionOption>
            <SubscriptionOption selected={emailEic}>
              <span>
                PRISMA sends you the report with the EIC of your RRM through
                E-mail
              </span>
              {emailEic && (
                <Info>
                  <>
                    <ServiceActivatedSpan validFrom={validFrom} />
                    <EmailSpan email={email} />
                    <span>EIC of RRM: {issuerEic}</span>
                  </>
                </Info>
              )}
            </SubscriptionOption>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

const SubscriptionOption: FC<{ selected: boolean; children: ReactNode }> = ({
  selected,
  children,
}) => {
  return (
    <ReadOnlyOption
      disabled
      selected={selected}
      tooltip={
        selected
          ? undefined
          : 'Please contact Customer Success to change your reporting channel.'
      }
    >
      {children}
    </ReadOnlyOption>
  );
};

const Info: FC<{ children: ReactNode }> = ({ children }) => {
  return <small style={{ color: Colors.brandLight2 }}>{children}</small>;
};

const EmailSpan: FC<{ email?: string }> = ({ email }) => {
  return email ? (
    <span style={{ marginRight: '1em' }}>Email recipient: {email}</span>
  ) : null;
};

const ServiceActivatedSpan: FC<{ validFrom?: string }> = ({ validFrom }) => {
  return validFrom ? (
    <span style={{ marginRight: '1em' }}>
      Service activated on <FormatDate value={validFrom} type="date-time" />
    </span>
  ) : null;
};

export default RemitSubscriptionCard;
