import { useState, useCallback, useEffect } from 'react';
import { createId } from 'src/utils/create-id';
import { reportToSentry } from 'src/utils/report-to-sentry';

const windowId = createId();

type BroadcastChannelOptions = {
  /**
   * By default a broadchast channel will also post a message
   * to the current window. Sometimes this is unwanted and you can opt-out
   * from this behavior by setting this flag to true.
   */
  ignoreCurrentWindow?: boolean;
};

type Envelope<T> = {
  windowId: string;
  message: T;
};

// we can't really know when a channel should be closed. when we do it on unmount
// we might run into errors if postMessage runs afterwards as part of an async callback.
// e.g. this could happen during login.
// to not create memory leaks we try to cache and re-use the few channels that we need
// and keep them as long as the application runs.
const channelCache: Record<string, BroadcastChannel> = {};

export const useBroadcastChannel = <T>(
  channelName: string,
  callback?: (message: T) => void,
  options?: BroadcastChannelOptions
) => {
  const [channel] = useState(() => {
    if (channelCache[channelName]) return channelCache[channelName];

    const channel = new BroadcastChannel(channelName);
    channelCache[channelName] = channel;
    return channel;
  });

  useEffect(() => {
    const controller = new AbortController();

    channel.addEventListener(
      'message',
      (event) => {
        const envelope = event.data as Envelope<T>;

        const sameWindow = envelope.windowId === windowId;
        if (sameWindow && options?.ignoreCurrentWindow) return;

        callback?.(envelope.message);
      },
      { signal: controller.signal }
    );
    channel.addEventListener('messageerror', (event) => reportToSentry(event), {
      signal: controller.signal,
    });

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const postMessage = useCallback(
    (message: T) => {
      const envelope: Envelope<T> = {
        windowId,
        message,
      };
      channel.postMessage(envelope);
    },
    [channel]
  );

  return postMessage;
};
