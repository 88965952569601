import { FC } from 'react';
import { AssignmentInfoRequest } from 'src/apis/assignment-service/use-assignment-info';
import { PublicRequiredDocumentsRequest } from 'src/apis/assignment-service/use-public-required-documents';
import {
  FinancialSecuritiesParams,
  RelationsParams,
} from 'src/apis/contract-management/types';
import { ContractManagementRequest } from 'src/apis/contract-management/use-contract-management';
import { useFinancialSecurities } from 'src/apis/contract-management/use-financial-securities';
import {
  OperatorRequest,
  useOperator,
} from 'src/apis/contract-management/use-operator';
import { OperatorRequest as CaMaOperatorRequest } from 'src/apis/contract-management/use-operator-by-tso';
import { useOperatorRelations } from 'src/apis/contract-management/use-operator-relations';
import { OrganisationAssignmentsRequest } from 'src/apis/monolith/use-organisation-assignments';
import { ShipperUserRequest } from 'src/apis/monolith/use-shipper-user';
import { TsoRequest } from 'src/apis/monolith/use-tso';
import { UserAssignmentRequest } from 'src/apis/monolith/use-user-assignment';
import { OptionalContactsRequest } from 'src/apis/organisation-service/use-optional-contacts';
import { PublicOperatorDetailsRequest } from 'src/apis/organisation-service/use-public-operator-details';
import { Card } from 'src/components/data-display/card';
import { CompanyRequiredDocuments } from 'src/components/domain-specifics/company-required-documents';
import { GeneralOperatorInformation } from 'src/components/domain-specifics/general-operator-information';
import { OperatorContactDetails } from 'src/components/domain-specifics/operator-conatct-details';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import { Heading } from 'src/components/text/heading';
import { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useReferrer } from 'src/hooks/use-referrer';
import { useTitle } from 'src/hooks/use-title';
import { BalancingGroups } from 'src/pages/operators/assignments/details/components/assignment-details-balancing-groups';
import { AssignmentDetailsContractingServices } from 'src/pages/operators/assignments/details/components/assignment-details-contracting-services';
import { CreditLimits } from 'src/pages/operators/assignments/details/components/assignment-details-credit-limits';
import { AssignmentDetailsOrganisationStatus } from 'src/pages/operators/assignments/details/components/assignment-details-organization-status';
import { PortfolioCodes } from 'src/pages/operators/assignments/details/components/assignment-details-portfolio-codes';
import { AssignmentDetailsUserStatus } from 'src/pages/operators/assignments/details/components/assignment-details-user-status';
import { Contracts } from 'src/pages/operators/assignments/details/components/contracts';
import { FinancialSecurities } from 'src/pages/operators/assignments/details/components/financial-securities';
import { useAssignmentsDetailsPageParams } from 'src/pages/operators/assignments/details/components/use-page-params';
import { MarketType } from 'src/pages/operators/assignments/overview/types';
import { useMemoOne } from 'use-memo-one';

export const Page: FC<PageProps> = (props) =>
  props.contractManagement.response?.data._links.operator?.href &&
  props.tso.response?.data.organizationId ? (
    <WithCMOperator {...props} />
  ) : (
    <OperatorDetailsPage {...props} />
  );

type PageProps = {
  tsoId: string;
  userId: string;
  tso: Successful<TsoRequest>;
  contractManagement: ContractManagementRequest;
  shipperUser: Successful<ShipperUserRequest>;
  organisationAssignments: Successful<OrganisationAssignmentsRequest>;
  userAssignment: Successful<UserAssignmentRequest>;
  publicRequiredDocuments: Successful<PublicRequiredDocumentsRequest>;
  operatorDetails: Successful<PublicOperatorDetailsRequest>;
  assignmentInfo: Successful<AssignmentInfoRequest>;
  contractManagementOperator?: OperatorRequest;
  caMaOperator?: CaMaOperatorRequest;
  contacts?: Successful<OptionalContactsRequest>;
};

const OperatorDetailsPage: FC<PageProps> = ({
  tsoId,
  userId,
  tso,
  shipperUser,
  organisationAssignments,
  userAssignment,
  publicRequiredDocuments,
  operatorDetails,
  assignmentInfo,
  contractManagementOperator,
  caMaOperator,
  contacts,
}) => {
  useTitle(tso.response.data.name);
  const referrer = useReferrer({
    label: 'Operators',
    location: '/operators/assignments/overview',
  });
  const { minDesktop } = useBreakpoints();
  const pageParams = useAssignmentsDetailsPageParams();

  const tsoAssignment = organisationAssignments.response.data.find(
    (assignedOperator) => assignedOperator.tso.id.toString() === tsoId
  );

  const userAssignmentData = userAssignment.response.data;
  const operatorDetailsResponse = operatorDetails.response.data;

  const relationParams = useMemoOne<RelationsParams>(
    () => ({
      offset: pageParams.relations.value.start,
      limit: pageParams.relations.value.pageSize,
    }),
    [pageParams.relations.value.start, pageParams.relations.value.pageSize]
  );
  const financialSecuritiesParams = useMemoOne<FinancialSecuritiesParams>(
    () => ({
      offset: pageParams.financialSecurities.value.start,
      limit: pageParams.financialSecurities.value.pageSize,
    }),
    [
      pageParams.financialSecurities.value.start,
      pageParams.financialSecurities.value.pageSize,
    ]
  );
  const relations = useOperatorRelations({
    url: contractManagementOperator?.response?.data._links?.getRelations?.href,
    params: relationParams,
  });
  const financialSecurities = useFinancialSecurities({
    url: contractManagementOperator?.response?.data._links
      ?.getFinancialSecurities?.href,
    params: financialSecuritiesParams,
  });

  if (!tsoAssignment) {
    // Type safety check, should never happen
    return <ThrowNotFound />;
  }

  const tsoAssignmentMapped = {
    organisationId: tsoAssignment.tso.id,
    organisationName: tsoAssignment.tso.name,
    transferCompanyData: tsoAssignment.transferCompanyData,
    status: tsoAssignment.state,
    logoUrl: `${PRISMA_CONFIG.monolithUrl}${tsoAssignment.tso.logoSmallUrl}`,
    marketAccess:
      tsoAssignment.state !== 'APPROVED'
        ? undefined
        : ([
            ...(tsoAssignment.primaryAccess ? ['PRIMARY'] : []),
            ...(tsoAssignment.secondaryAccess ? ['SECONDARY'] : []),
          ] as MarketType),
  };

  if (
    !(relations.response || relations.error) ||
    !(financialSecurities.response || financialSecurities.error)
  )
    return <PageSpinner />;

  return (
    <Stack gap={2}>
      {referrer.backLink}
      <Heading mode="section">{tso.response.data.name}</Heading>
      <Card>
        <GeneralOperatorInformation
          operatorDetails={operatorDetails.response.data}
        />
        <OperatorContactDetails
          contacts={contacts}
          assignmentInfo={assignmentInfo}
        />
      </Card>
      <Stack
        gap={1}
        templateColumns={minDesktop ? '1fr 1fr' : '1fr'}
        alignItems="stretch"
      >
        <AssignmentDetailsOrganisationStatus
          caMaOperator={caMaOperator}
          orgAssignment={tsoAssignmentMapped}
          operatorDetails={operatorDetailsResponse}
          tsoId={tsoId}
          title="Organisation Assignment"
        />
        <AssignmentDetailsUserStatus
          tsoId={tsoId}
          userId={userId}
          shipperUser={shipperUser}
          userAssignment={userAssignment}
          operatorName={operatorDetailsResponse.registeredAddress.companyName}
          orgAssignment={tsoAssignmentMapped}
          onCancelSuccess={() => userAssignment.refresh?.()}
        />
      </Stack>

      <CompanyRequiredDocuments
        tsoDetails={operatorDetailsResponse}
        publicRequiredDocuments={publicRequiredDocuments}
      />
      <AssignmentDetailsContractingServices tso={tso} />
      {relations.response && contractManagementOperator?.response?.data ? (
        <Contracts
          tsoId={tsoId}
          operator={contractManagementOperator.response.data}
          relations={relations}
        />
      ) : null}
      {contractManagementOperator?.response?.data && (
        <FinancialSecurities
          tso={tso}
          operator={contractManagementOperator.response.data}
          financialSecurities={financialSecurities}
        />
      )}

      {userAssignmentData.state !== 'NOT_REQUESTED' && (
        <>
          {userAssignmentData.balancingGroups.length > 0 && (
            <BalancingGroups
              operatorOldId={tso.response.data.id}
              balancingGroups={userAssignmentData.balancingGroups}
            />
          )}

          {userAssignmentData.limits.length > 0 && (
            <CreditLimits
              operatorOldId={tso.response.data.id}
              creditLimits={userAssignmentData.limits}
            />
          )}

          {userAssignmentData.portfolioCodes.length > 0 && (
            <PortfolioCodes
              operatorOldId={tso.response.data.id}
              portfolioCodes={userAssignmentData.portfolioCodes}
            />
          )}
        </>
      )}
    </Stack>
  );
};

const WithCMOperator: FC<PageProps> = (props) => {
  const contractManagementOperator = useOperator({
    operatorUrl: props.contractManagement.response!.data._links.operator!.href,
    operatorUuid: props.tso.response.data.organizationId,
  });

  if (!contractManagementOperator.response && !contractManagementOperator.error)
    return <PageSpinner />;

  return (
    <OperatorDetailsPage
      {...props}
      contractManagementOperator={contractManagementOperator}
    />
  );
};

const PageWrapper: FC<PageProps> = (props) =>
  props.contractManagement.response?.data._links.operator?.href &&
  props.tso.response?.data.organizationId ? (
    <WithCMOperator {...props} />
  ) : (
    <Page {...props} />
  );

export default PageWrapper;
