import { FC } from 'react';
import {
  documentTypeLabels,
  OperatorDocument,
  OperatorDocumentTableList,
} from 'src/apis/document-exchange/types';
import { FormatDate } from 'src/components/data-display/format-date';
import { Table } from 'src/components/data-display/smart-table';
import { SpecificSortHeader, Td, Th } from 'src/components/data-display/table';
import { ActionsWrapper } from 'src/pages/shippers/documents/actions';
import { DownloadDocumentLink } from 'src/pages/shippers/documents/download-document-link';
import {
  DocumentStatus,
  ShipperDocumentStatusDisplay,
} from 'src/pages/shippers/documents/status';
import { PageParams } from 'src/pages/shippers/documents/use-page-params';

type Props = {
  type: 'INBOX' | 'OUTBOX';
  pageParams: PageParams;
  documents: OperatorDocumentTableList;
  refreshDocuments?: () => void;
  showActions?: boolean;
  pending: boolean;
};

export const OperatorDocumentsTable: FC<Props> = ({
  type,
  pageParams,
  documents,
  refreshDocuments,
  showActions = true,
  pending,
}) => {
  return (
    <Table
      data-testid="documents-table"
      pending={pending}
      paginated={documents}
      data={documents._embedded.items}
      setId={(data) => data.operatorDocumentId}
      cols={[
        {
          key: 'changedAt',
          head: (
            <SpecificSortHeader
              label="Date"
              column="CHANGED_AT"
              params={pageParams}
              sortParam="sortBy"
              directionParam="sortDirection"
              data-testid="date-header-sort"
            />
          ),
          body: (data, index) => (
            <Td data-testid={`changedAt-${index}`}>
              <FormatDate value={data.changedAt} type="date-time" />
            </Td>
          ),
        },
        {
          key: 'name',
          head: <Th>{pageParams.filter.documentName.label}</Th>,
          body: (data, index) => (
            <Td data-testid={`name-${index}`}>
              {data._links.getCurrentOperatorDocumentFile ? (
                <DownloadDocumentLink
                  getCurrentOperatorDocumentFile={
                    data._links.getCurrentOperatorDocumentFile.href
                  }
                >
                  {data.documentName}
                </DownloadDocumentLink>
              ) : (
                <>{data.documentName}</>
              )}
            </Td>
          ),
        },
        {
          key: 'description',
          head: <Th>{pageParams.filter.documentDescription.label}</Th>,
          body: (data, index) => (
            <Td data-testid={`description-${index}`}>
              {data.documentDescription}
            </Td>
          ),
        },
        {
          key: 'type',
          head: <Th>{pageParams.filter.documentType.label}</Th>,
          body: (data, index) => (
            <Td data-testid={`type-${index}`}>
              {documentTypeLabels[data.documentType]}
            </Td>
          ),
        },
        ...(type === 'OUTBOX'
          ? [
              {
                key: 'status',
                head: <Th>{pageParams.filter.documentStatus.label}</Th>,
                body: (data: OperatorDocument, index: number) => (
                  <Td>
                    <ShipperDocumentStatusDisplay
                      value={data.documentStatus as DocumentStatus}
                      data-testid={`status-${index}`}
                    />
                  </Td>
                ),
              },
              ...(showActions
                ? [
                    {
                      key: 'actions',
                      width: '8rem',
                      head: <Th align="center">Actions</Th>,
                      body: (data: OperatorDocument, index: number) => (
                        <Td data-testid={`actions-${index}`} align="center">
                          <ActionsWrapper
                            data={data}
                            refreshDocuments={refreshDocuments}
                            index={index}
                          />
                        </Td>
                      ),
                    },
                  ]
                : []),
            ]
          : []),
        ...(type === 'INBOX'
          ? [
              {
                key: 'operatorName',
                head: <Th>Operator</Th>,
                body: (data: OperatorDocument, index: number) => (
                  <Td data-testid={`operator-name-${index}`}>
                    {data.operatorName}
                  </Td>
                ),
              },
            ]
          : []),
      ]}
      empty={
        pageParams.hasChangedFilters
          ? {
              label:
                'No results match your search criteria. Try changing or resetting the filters.',
              button: { onClick: pageParams.resetFilters },
            }
          : {
              label:
                type === 'INBOX'
                  ? 'No documents have been sent by operators in this period.'
                  : 'No documents sent to shippers in this period.',
            }
      }
    />
  );
};
