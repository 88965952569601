import { FC, lazy } from 'react';
import { CurtailmentsParams } from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useCurtailments } from 'src/apis/capacity-management/use-curtailments';
import { usePageParams } from 'src/pages/reporting/monthly-statements/curtailments/schema';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

type Params = {
  capacityManagementRoot: CapacityManagementRootRequest;
};

export const CurtailmentsOverview: FC<Params> = ({
  capacityManagementRoot,
}) => {
  const pageParams = usePageParams();

  const params = useMemoOne<CurtailmentsParams>(() => {
    const { start, pageSize, shipperId, sortBy, sortDirection, ...rest } =
      pageParams.value;
    return {
      offset: start,
      limit: pageSize,
      sortBy: 'CURTAILMENT_PERIOD_START',
      sortDirection: 'asc',
      shipperId: shipperId.map(({ value }) => value),
      ...rest,
    };
  }, [pageParams.value]);

  const curtailments = useCurtailments({ capacityManagementRoot, params });

  return <Page curtailments={curtailments} pageParams={pageParams} />;
};
