import { createContext, FC, ReactNode, useContext } from 'react';
import { useMatchMedia } from 'src/hooks/use-match-media';

const IsPrintContext = createContext(false);

export function useIsPrint() {
  return useContext(IsPrintContext);
}

export const IsPrintProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const isPrint = useMatchMedia('print');
  return (
    <IsPrintContext.Provider value={isPrint}>
      {children}
    </IsPrintContext.Provider>
  );
};
