import { FC, lazy } from 'react';
import { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useRequiredDocuments } from 'src/apis/assignment-service/use-required-documents';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () =>
    import(
      'src/pages/settings/assignment-settings/required-information/edit/page'
    )
);

type AssignmentServiceProps = {
  assignmentService: Successful<AssignmentServiceRequest>;
};

export const EditRequiredInformation: FC<AssignmentServiceProps> = ({
  assignmentService,
}) => {
  const prepareFileUploadUrl =
    assignmentService.response.data._links.prepareFileUpload?.href;

  if (
    !assignmentService.response.data._links.requiredDocuments ||
    !prepareFileUploadUrl
  ) {
    throw new Forbidden();
  }

  const requiredInformation = useRequiredDocuments(
    assignmentService.response.data._links.requiredDocuments.href
  );

  if (!requiredInformation.response) return <PageSpinner />;

  return (
    <Page
      requiredInformation={requiredInformation}
      prepareFileUploadUrl={prepareFileUploadUrl}
    />
  );
};
