import { FC, TimeHTMLAttributes } from 'react';
import {
  dateFormat,
  dateTimeFormat,
  dateTimeWithSecondsFormat,
  timeFormat,
} from 'src/utils/date-time-format';
import { StrictOmit } from 'src/utils/utility-types';
import { useMemoOne } from 'use-memo-one';

export type DateType = 'date' | 'date-time' | 'time' | 'date-time-with-seconds';

type Props = {
  value: string;
  type?: DateType;
} & StrictOmit<TimeHTMLAttributes<HTMLTimeElement>, 'dateTime'>;

export function getFormat(type: DateType) {
  switch (type) {
    case 'date':
      return dateFormat;
    case 'date-time':
      return dateTimeFormat;
    case 'time':
      return timeFormat;
    case 'date-time-with-seconds':
      return dateTimeWithSecondsFormat;
  }
}

export const FormatDate: FC<Props> = ({ value, type = 'date', ...props }) => {
  const formatted = useMemoOne(() => {
    const date = new Date(value);
    return date.toLocaleString('de-DE', getFormat(type));
  }, [type, value]);

  return (
    <time dateTime={value} {...props}>
      {formatted}
    </time>
  );
};
