import { FC, Suspense } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAssignmentServicePublic } from 'src/apis/assignment-service/use-assignment-service-root';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { usePortal } from 'src/hooks/use-portal';
import { CreditLimitsOverview } from 'src/pages/shippers/credit-limits';
import { ShippersDocumentsOverview } from 'src/pages/shippers/documents';
import { InvoicesOverview } from 'src/pages/shippers/invoices';
import { ShippersListOverview } from 'src/pages/shippers/organisations';
import { AssignedUsers } from 'src/pages/shippers/organisations-and-users';
import { ShipperNavigationBar } from 'src/pages/shippers/shipper-navigation-bar';

export const ShippersView: FC = () => {
  const actionPortal = usePortal();
  const assignmentService = useAssignmentServicePublic();

  if (!assignmentService.response) return <PageSpinner />;

  return (
    <Stack gap={1}>
      <PageHeader title="Shippers" />

      <ShipperNavigationBar
        actionPortal={actionPortal}
        assignmentService={assignmentService}
      />

      <Suspense fallback={<PageSpinner />}>
        <RootRoutes>
          <Route
            path="/shippers/organisations"
            element={<ShippersListOverview />}
          />

          <Route
            path="/shippers/documents"
            element={<ShippersDocumentsOverview />}
          />

          <Route
            path="/shippers/invoices"
            element={<InvoicesOverview actionPortal={actionPortal} />}
          />

          <Route
            path="/shippers/organisations-and-users"
            element={<AssignedUsers assignmentService={assignmentService} />}
          />

          <Route path="*" element={<ThrowNotFound />} />

          <Route
            path="/shippers/credit-limit"
            element={<CreditLimitsOverview />}
          />

          <Route
            path="/shippers/list/*"
            element={<Navigate replace to="/shippers/organisations" />}
          />
        </RootRoutes>
      </Suspense>
    </Stack>
  );
};
