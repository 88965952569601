import { FC, lazy, useMemo } from 'react';
import { ContactsParams } from 'src/apis/organisation-service/types';
import { useAssignedShipperDetails } from 'src/apis/organisation-service/use-assigned-shipper-details';
import { useOptionalShipperContacts } from 'src/apis/organisation-service/use-optional-shipper-contacts';
import { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
  shipperUuid: string;
};

export const ContactDetails: FC<Props> = ({
  organisationService,
  shipperUuid,
}) => {
  if (!organisationService.response.data._links.getAssignedShipperDetails)
    throw new Forbidden();

  const pageParams = usePageParamsFromSchema(
    getPaginationSchema({ pageSize: 5 })
  );

  const params = useMemo(
    () =>
      ({
        limit: pageParams.value.pageSize,
        offset: pageParams.value.start,
      }) satisfies ContactsParams,
    [pageParams.value]
  );

  const assignedShipperDetails = useAssignedShipperDetails({
    assignedShipperDetailsUrl:
      organisationService.response.data._links.getAssignedShipperDetails.href,
    shipperUuid,
  });

  const shipperContacts = useOptionalShipperContacts({
    assignedShipperDetails,
    params,
  });

  if (!assignedShipperDetails.response || shipperContacts?.response === null)
    return <PageSpinner />;

  return (
    <Page
      assignedShipperDetails={assignedShipperDetails}
      shipperContacts={shipperContacts}
    />
  );
};
