import { memo } from 'react';
import { StatusDisplay } from 'src/components/data-display/status-display';
import { Colors } from 'src/styles';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export type DocumentStatus = 'WITHDRAWN' | 'PUBLISHED';

export const documentStatusLabels: Record<DocumentStatus, string> = {
  WITHDRAWN: 'Withdrawn',
  PUBLISHED: 'Published',
};

type Props = {
  value: DocumentStatus;
  'data-testid'?: string;
};

export const documentStatusOptions = labelsToOptions(documentStatusLabels);

export const documentStatusValues = labelsToValues(documentStatusLabels);

const colorMap: Record<DocumentStatus, string> = {
  WITHDRAWN: Colors.inactive,
  PUBLISHED: Colors.success,
};

export const ShipperDocumentStatusDisplay = memo<Props>(
  ({ value, 'data-testid': testId }) => {
    return (
      <StatusDisplay
        status={documentStatusLabels[value]}
        iconColor={colorMap[value]}
        data-testid={testId}
      />
    );
  }
);
