import { startOfDay, subMonths } from 'date-fns';
import { useNow } from 'src/hooks/use-now';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getDocumentsPageParamsSchema } from 'src/pages/shippers/documents/schema';

export function usePageParams() {
  const now = useNow();
  const dateMinus6Months = startOfDay(subMonths(now, 6));

  return usePageParamsFromSchema(
    getDocumentsPageParamsSchema({
      defaultStartDate: dateMinus6Months.toISOString(),
    }),
    {
      filters: {
        documentStatus: { label: 'Status' },
        documentType: { label: 'Type' },
        documentName: { label: 'Name' },
        documentDescription: { label: 'Description' },
        changedAtStart: { label: 'Date' },
        changedAtEnd: { label: 'Date' },
      },
    }
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
