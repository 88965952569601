import { FC } from 'react';
import { Route } from 'react-router-dom';
import {
  AssignmentServiceRequest,
  useAssignmentServicePublic,
} from 'src/apis/assignment-service/use-assignment-service-root';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { OperatorsListOverviewPage } from 'src/pages/operators/operator-assignments/overview';

export const OperatorsListOverview = () => {
  const assignmentService = useAssignmentServicePublic();

  if (!assignmentService.response) return <PageSpinner />;

  return <OperatorsListOverviewPage assignmentService={assignmentService} />;
};

export const OperatorAssignments: FC<{
  assignmentService: Successful<AssignmentServiceRequest>;
}> = ({ assignmentService }) => {
  return (
    <RootRoutes>
      <Route
        path="/operators/operator-assignments/overview/*"
        element={
          <OperatorsListOverviewPage assignmentService={assignmentService} />
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
