import { Stack } from 'src/components/layout/stack';
import { isNetworkError, isServerError } from 'src/hooks/use-axios';
import { ToastContextValue } from 'src/hooks/use-toasts';
import {
  BalancingGroupAllocationForbidden,
  Forbidden,
  Gone,
  MissingMock,
  NotFound,
  Unauthorized,
} from 'src/utils/errors';
import { getTraceId } from 'src/utils/get-trace-id';
import { isObject } from 'src/utils/is-object';
import { isVerificationProblemWithoutField } from 'src/utils/is-verification-problem-without-field';
import { reportToSentry } from 'src/utils/report-to-sentry';

export function unknownMonolithError(msg: string) {
  if (msg === 'NullPointerException') return true;
  if (msg.startsWith('de.tracx')) return true;
  return false;
}

export function handleError(
  error: unknown,
  setReauthenticate: (value: boolean) => void
): [string, boolean] {
  if (isNetworkError(error)) {
    // note: we treat every axios error that has no response as a network error, but the
    // reasons why we don't have a response can be different. E.g. we can see
    // code === 'ERR_NETWORK' for CORS/firewall issues/blocked domains or code === 'ECONNABORTED'
    // for timeouts.
    return [
      error.code === 'ECONNABORTED'
        ? `A request took too long and was cancelled.`
        : `Request couldn't be made. Please check your network connectivity and firewall settings.`,
      // if you came to this place to enable logging for CORS keep in mind that
      // network errors happen for a lot of other reasons as well (flaky internet, no VPN, etc.).
      // in that past we got _so many_ false alarms that we decided to completely turn off
      // the reporting of network errors.
      // if you'd like to monitor something like CORS errors, find a different way (e.g. inspect
      // request/response headers on the server side).
      false,
    ];
  } else if (
    (isServerError(error, 400) || isServerError(error, 500)) &&
    error.config.url?.startsWith(PRISMA_CONFIG.monolithApiUrl) &&
    isObject(error.response.data) &&
    ((typeof (error.response.data as any).message === 'string' &&
      (error.response.data as any).message) || // sometimes the message is an empty string, so check description as well
      (typeof (error.response.data as any).description === 'string' &&
        (error.response.data as any).description))
  ) {
    // the old backend sometimes responds with human readable error messages
    // e.g. https://sentry.io/organizations/prisma-european-capacity-platf/issues/2397095793/?project=1832012
    const msg: string =
      (error.response.data as any).message ||
      (error.response.data as any).description;
    if (unknownMonolithError(msg))
      return [`An unknown error happened. We're sorry.`, true];
    else return [msg, false];
  } else if (isServerError(error, 401) || error instanceof Unauthorized) {
    setReauthenticate(true);
    return ['Your session has expired. Please login again.', false];
  } else if (isServerError(error, 403) || error instanceof Forbidden) {
    return [`You don’t have permission to do this action.`, false];
  } else if (isServerError(error, 404) || error instanceof NotFound) {
    return [`The requested document could not be found.`, false];
  } else if (isServerError(error, 410) || error instanceof Gone) {
    return [`The requested document is gone.`, false];
  } else if (isVerificationProblemWithoutField(error)) {
    return [error.response.data.message, false];
  } else if (
    isObject(error) &&
    (error as any).name === 'NS_ERROR_FILE_CORRUPTED'
  ) {
    return [`The browser cache is corrupted.`, false];
  } else if (error instanceof MissingMock) {
    return [`This feature is not mocked in Storybook.`, false];
  } else if (error instanceof BalancingGroupAllocationForbidden) {
    return [error.value, false];
  } else {
    return [`An unknown error happened. We're sorry.`, true];
  }
}

// this is most commonly used for errors that happen *after* the page has loaded,
// so we don't want to show an error page, but just an error notification
export function handleErrorWithNotification({
  notify,
  error,
  setReauthenticate,
}: {
  notify: ToastContextValue;
  error: unknown;
  setReauthenticate: (value: boolean) => void;
}) {
  const [message, report] = handleError(error, setReauthenticate);
  const traceId = isServerError(error, null) ? getTraceId(error) : undefined;
  notify({
    type: 'error',
    children:
      report && traceId ? (
        <Stack>
          <p>{message}</p>
          <small>(Trace ID: {traceId})</small>
        </Stack>
      ) : (
        message
      ),
  });

  if (report) reportToSentry(error);
}
