import { FC } from 'react';
import { FilterCard } from 'src/components/data-display/expandable-filters';
import { Input } from 'src/components/form/input';
import { PageParams } from 'src/pages/storage/locations/use-page-params';

type TableFilter = PageParams['filterValues'];

type FilterCardProps = {
  pageParams: PageParams;
};

export const LocationsFilterCard: FC<FilterCardProps> = ({ pageParams }) => (
  <FilterCard
    pageParams={pageParams}
    constraintViolation={null}
    filters={{
      favoritesOnly: {
        type: 'value',
        mappedValue:
          pageParams.value.favoritesOnly === true ? "Your SSO's only" : 'All',
        field: null,
      },

      name: {
        type: 'value',
        field: <Input name="name" label={pageParams.filter.name.label} />,
      },
      refId: {
        type: 'value',
        field: <Input name="refId" label={pageParams.filter.refId.label} />,
      },
      ssoShortName: {
        type: 'value',
        field: (
          <Input
            name="ssoShortName"
            label={pageParams.filter.ssoShortName.label}
          />
        ),
      },
    }}
  />
);
