export const emailRegex = /^(.+)@(\S+)\.(\S+)$/;
export const ibanRegex = /^[A-Z]{2}[0-9]{2}[0-9A-Z \t]{1,30}$/;

export const urlRegex =
  /^[a-z]+:\/\/[-a-zA-Z0-9+&@#\/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#\/%=~_|]$/;

export const minIbanLength = 1;
export const maxIbanLength = 34;
export const minBicLength = 8;
export const maxBicLength = 11;
