import { lazy } from 'react';
import { useSurrenderRoot } from 'src/apis/surrender-service/use-surrender-root';
import { useTransaction } from 'src/apis/surrender-service/use-transaction';
import { PageSpinner } from 'src/components/spinner-container';

const Page = lazy(() => import('./page'));

export const CreateSurrenderRequest = () => {
  const surrenderRoot = useSurrenderRoot();
  const transactionRequest = useTransaction(surrenderRoot);

  if (!surrenderRoot.response || !transactionRequest.response)
    return <PageSpinner />;

  return (
    <Page
      surrenderRoot={surrenderRoot}
      transactionRequest={transactionRequest}
    />
  );
};
