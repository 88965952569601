import { isAfter, isBefore, isSameDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useNow } from 'src/hooks/use-now';
import { timeZone } from 'src/utils/date-time-format';

// Ignore time for the isBefore comparison.
function isBeforeDay(date: Date, dateToCompare: Date) {
  const immutableDate = new Date(date.getTime());
  const immutableDateToCompare = new Date(dateToCompare.getTime());

  immutableDate.setHours(0, 0, 0, 0);
  immutableDateToCompare.setHours(0, 0, 0, 0);

  return isBefore(immutableDate, immutableDateToCompare);
}

// Ignore time for the isAfter comparison.
function isAfterDay(date: Date, dateToCompare: Date) {
  const immutableDate = new Date(date.getTime());
  const immutableDateToCompare = new Date(dateToCompare.getTime());

  immutableDate.setHours(0, 0, 0, 0);
  immutableDateToCompare.setHours(0, 0, 0, 0);

  return isAfter(immutableDate, immutableDateToCompare);
}

export const useIsHighlightStart = (
  currentDate: Date,
  startDate: Date | null,
  endDate: Date | null,
  hoverDate: Date | null
) =>
  useMemo(() => {
    const currentDateBerlin = toZonedTime(currentDate, timeZone);
    const startDateBerlin = startDate && toZonedTime(startDate, timeZone);
    const endDateBerlin = endDate && toZonedTime(endDate, timeZone);
    const hoverDateBerlin = hoverDate && toZonedTime(hoverDate, timeZone);

    if (!startDateBerlin) {
      return false;
    }

    if (
      !endDateBerlin &&
      hoverDateBerlin &&
      isBeforeDay(hoverDateBerlin, startDateBerlin) &&
      isSameDay(currentDateBerlin, hoverDateBerlin)
    ) {
      return true;
    }

    if (
      !endDateBerlin &&
      hoverDateBerlin &&
      isAfterDay(hoverDateBerlin, startDateBerlin) &&
      isSameDay(currentDateBerlin, startDateBerlin)
    ) {
      return true;
    }

    if (
      endDateBerlin &&
      isAfterDay(endDateBerlin, startDateBerlin) &&
      isSameDay(currentDateBerlin, startDateBerlin)
    ) {
      return true;
    }

    return false;
  }, [currentDate, startDate, endDate, hoverDate]);

export const useIsHighlightEnd = (
  currentDate: Date,
  startDate: Date | null,
  endDate: Date | null,
  hoverDate: Date | null
) =>
  useMemo(() => {
    const currentDateBerlin = toZonedTime(currentDate, timeZone);
    const startDateBerlin = startDate && toZonedTime(startDate, timeZone);
    const endDateBerlin = endDate && toZonedTime(endDate, timeZone);
    const hoverDateBerlin = hoverDate && toZonedTime(hoverDate, timeZone);

    if (!startDateBerlin) {
      return false;
    }

    if (endDateBerlin && isSameDay(startDateBerlin, endDateBerlin)) {
      return false;
    }

    if (
      !endDateBerlin &&
      hoverDateBerlin &&
      isBeforeDay(hoverDateBerlin, startDateBerlin) &&
      isSameDay(currentDateBerlin, startDateBerlin)
    ) {
      return true;
    }

    if (endDateBerlin && isSameDay(currentDateBerlin, endDateBerlin)) {
      return true;
    }

    if (isSameDay(currentDateBerlin, startDateBerlin)) {
      return false;
    }

    if (
      !endDateBerlin &&
      hoverDateBerlin &&
      isAfterDay(hoverDateBerlin, startDateBerlin) &&
      isSameDay(currentDateBerlin, hoverDateBerlin)
    ) {
      return true;
    }

    return false;
  }, [currentDate, startDate, endDate, hoverDate]);

export const useIsHighlighted = (
  currentDate: Date,
  startDate: Date | null,
  endDate: Date | null,
  hoverDate: Date | null
) =>
  useMemo(() => {
    const currentDateBerlin = toZonedTime(currentDate, timeZone);
    const startDateBerlin = startDate && toZonedTime(startDate, timeZone);
    const endDateBerlin = endDate && toZonedTime(endDate, timeZone);
    const hoverDateBerlin = hoverDate && toZonedTime(hoverDate, timeZone);
    if (!startDateBerlin && !endDateBerlin) {
      return false;
    }

    if (
      startDateBerlin &&
      endDateBerlin &&
      isAfterDay(currentDateBerlin, startDateBerlin) &&
      isBeforeDay(currentDateBerlin, endDateBerlin)
    ) {
      return true;
    }

    if (
      !endDateBerlin &&
      startDateBerlin &&
      hoverDateBerlin &&
      isAfterDay(currentDateBerlin, startDateBerlin) &&
      isBeforeDay(currentDateBerlin, hoverDateBerlin)
    ) {
      return true;
    }

    if (
      !endDateBerlin &&
      startDateBerlin &&
      hoverDateBerlin &&
      isBeforeDay(currentDateBerlin, startDateBerlin) &&
      isAfterDay(currentDateBerlin, hoverDateBerlin)
    ) {
      return true;
    }

    return false;
  }, [currentDate, startDate, endDate, hoverDate]);

export const useIsStartOrEndDate = (
  currentDate: Date,
  startDate: Date | null,
  endDate: Date | null
) =>
  useMemo(() => {
    const currentDateBerlin = toZonedTime(currentDate, timeZone);
    const startDateBerlin = startDate && toZonedTime(startDate, timeZone);
    const endDateBerlin = endDate && toZonedTime(endDate, timeZone);
    return (
      (startDateBerlin && isSameDay(startDateBerlin, currentDateBerlin)) ||
      (endDateBerlin && isSameDay(endDateBerlin, currentDateBerlin)) ||
      false
    );
  }, [currentDate, startDate, endDate]);

export const useIsToday = (date: Date) => {
  const now = useNow();
  return useMemo(() => {
    const nowBerlin = toZonedTime(now, timeZone);
    const dateBerlin = toZonedTime(date, timeZone);
    return isSameDay(dateBerlin, nowBerlin);
  }, [date, now]);
};

export const useIsBefore = (
  date: Date | string | null | undefined,
  otherDate: Date | string | null | undefined
) =>
  useMemo(() => {
    if (!date || !otherDate) return false;
    return isBefore(
      typeof date === 'string' ? new Date(date) : date,
      typeof otherDate === 'string' ? new Date(otherDate) : otherDate
    );
  }, [date, otherDate]);

export const useIsAfter = (
  date: Date | string | null | undefined,
  otherDate: Date | string | null | undefined
) =>
  useMemo(() => {
    if (!date || !otherDate) return false;
    return isAfter(
      typeof date === 'string' ? new Date(date) : date,
      typeof otherDate === 'string' ? new Date(otherDate) : otherDate
    );
  }, [date, otherDate]);

export function useIsInitiallyBefore(name: string, minBookingDate?: Date) {
  const { getFieldMeta } = useFormikContext<unknown>();
  const { initialValue } = getFieldMeta<Date | null>(name);

  return useIsBefore(initialValue, minBookingDate);
}

export function useIsInitiallyAfter(name: string, maxBookingDate?: Date) {
  const { getFieldMeta } = useFormikContext<unknown>();
  const { initialValue } = getFieldMeta<Date | null>(name);

  return useIsAfter(initialValue, maxBookingDate);
}
