import styled from 'styled-components';

export type StackProps = {
  gap?: number | [row: number, column: number];
  flow?: 'row' | 'column' | 'dense';
  inline?: boolean;
  alignItems?:
    | 'center'
    | 'baseline'
    | 'start'
    | 'end'
    | 'stretch'
    | 'flex-start'
    | 'flex-end';
  alignContent?:
    | 'normal'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';
  justifyContent?:
    | 'normal'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'end'
    | 'start';
  justifyItems?: 'legacy' | 'center' | 'start' | 'end' | 'stretch';
  textAlign?:
    | 'center'
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'justify'
    | 'justify-all';
  autoColumns?: string;
  templateColumns?: string;
  autoRows?: string;
  templateRows?: string;
};

export const Stack = styled.div<StackProps>`
  display: ${({ inline = false }) => (inline ? 'inline-grid' : 'grid')};
  grid-auto-flow: ${({ flow = 'row' }) => flow};
  grid-gap: ${({ gap = 0 }) =>
    Array.isArray(gap) ? `${gap[0]}rem ${gap[1]}rem` : `${gap}rem`};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  align-content: ${({ alignContent = 'normal' }) => alignContent};
  justify-content: ${({ justifyContent = 'normal' }) => justifyContent};
  grid-auto-columns: ${({ autoColumns = 'auto' }) => autoColumns};
  grid-template-columns: ${({ templateColumns = 'none' }) => templateColumns};
  grid-auto-rows: ${({ autoRows = 'auto' }) => autoRows};
  grid-template-rows: ${({ templateRows = 'none' }) => templateRows};
  justify-items: ${({ justifyItems = 'legacy' }) => justifyItems};
  text-align: ${({ textAlign = 'left' }) => textAlign};
`;
