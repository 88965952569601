import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class Unauthorized extends Error {
  constructor() {
    super('Unauthorized');
    Object.setPrototypeOf(this, Unauthorized.prototype);
  }
}

export class Forbidden extends Error {
  response: AxiosResponse | undefined;

  constructor(response?: AxiosResponse) {
    super('Forbidden');
    if (response) {
      this.response = response;
    }
    Object.setPrototypeOf(this, Forbidden.prototype);
  }
}

export class NotFound extends Error {
  constructor() {
    super('NotFound');
    Object.setPrototypeOf(this, NotFound.prototype);
  }
}

export class Gone extends Error {
  constructor() {
    super('Gone');
    Object.setPrototypeOf(this, Gone.prototype);
  }
}

export class MissingMock extends Error {
  method: string;
  url: string;

  constructor({ method = 'get', url = '' }: AxiosRequestConfig) {
    super(`Unhandled route in Axios mock: ${method.toUpperCase()} ${url}`);
    this.name = 'MissingMock';
    this.method = method.toUpperCase();
    this.url = url;
    Object.setPrototypeOf(this, MissingMock.prototype);
  }
}

export class InvalidQueryParams extends Error {
  name: string;
  value: unknown;

  constructor({ name, value }: { name: string; value: unknown }) {
    super('InvalidQueryParams');
    this.name = name;
    this.value = value;
    Object.setPrototypeOf(this, InvalidQueryParams.prototype);
  }
}

export class BalancingGroupAllocationForbidden extends Error {
  value: string;

  constructor({ value }: { value: string }) {
    super('BalancingGroupAllocationForbidden');
    this.value = value;
    Object.setPrototypeOf(this, BalancingGroupAllocationForbidden.prototype);
  }
}
