import { FC } from 'react';
import { DocumentsParams } from 'src/apis/document-exchange/types';
import { useOperatorDocumentsOutbox } from 'src/apis/document-exchange/use-operator-documents-outbox';
import { Shipper } from 'src/apis/monolith/types';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import { isNetworkError } from 'src/hooks/use-axios';
import { useTitle } from 'src/hooks/use-title';
import { DocumentsFilterCard } from 'src/pages/shippers/documents/filter-card';
import { OperatorDocumentsTable } from 'src/pages/shippers/documents/table';
import { usePageParams } from 'src/pages/shippers/documents/use-page-params';
import { useMemoOne } from 'use-memo-one';

type Props = {
  operatorDocumentsOutboxLink: string;
  shipper: Shipper;
};

export const ToShipperPage: FC<Props> = ({
  operatorDocumentsOutboxLink,
  shipper,
}) => {
  useTitle('Document Overview');
  const pageParams = usePageParams();

  const params = useMemoOne<DocumentsParams>(() => {
    const { start, pageSize, ...rest } = pageParams.value;
    return {
      offset: start,
      limit: pageSize,
      shipperId: shipper.organizationId,
      ...rest,
    };
  }, [pageParams.value]);

  const documents = useOperatorDocumentsOutbox({
    url: operatorDocumentsOutboxLink,
    params,
  });

  if (!documents.response && !isNetworkError(documents.error)) {
    return <PageSpinner />;
  }

  return (
    <Stack gap={2.5}>
      <DocumentsFilterCard
        type="OUTBOX"
        pageParams={pageParams}
        documents={documents}
      />
      {documents.response && (
        <OperatorDocumentsTable
          type="OUTBOX"
          pageParams={pageParams}
          pending={documents.pending}
          documents={documents.response.data}
          showActions={false}
        />
      )}
    </Stack>
  );
};

export default ToShipperPage;
