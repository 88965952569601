import { format, setHours } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import { timeZone } from 'src/utils/date-time-format';

// TODO: remove me?
export function berlinZoneToLocal(isoString?: string | null) {
  if (isoString == null) return isoString;
  return format(toZonedTime(isoString, timeZone), `yyyy-MM-dd'T'HH:mm:ss'Z'`);
}

export function shiftBerlinZoneToTsoDate(isoString: string, tsoZone = 'UTC') {
  return fromZonedTime(toZonedTime(isoString, timeZone), tsoZone);
}

export function shiftBerlinZoneToTso(isoString: string, tsoZone = 'UTC') {
  return shiftBerlinZoneToTsoDate(isoString, tsoZone).toISOString();
}

export function shiftTsoZoneToBerlinDate(isoString: string, tsoZone = 'UTC') {
  return fromZonedTime(toZonedTime(isoString, tsoZone), timeZone);
}

export function shiftTsoZoneToBerlin(isoString: string, tsoZone = 'UTC') {
  return shiftTsoZoneToBerlinDate(isoString, tsoZone).toISOString();
}

export function setBerlinZoneHours(isoString: string, hours: number) {
  return fromZonedTime(
    setHours(toZonedTime(isoString, timeZone), hours),
    timeZone
  ).toISOString();
}
