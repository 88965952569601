import { FC, lazy, useEffect } from 'react';
import { MonthlyStatementsParams } from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { MonthlyStatementsRequest } from 'src/apis/capacity-management/use-monthly-statements';
import { usePageParams } from 'src/pages/reporting/monthly-statements/overview/schema';
import { useMemoOne } from 'use-memo-one';
const Page = lazy(() => import('./page'));

type Params = {
  capacityManagementRoot: CapacityManagementRootRequest;
  monthlyStatements: MonthlyStatementsRequest;
};

export const MonthlyStatementsOverview: FC<Params> = ({
  capacityManagementRoot,
  monthlyStatements,
}) => {
  const pageParams = usePageParams();

  const params = useMemoOne<MonthlyStatementsParams>(() => {
    const {
      start,
      pageSize,
      sortBy,
      servicePeriodStart,
      servicePeriodEnd,
      status,
      onlyWithInvoiceCreated,
      monthlyStatementId,
      monthlyStatementType,
      sortDirection,
    } = pageParams.value;
    return {
      offset: start,
      limit: pageSize,
      servicePeriodStart,
      servicePeriodEnd,
      status,
      onlyWithInvoiceCreated,
      sortBy,
      sortDirection,
      monthlyStatementId,
      monthlyStatementType,
    };
  }, [pageParams.value]);

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const rootData = capacityManagementRoot.response.data;
    const options = { rootData, params };

    monthlyStatements.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, params]);

  return (
    <Page
      pageParams={pageParams}
      monthlyStatements={monthlyStatements}
      canChangeInvoiceSettings={
        !!capacityManagementRoot.response?.data._links?.settingsInvoicing
      }
    />
  );
};
