import { FC } from 'react';
import { Route } from 'react-router-dom';
import { useRemitReportingEntrypoint } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import {
  RemitReportingLookupContextProvider,
  useRemitReportingLookups,
} from 'src/pages/reporting/secondary-tradings/external-trades/common';
import { ExternalTradeDetails } from 'src/pages/reporting/secondary-tradings/external-trades/details';
import { ExternalTradeEdit } from 'src/pages/reporting/secondary-tradings/external-trades/edit';
import { EnterExternalTrade } from 'src/pages/reporting/secondary-tradings/external-trades/enter';
import { Forbidden } from 'src/utils/errors';

export const ExternalTradesEntry: FC<{}> = () => {
  const remitReportingEntrypoint = useRemitReportingEntrypoint();
  const lookups = useRemitReportingLookups();

  if (!lookups || !remitReportingEntrypoint.loaded) return <PageSpinner />;

  if (
    !remitReportingEntrypoint.response?.data._links?.['find-external-trades']
  ) {
    throw new Forbidden();
  }

  return (
    <RemitReportingLookupContextProvider value={lookups}>
      <RootRoutes>
        <Route
          path="/reporting/secondary/external-trades/enter/*"
          element={<EnterExternalTrade />}
        />
        <Route
          path="/reporting/secondary/external-trades/:externalTradeId"
          element={<ExternalTradeDetails />}
        />
        <Route
          path="/reporting/secondary/external-trades/:externalTradeId/edit/*"
          element={<ExternalTradeEdit />}
        />
        <Route path="*" element={<ThrowNotFound />} />
      </RootRoutes>
    </RemitReportingLookupContextProvider>
  );
};
