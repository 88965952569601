import {
  CorrectionType,
  CorrectionTypeWithCommodityFee,
} from 'src/apis/capacity-management/types';
import { labelsToOptions } from 'src/utils/labels-to-options';

export const correctionTypeLabels: Record<CorrectionType, string> = {
  REGULATED_TARIFF: 'Regulated Tariff',
  SURCHARGE: 'Surcharge',
  REGULATED_TARIFF_REDUCTION: 'Regulated Tariff Reduction',
  SURCHARGE_REDUCTION: 'Surcharge Reduction',
};

export const correctionTypeLabelsWithCommodityFee: Record<
  CorrectionTypeWithCommodityFee,
  string
> = {
  ...correctionTypeLabels,
  COMMODITY_FEE: 'Commodity Fee',
  COMMODITY_FEE_REDUCTION: 'Commodity Fee Reduction',
};

export const correctionTypeOptions = labelsToOptions(correctionTypeLabels);

export const correctionTypeOptionsWithCommodityFee = labelsToOptions(
  correctionTypeLabelsWithCommodityFee
);
