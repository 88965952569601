import { FC, lazy } from 'react';
import { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useAssignmentSettings } from 'src/apis/assignment-service/use-assignment-settings';
import { useRequiredDocuments } from 'src/apis/assignment-service/use-required-documents';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('src/pages/settings/assignment-settings/page'));

type AssignmentServiceProps = {
  assignmentService: Successful<AssignmentServiceRequest>;
};

export const AssignmentSettingsPage: FC<AssignmentServiceProps> = ({
  assignmentService,
}) => {
  if (
    !assignmentService.response.data._links.assignmentSettings ||
    !assignmentService.response.data._links.requiredDocuments
  ) {
    throw new Forbidden();
  }

  const assignmentSettings = useAssignmentSettings(
    assignmentService.response.data._links.assignmentSettings.href
  );
  const requiredDocuments = useRequiredDocuments(
    assignmentService.response.data._links.requiredDocuments.href
  );

  if (!assignmentSettings.response || !requiredDocuments.response)
    return <PageSpinner />;

  return (
    <Page
      assignmentSettings={assignmentSettings}
      requiredDocuments={requiredDocuments}
    />
  );
};
