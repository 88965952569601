import { addMonths, setHours, startOfMonth } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { useMemo } from 'react';
import { monthlyStatementsValues } from 'src/apis/capacity-management/types';
import {
  formDescSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { useNow } from 'src/hooks/use-now';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { monthlyStatementStatusValues } from 'src/pages/reporting/monthly-statements/overview/labels';
import { monthlyStatementTypeValues } from 'src/pages/shippers/invoices/schema';
import { timeZone } from 'src/utils/date-time-format';
import { z } from 'zod';

const getMonthlyStatementsOverviewSchema = ({ start }: { start: string }) =>
  z
    .object({
      servicePeriodStart: z.string().nullable().default(start),
      servicePeriodEnd: z.string().nullable().default(null),
      status: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(monthlyStatementStatusValues)).default([])
      ),
      monthlyStatementType: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(monthlyStatementTypeValues)).default([])
      ),
      monthlyStatementId: z.string().nullable().default(null),
      onlyWithInvoiceCreated: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(false)
      ),
      sortBy: z.enum(monthlyStatementsValues).default('SERVICE_PERIOD_START'),
      sortDirection: formDescSortDirectionSchema,
    })
    .merge(getPaginationSchema());

export function usePageParams() {
  const now = useNow();

  const start = useMemo(() => {
    const zonedNow = toZonedTime(now, timeZone);
    const start = setHours(startOfMonth(addMonths(zonedNow, -1)), 6);
    return fromZonedTime(start, timeZone).toISOString();
  }, [now]);

  return usePageParamsFromSchema(
    getMonthlyStatementsOverviewSchema({ start }),
    {
      filters: {
        servicePeriodStart: { label: 'Service Period' },
        servicePeriodEnd: { label: 'Service Period' },
        status: { label: 'Status' },
        monthlyStatementType: { label: 'Monthly Statement Type' },
        monthlyStatementId: { label: 'Monthly Statement ID' },
        onlyWithInvoiceCreated: { label: 'Approved Monthly Statements' },
      },
    }
  );
}

export type MonthlyStatementOverviewPageParams = ReturnType<
  typeof usePageParams
>;
