export function labelsToValues<T extends string>(labels: Record<T, string>) {
  return Object.keys(labels) as UnionToTuple<T>;
}

// taken from https://github.com/type-challenges/type-challenges/issues/11473
type UnionToTuple<T> = [T] extends [never]
  ? []
  : ReturnType<
      <
        L extends (T extends T ? (_: Promise<T>) => T : never) extends (
          _: infer L
        ) => T
          ? Awaited<L>
          : never,
      >() => [...UnionToTuple<Exclude<T, L>>, L]
    >;
