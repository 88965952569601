import { FC, lazy } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  BalancingCodes,
  ProcedureType,
  SecondaryConfig,
  TraderList,
} from 'src/apis/monolith/types';
import { useBalancingCodes } from 'src/apis/monolith/use-balancing-codes';
import {
  TradeProposalRequest,
  useProposal,
} from 'src/apis/monolith/use-proposal';
import { useSecondaryConfig } from 'src/apis/monolith/use-secondary-config';
import { useTraderLists } from 'src/apis/monolith/use-trader-lists';
import {
  TsoSecondarySettings,
  useTsoSecondarySettings,
} from 'src/apis/monolith/use-tso-secondary-settings';
import { RemitReportingSubscriptionInfo } from 'src/apis/remit-reporting/types';
import { useRemitReportingEntrypoint } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { useRemitSecondaryReportingSubscriptionInfo } from 'src/apis/remit-reporting/use-remit-secondary-reporting-subscription-info';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useTitle } from 'src/hooks/use-title';
import { useRemitReportHistory } from 'src/pages/reporting/secondary-tradings/overview/remit-reports/use-remit-report-history-for-proposal';

const Page = lazy(() => import('./page'));

export const ReportingSecondaryTradeProposal: FC = () => {
  const { proposalId } = useParams<{ proposalId: string }>();
  const { pathname } = useLocation();
  useTitle(`Proposal ID ${proposalId}`);
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  const tradeProposal = useProposal({ proposalId });

  const reportingPath = '/reporting/secondary/proposals';
  const isReportingPath = pathname.startsWith(reportingPath);

  if (!tradeProposal.response) return <PageSpinner />;

  if (monolithUser?.isShipper) {
    return (
      <LoadShipperResources
        tradeProposal={tradeProposal}
        isReportingPath={isReportingPath}
      />
    );
  } else {
    return (
      <Page tradeProposal={tradeProposal} isReportingPath={isReportingPath} />
    );
  }
};

const LoadShipperResources: FC<{
  tradeProposal: Successful<TradeProposalRequest>;
  isReportingPath: boolean;
}> = ({ tradeProposal, isReportingPath }) => {
  const proposal = tradeProposal.response.data;

  const secondaryConfig = useSecondaryConfig({
    networkPointId: proposal.networkPoint.id,
  });
  const balancingCodes = useBalancingCodes({ proposal });
  const traderLists = useTraderLists();
  const remitReportingEntrypoint = useRemitReportingEntrypoint();
  const remitSecondaryReportingSubscriptionInfo =
    useRemitSecondaryReportingSubscriptionInfo();

  const tso0SecondarySettings = useTsoSecondarySettings({
    tsoId: proposal.networkPoint.tso0Id,
  });
  const tso1SecondarySettings = useTsoSecondarySettings({
    tsoId: proposal.networkPoint.tso1Id,
  });

  if (
    !secondaryConfig.response ||
    !remitReportingEntrypoint.loaded ||
    !remitSecondaryReportingSubscriptionInfo.loaded ||
    !balancingCodes.response ||
    !traderLists.response ||
    (tso0SecondarySettings && !tso0SecondarySettings.loaded) ||
    (tso1SecondarySettings && !tso1SecondarySettings.loaded)
  )
    return <PageSpinner />;

  return remitReportable(proposal.tradingProcedure) &&
    remitReportingEntrypoint.response?.data._links?.[
      'secondary-report-history-for-proposal'
    ] ? (
    <LoadRemitHistory
      tradeProposal={tradeProposal}
      isReportingPath={isReportingPath}
      secondaryConfig={secondaryConfig.response.data}
      balancingCodes={balancingCodes.response.data}
      traderLists={traderLists.response.data}
      remitSecondaryReportingSubscriptionInfo={
        remitSecondaryReportingSubscriptionInfo.response?.data
      }
      tso0SecondarySettings={tso0SecondarySettings?.response?.data}
      tso1SecondarySettings={tso1SecondarySettings?.response?.data}
    />
  ) : (
    <Page
      tradeProposal={tradeProposal}
      isReportingPath={isReportingPath}
      secondaryConfig={secondaryConfig.response.data}
      balancingCodes={balancingCodes.response.data}
      traderLists={traderLists.response.data}
      remitSecondaryReportingSubscriptionInfo={
        remitSecondaryReportingSubscriptionInfo.response?.data
      }
      tso0SecondarySettings={tso0SecondarySettings?.response?.data}
      tso1SecondarySettings={tso1SecondarySettings?.response?.data}
    />
  );
};

const LoadRemitHistory: FC<{
  secondaryConfig?: SecondaryConfig;
  tradeProposal: Successful<TradeProposalRequest>;
  balancingCodes?: BalancingCodes;
  traderLists?: TraderList[];
  remitSecondaryReportingSubscriptionInfo?: RemitReportingSubscriptionInfo;
  tso0SecondarySettings?: TsoSecondarySettings;
  tso1SecondarySettings?: TsoSecondarySettings;
  isReportingPath: boolean;
}> = ({
  secondaryConfig,
  tradeProposal,
  balancingCodes,
  traderLists,
  remitSecondaryReportingSubscriptionInfo,
  tso0SecondarySettings,
  tso1SecondarySettings,
  isReportingPath,
}) => {
  const proposal = tradeProposal.response.data;
  const remitHistory = useRemitReportHistory(proposal.id);

  if (!remitHistory.loaded) return <PageSpinner />;

  return (
    <Page
      tradeProposal={tradeProposal}
      isReportingPath={isReportingPath}
      secondaryConfig={secondaryConfig}
      balancingCodes={balancingCodes}
      traderLists={traderLists}
      remitSecondaryReportingSubscriptionInfo={
        remitSecondaryReportingSubscriptionInfo
      }
      remitHistory={remitHistory?.response?.data}
      tso0SecondarySettings={tso0SecondarySettings}
      tso1SecondarySettings={tso1SecondarySettings}
    />
  );
};

function remitReportable(procedure: ProcedureType) {
  // so far CFO only can have remit reports
  return procedure === 'CFO';
}
