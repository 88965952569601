import { isAfter, isBefore, startOfDay, parseISO, addMinutes } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import { dateFormat, timeFormat, timeZone } from 'src/utils/date-time-format';

export type InputTime =
  | boolean
  | 'full-hour'
  | 'gas-day'
  | 'day-end' // 23:59
  | 'gas-month';

// dd.mm.yyyy
export const dateRegExp =
  /^\s*([0-9][0-9]).([0-9][0-9]).([0-9][0-9][0-9][0-9])\s*$/;

// dd.mm.yyyy for finding it anywhere in string
const dateRegExpAnywhere =
  /\s*([0-9][0-9]).([0-9][0-9]).([0-9][0-9][0-9][0-9])\s*/;

// dd.mm.yyyy | hh:mm
export const dateTimeRegExp =
  /^\s*([0-9][0-9]).([0-9][0-9]).([0-9][0-9][0-9][0-9])\s+\|\s+([0-9][0-9]):([0-9][0-9])\s*$/;

// all our dates in the FRONTEND are using CET/CEST, but sadly we can't _create_
// a new date for a specific timezone in vanilla JS (we can only render it to a
// certain timezone by using `toLocaleString`) - that's why we need `fromZonedTime`,
// because on the BACKEND we're using UTC
export function toISOString(value: string | null) {
  if (value) {
    const dateTimeMatches = value.match(dateTimeRegExp);
    const dateMatches = value.match(dateRegExp);

    if (dateTimeMatches) {
      const date = parseDateTimeMatches(dateTimeMatches);
      return fromZonedTime(date, timeZone).toISOString();
    } else if (dateMatches) {
      const date = parseDateMatches(dateMatches);
      return fromZonedTime(date, timeZone).toISOString();
    } else {
      return null;
    }
  } else {
    return value;
  }
}

// tries to extract a dd.mm.yyyy date from the string, and if found, returns exactly that or null
export function extractDateFromString(input: string): string | null {
  if (input) {
    const match = input.match(dateRegExpAnywhere);
    if (match) {
      const [fullMatch] = match;
      return fullMatch;
    }
    return null;
  }
  return null;
}

// Transform the given date string to needed input values to match the given input mask.
// See regex above for the format.
export function transformValueToInputMask(
  value: string | null,
  time?: InputTime
) {
  if (!value) {
    return '';
  }

  if (!time) {
    return new Date(value).toLocaleString('de-DE', dateFormat);
  }

  const date = new Date(value);

  const dateString = date.toLocaleString('de-DE', dateFormat);
  const timeString = date.toLocaleString('de-DE', timeFormat);
  return `${dateString} | ${timeString}`;
}

export type Hour =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23';

type AttachTimeParams = {
  originalDate: Date;
  hours: string;
  minutes: string;
  time: Exclude<InputTime, false>;
  defaultHour?: Hour;
  /**
   * If this function is called by clicking on a day in the month view,
   * we round up to the next minute. This is interesting, because we often
   * validate that a date is not in the past and we'd like to avoid showing
   * the customer an error right away. With this small rounding they can select
   * a day and adjust the time without seeing an error.
   */
  roundToNextMinute: boolean;
};

// Attatch time to given date or fallback to current time if no custom time is set
export const attachTime = ({
  originalDate,
  hours,
  minutes,
  time,
  defaultHour,
  roundToNextMinute,
}: AttachTimeParams) => {
  // Clone date to be immutable and trigger effects correctly.
  const date = toZonedTime(originalDate.toISOString(), timeZone);

  switch (time) {
    case 'gas-day':
    case 'gas-month':
      date.setHours(6);
      date.setMinutes(0);
      break;
    case 'full-hour':
      date.setHours(
        hours === ''
          ? defaultHour == null
            ? getCurrentHourInPrismaTimeZone()
            : Number(defaultHour)
          : Number(hours)
      );
      date.setMinutes(0);
      break;
    case 'day-end':
      date.setHours(23);
      date.setMinutes(59);
      break;
    case true:
      date.setHours(
        hours === ''
          ? defaultHour == null
            ? getCurrentHourInPrismaTimeZone()
            : Number(defaultHour)
          : Number(hours)
      );
      date.setMinutes(
        minutes === ''
          ? getCurrentMinutesInPrismaTimeZone(roundToNextMinute)
          : Number(minutes)
      );
  }

  // this is now the correct time in Europe/Berlin, but needs to be converted to local tz so that UTC values of saved date match the real utc values
  const dateInLocalTz = toZonedTime(
    fromZonedTime(date, timeZone),
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  return dateInLocalTz;
};

function getCurrentHourInPrismaTimeZone() {
  return toZonedTime(new Date().toISOString(), timeZone).getHours();
}

function getCurrentMinutesInPrismaTimeZone(roundToNextMinute: boolean) {
  const date = toZonedTime(new Date().toISOString(), timeZone);
  if (roundToNextMinute) return addMinutes(date, 1).getMinutes();
  return date.getMinutes();
}

export function parseDateMatches(matches: string[]) {
  const [_, day, month, year] = matches;
  return parseISO(`${year}-${month}-${day}`);
}

export function parseDateTimeMatches(values: string[]) {
  const [_, day, month, year, hour, minute] = values;
  return parseISO(`${year}-${month}-${day} ${hour}:${minute}`);
}

export function getPlaceholder(time: InputTime) {
  if (time === true) return dateTimeMaskPlaceholder;
  if (time === 'full-hour') return dateTimeFullHourMaskPlaceholder;
  if (time === 'gas-day') return dateTimeGasDayMaskPlaceholder;
  if (time === 'gas-month') return dateTimeGasMonthMaskPlaceholder;
  if (time === 'day-end') return dateTimeDayEndMaskPlaceholder;
  return dateMaskPlaceholder;
}

export function getMask(time: InputTime) {
  if (time === true) return dateTimeInputMask;
  if (time === 'full-hour') return dateTimeFullHourInputMask;
  if (time === 'gas-day') return dateTimeGasDayInputMask;
  if (time === 'gas-month') return dateTimeGasMonthInputMask;
  if (time === 'day-end') return dateTimeDayEndInputMask;
  return dateInputMask;
}

export function isDateProvided(
  time: InputTime,
  value: string | null
): value is string {
  if (value === null) return false;
  if (value === '') return false;
  if (value === getPlaceholder(time)) return false;
  return true;
}

export function validateDateInput(time: InputTime, value: string | null) {
  const providedDate = isDateProvided(time, value);
  if (!providedDate) return;

  const matches = value.match(time ? dateTimeRegExp : dateRegExp);
  if (!matches) return 'Please enter a valid date for {label}.';

  const date = time ? parseDateTimeMatches(matches) : parseDateMatches(matches);

  // avoid "date-fns-tz" bug
  // see https://github.com/marnusw/date-fns-tz/issues/87
  if (date.getFullYear() < 100) return 'Please enter a valid date for {label}.';
  if (isNaN(date.valueOf())) return 'Please enter a valid date for {label}.';
}

export function isFullHourDate(value: string | null): boolean {
  if (!value) return false;

  const date = parseISO(value);
  return (
    !isNaN(date.valueOf()) &&
    date.getMinutes() === 0 &&
    date.getSeconds() === 0 &&
    date.getMilliseconds() === 0
  );
}

export function isStartOfGasMonthDate(value: string | null): boolean {
  if (!value) return false;

  const date = parseISO(value);
  const valueInBerlin = toZonedTime(
    fromZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone),
    timeZone
  );

  return (
    !isNaN(date.valueOf()) &&
    date.getMinutes() === 0 &&
    date.getSeconds() === 0 &&
    date.getMilliseconds() === 0 &&
    valueInBerlin.getHours() === 6 &&
    valueInBerlin.getDate() === 1
  );
}

export function checkBlockedDate({
  midnightInBerlin,
  time,
  minBookingDate,
  maxBookingDate,
}: {
  midnightInBerlin: Date;
  time: InputTime;
  minBookingDate?: Date;
  maxBookingDate?: Date;
}) {
  // date was already normalized before its passed to `useDay`,
  // so we know it's midnightInBerlin (but as UTC, so either 22:00 or 23:00).
  // however we might not need midnight but the start of the gas day.
  // so we need to convert it back to CET/CEST, set the hour and convert back to UTC.
  const berlinDate = toZonedTime(midnightInBerlin, timeZone);
  if (time === 'gas-month' || time === 'gas-day') berlinDate.setHours(6);
  const currentDate = fromZonedTime(berlinDate, timeZone);

  // start checking, if date should be blocked
  const blockedByGasMonth = time === 'gas-month' && berlinDate.getDate() !== 1;

  // note: we use `startOfDay` here, because if `minBookingDate` is 2021-01-01T01:00:00Z,
  // we still want the 2021-01-01 to be selectable (e.g. there are still 23 hours valid hours left)
  const blockedByMinDate =
    !!minBookingDate && isBefore(currentDate, startOfDay(minBookingDate));

  const blockedByMaxDate =
    !!maxBookingDate && isAfter(currentDate, maxBookingDate);

  return blockedByGasMonth || blockedByMinDate || blockedByMaxDate;
}

const dateInputMask = '99.99.9999';
const dateMaskPlaceholder = 'DD.MM.YYYY';

const dateTimeInputMask = '99.99.9999 | 99:99';
const dateTimeMaskPlaceholder = 'DD.MM.YYYY | HH:mm';

const dateTimeFullHourInputMask = '99.99.9999 | 99:00';
const dateTimeFullHourMaskPlaceholder = 'DD.MM.YYYY | HH:00';

const dateTimeGasDayInputMask = '99.99.9999 | 06:00';
const dateTimeGasDayMaskPlaceholder = 'DD.MM.YYYY | 06:00';

const dateTimeDayEndInputMask = '99.99.9999 | 23:5\\9'; // 9 needs to be escaped with double backslash
const dateTimeDayEndMaskPlaceholder = 'DD.MM.YYYY | 23:59 '; // the space is needed, because of a bug in the input mask (the double backslash is counted as a character)

const dateTimeGasMonthInputMask = '01.99.9999 | 06:00';
const dateTimeGasMonthMaskPlaceholder = '01.MM.YYYY | 06:00';
