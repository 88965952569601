import {
  faMinusCircle,
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAfter, isBefore } from 'date-fns';
import { FieldArray, useFormikContext } from 'formik';
import { FC, Fragment, ReactElement, useEffect } from 'react';
import {
  AuctionConfig,
  AuctionDetail,
  AuctionOrderStep,
  CapacityUpgrade,
  ComfortBidConfig,
  PotentiallyBundledConversion,
  Price,
  Quantity,
  WriteCapacityUpgrade,
  WritePotentiallyBundledConversion,
} from 'src/apis/monolith/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { DataItems } from 'src/components/data-display/data-list';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { FormatDate } from 'src/components/data-display/format-date';
import {
  exactFormat,
  FormatValue,
} from 'src/components/data-display/format-value';
import { CardDivider } from 'src/components/dividers';
import { Alert } from 'src/components/feedback/alert';
import { DateRangeInput } from 'src/components/form/datepicker';
import { formGap } from 'src/components/form/form-container';
import { Input } from 'src/components/form/input';
import { Radios } from 'src/components/form/radios';
import { Select } from 'src/components/form/select';
import { requiredOutputWhen } from 'src/components/form/zod-utilities';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useOnChange } from 'src/hooks/use-on-change';
import { Referrer } from 'src/hooks/use-referrer';
import { capacityLabels } from 'src/pages/reporting/capacity';
import {
  AcceptGtc,
  AcceptSpecificCondition,
} from 'src/pages/reporting/secondary-tradings/trade-proposal/accept-condition';
import { FakeAuctionForComfortBid } from 'src/pages/transport/auctions/comfort-bids/create/utils';
import { AuctionDetailsPageParams } from 'src/pages/transport/auctions/details/use-page-params';
import { physicalUnitLabels } from 'src/pages/transport/secondary-tradings/trade/network-point-card';
import { StrictOmit } from 'src/utils/utility-types';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';

const interruptibleCategories = [
  'INTERRUPTIBLE',
  'INTERRUPTIBLE_LEVEL_1',
  'INTERRUPTIBLE_LEVEL_2',
  'INTERRUPTIBLE_LEVEL_N',
  'BACKHAUL_CAPACITY',
  'BACKHAUL_LEVEL_1',
  'BACKHAUL_LEVEL_2',
  'BACKHAUL_LEVEL_N',
  'U_BZK',
  'U_KC',
];

type FieldsMetaOptions = {
  values: {
    upgradeSettings: 'NO_BID' | 'BID_TSO_0' | 'BID_TSO_1' | 'BID_BOTH';
    conversionSettings: 'NO_BID' | 'BID_TSO_0' | 'BID_TSO_1';
  };
  auction: AuctionDetail | FakeAuctionForComfortBid;
  auctionConfig: AuctionConfig | ComfortBidConfig;
  deferTermsAndConditions: boolean;
};

export function getShortTermFieldsMeta({
  values,
  auction,
  auctionConfig,
  deferTermsAndConditions,
}: FieldsMetaOptions) {
  const isCapacityInterruptible = auction.capacityCategory1
    ? interruptibleCategories.includes(auction.capacityCategory0) &&
      interruptibleCategories.includes(auction.capacityCategory1)
    : interruptibleCategories.includes(auction.capacityCategory0);

  const isUpgradePossible =
    (auctionConfig.upgradeSupported0 || auctionConfig.upgradeSupported1) &&
    !isCapacityInterruptible;

  const isUpgradePossibleOnBothSides =
    auctionConfig.upgradeSupported0 && auctionConfig.upgradeSupported1;

  const isConversionPossible =
    (auctionConfig.conversionSupported0 ||
      auctionConfig.conversionSupported1) &&
    auction.networkPoint.bundled;

  return {
    isUpgradePossibleOnBothSides,
    priceCurrency: {
      visible: auction.possibleUnits.length > 1,
    },
    undiscounted: {
      visible:
        auction.networkPoint.undiscountedTariffs &&
        auction.networkPoint.connectionPointType === 'RESERVOIR',
    },
    balancingGroup0: {
      visible:
        auctionConfig.balancingGroupConfig0 === 'MANDATORY' ||
        auctionConfig.balancingGroupConfig0 === 'OPTIONAL',
      required: auctionConfig.balancingGroupConfig0 === 'MANDATORY',
    },
    portfolioCode0: {
      visible:
        auctionConfig.portfolioCodeConfig0 === 'MANDATORY' ||
        auctionConfig.portfolioCodeConfig0 === 'OPTIONAL',
      required: auctionConfig.portfolioCodeConfig0 === 'MANDATORY',
    },
    balancingGroup1: {
      visible:
        auctionConfig.balancingGroupConfig1 === 'MANDATORY' ||
        auctionConfig.balancingGroupConfig1 === 'OPTIONAL',
      required: auctionConfig.balancingGroupConfig1 === 'MANDATORY',
    },
    portfolioCode1: {
      visible:
        auctionConfig.portfolioCodeConfig1 === 'MANDATORY' ||
        auctionConfig.portfolioCodeConfig1 === 'OPTIONAL',
      required: auctionConfig.portfolioCodeConfig1 === 'MANDATORY',
    },
    upgradeSettings: {
      visible: isUpgradePossible,
    },
    upgradeMaxCapacity0: {
      visible:
        values.upgradeSettings === 'BID_TSO_0' ||
        values.upgradeSettings === 'BID_BOTH',
    },
    upgradeDealId0: {
      visible:
        values.upgradeSettings === 'BID_TSO_0' ||
        values.upgradeSettings === 'BID_BOTH',
    },
    upgradeContractId0: {
      visible:
        (values.upgradeSettings === 'BID_TSO_0' ||
          values.upgradeSettings === 'BID_BOTH') &&
        (auctionConfig.upgradeContractIdConfig0 === 'MANDATORY' ||
          auctionConfig.upgradeContractIdConfig0 === 'OPTIONAL'),
      required: auctionConfig.upgradeContractIdConfig0 === 'MANDATORY',
    },
    upgradeFormerBalancing0: {
      visible:
        (values.upgradeSettings === 'BID_TSO_0' ||
          values.upgradeSettings === 'BID_BOTH') &&
        (auctionConfig.conversionAndUpgradeBalancingGroupConfig0 ===
          'MANDATORY' ||
          auctionConfig.conversionAndUpgradeBalancingGroupConfig0 ===
            'OPTIONAL'),
      required:
        auctionConfig.conversionAndUpgradeBalancingGroupConfig0 === 'MANDATORY',
    },
    upgradeMaxCapacity1: {
      visible: values.upgradeSettings === 'BID_TSO_1',
    },
    upgradeDealId1: {
      visible: values.upgradeSettings === 'BID_TSO_1',
    },
    upgradeContractId1: {
      visible:
        (values.upgradeSettings === 'BID_TSO_1' ||
          values.upgradeSettings === 'BID_BOTH') &&
        (auctionConfig.upgradeContractIdConfig1 === 'MANDATORY' ||
          auctionConfig.upgradeContractIdConfig1 === 'OPTIONAL'),
      required: auctionConfig.upgradeContractIdConfig1 === 'MANDATORY',
    },
    upgradeFormerBalancing1: {
      visible:
        (values.upgradeSettings === 'BID_TSO_1' ||
          values.upgradeSettings === 'BID_BOTH') &&
        (auctionConfig.conversionAndUpgradeBalancingGroupConfig1 ===
          'MANDATORY' ||
          auctionConfig.conversionAndUpgradeBalancingGroupConfig1 ===
            'OPTIONAL'),
      required:
        auctionConfig.conversionAndUpgradeBalancingGroupConfig1 === 'MANDATORY',
    },
    conversionSettings: {
      visible: isConversionPossible,
    },
    conversionContractId0: {
      visible:
        values.conversionSettings === 'BID_TSO_0' &&
        (auctionConfig.conversionContractIdConfig0 === 'MANDATORY' ||
          auctionConfig.conversionContractIdConfig0 === 'OPTIONAL'),
      required: auctionConfig.conversionContractIdConfig0 === 'MANDATORY',
    },
    conversionFormerBalancing0: {
      visible:
        values.conversionSettings === 'BID_TSO_0' &&
        (auctionConfig.conversionAndUpgradeBalancingGroupConfig0 ===
          'MANDATORY' ||
          auctionConfig.conversionAndUpgradeBalancingGroupConfig0 ===
            'OPTIONAL'),
      required:
        auctionConfig.conversionAndUpgradeBalancingGroupConfig0 === 'MANDATORY',
    },
    conversionRuntimes0: {
      visible:
        values.conversionSettings === 'BID_TSO_0' &&
        Boolean(auctionConfig.runtimeForConversionSupported0),
    },
    conversionContractId1: {
      visible:
        values.conversionSettings === 'BID_TSO_1' &&
        (auctionConfig.conversionContractIdConfig1 === 'MANDATORY' ||
          auctionConfig.conversionContractIdConfig1 === 'OPTIONAL'),
      required: auctionConfig.conversionContractIdConfig1 === 'MANDATORY',
    },
    conversionFormerBalancing1: {
      visible:
        values.conversionSettings === 'BID_TSO_1' &&
        (auctionConfig.conversionAndUpgradeBalancingGroupConfig1 ===
          'MANDATORY' ||
          auctionConfig.conversionAndUpgradeBalancingGroupConfig1 ===
            'OPTIONAL'),
      required:
        auctionConfig.conversionAndUpgradeBalancingGroupConfig1 === 'MANDATORY',
    },
    conversionRuntimes1: {
      visible:
        values.conversionSettings === 'BID_TSO_1' &&
        Boolean(auctionConfig.runtimeForConversionSupported1),
    },
    acceptGtcTso0: {
      visible: !deferTermsAndConditions,
    },
    acceptGtcTso1: {
      visible: !deferTermsAndConditions && auction.networkPoint.bundled,
    },
    specificConditions0: {
      visible:
        !deferTermsAndConditions && auctionConfig.specificConditionsEnabled0,
    },
    specificConditions1: {
      visible:
        !deferTermsAndConditions && auctionConfig.specificConditionsEnabled1,
    },
  };
}

export type FieldsMeta = ReturnType<typeof getShortTermFieldsMeta>;

export const SharedReviewFields: FC<{
  auction: AuctionDetail | FakeAuctionForComfortBid;
  auctionConfig: AuctionConfig | ComfortBidConfig;
  meta: FieldsMeta;
  values: ValidSharedBiddingFormValues;
  incrementalAuction?: ReactElement | false;
  deferTermsAndConditions: boolean;
}> = ({
  auction,
  auctionConfig,
  meta,
  values,
  incrementalAuction,
  deferTermsAndConditions,
}) => {
  return (
    <>
      {(meta.balancingGroup0.visible || meta.portfolioCode0.visible) && (
        <>
          <Spacer />
          {auction.networkPoint.bundled && (
            <p>
              <strong>{auction.networkPoint.tso0Name}</strong>
            </p>
          )}
        </>
      )}

      <DataItems>
        {meta.balancingGroup0.visible && (
          <>
            <span>Desired Balancing Group</span>
            <span>{values.balancingGroup0 || <EmptyValue label="None" />}</span>
          </>
        )}

        {meta.portfolioCode0.visible && (
          <>
            <span>Desired Portfolio Code</span>
            <span>{values.portfolioCode0 || <EmptyValue label="None" />}</span>
          </>
        )}
      </DataItems>

      {(meta.balancingGroup1.visible || meta.portfolioCode1.visible) && (
        <>
          <Spacer />
          <p>
            <strong>{auction.networkPoint.tso1Name}</strong>
          </p>
        </>
      )}

      <DataItems>
        {meta.balancingGroup1.visible && (
          <>
            <span>Desired Balancing Group</span>
            <span>{values.balancingGroup1 || <EmptyValue label="None" />}</span>
          </>
        )}

        {meta.portfolioCode1.visible && (
          <>
            <span>Desired Portfolio Code</span>
            <span>{values.portfolioCode1 || <EmptyValue label="None" />}</span>
          </>
        )}
      </DataItems>

      {(values.upgradeSettings === 'BID_TSO_0' ||
        values.upgradeSettings === 'BID_BOTH') && (
        <>
          <Spacer />
          <p>
            <strong>Upgrade Bid for {auction.networkPoint.tso0Name}</strong>
          </p>

          <DataItems>
            <span>Maximal Capacity to be Upgraded</span>
            <span>
              <FormatValue
                value={values.upgradeMaxCapacity0!}
                label={physicalUnitLabels[auction.networkPoint.physicalUnit]}
              />
            </span>

            <span>Deal ID</span>
            <span>{values.upgradeDealId0 || <EmptyValue label="None" />}</span>

            {meta.upgradeContractId0.visible && (
              <>
                <span>Contract ID</span>
                <span>
                  {values.upgradeContractId0 || <EmptyValue label="None" />}
                </span>
              </>
            )}

            {meta.upgradeFormerBalancing0.visible && (
              <>
                <span>
                  Former Balancing Group/Sub-Balancing <br />
                  Account
                </span>
                <span>
                  {values.upgradeFormerBalancing0 || (
                    <EmptyValue label="None" />
                  )}
                </span>
              </>
            )}
          </DataItems>
        </>
      )}

      {(values.upgradeSettings === 'BID_TSO_1' ||
        values.upgradeSettings === 'BID_BOTH') && (
        <>
          <Spacer />
          <p>
            <strong>Upgrade Bid for {auction.networkPoint.tso1Name}</strong>
          </p>

          <DataItems>
            <span>Maximal Capacity to be Upgraded</span>
            <span>
              <FormatValue
                value={values.upgradeMaxCapacity1!}
                label={physicalUnitLabels[auction.networkPoint.physicalUnit]}
              />
            </span>

            <span>Deal ID</span>
            <span>{values.upgradeDealId1 || <EmptyValue label="None" />}</span>

            {meta.upgradeContractId1.visible && (
              <>
                <span>Contract ID</span>
                <span>
                  {values.upgradeContractId1 || <EmptyValue label="None" />}
                </span>
              </>
            )}

            {meta.upgradeFormerBalancing1.visible && (
              <>
                <span>
                  Former Balancing Group/Sub-Balancing <br />
                  Account
                </span>
                <span>
                  {values.upgradeFormerBalancing1 || (
                    <EmptyValue label="None" />
                  )}
                </span>
              </>
            )}
          </DataItems>
        </>
      )}

      {values.conversionSettings === 'BID_TSO_0' &&
        values.conversions0.map((conversion, index) => (
          <Fragment key={index}>
            <Spacer />
            <p>
              <strong>
                Conversion Bid{' '}
                {auctionConfig.multipleConversionsPerBid0
                  ? `#${index + 1} `
                  : ''}
                for {auction.networkPoint.tso0Name}
              </strong>
            </p>

            <DataItems>
              {meta.conversionContractId0.visible && (
                <>
                  <span>Contract ID</span>
                  <span>
                    {conversion.contractId || <EmptyValue label="None" />}
                  </span>
                </>
              )}

              {meta.conversionFormerBalancing0.visible && (
                <>
                  <span>
                    Former Balancing Group/Sub-Balancing <br />
                    Account
                  </span>
                  <span>
                    {conversion.formerBalancing || <EmptyValue label="None" />}
                  </span>
                </>
              )}

              {meta.conversionRuntimes0.visible ? (
                conversion.intervals.map((interval) => (
                  <Fragment key={interval.id}>
                    <span>Max. Capacity, Runtime</span>
                    <span>
                      <FormatValue
                        value={interval.quantity!}
                        label={
                          physicalUnitLabels[auction.networkPoint.physicalUnit]
                        }
                      />
                      , <FormatDate value={interval.runtimeStart!} /> -{' '}
                      <FormatDate value={interval.runtimeEnd!} />
                    </span>
                  </Fragment>
                ))
              ) : (
                <Fragment>
                  <span>Max. Capacity</span>
                  <span>
                    <FormatValue
                      value={conversion.intervals[0].quantity!}
                      label={
                        physicalUnitLabels[auction.networkPoint.physicalUnit]
                      }
                    />
                  </span>
                </Fragment>
              )}
            </DataItems>
          </Fragment>
        ))}

      {values.conversionSettings === 'BID_TSO_1' &&
        values.conversions1.map((conversion, index) => (
          <Fragment key={index}>
            <Spacer />
            <p>
              <strong>
                Conversion Bid{' '}
                {auctionConfig.multipleConversionsPerBid1
                  ? `#${index + 1} `
                  : ''}
                for {auction.networkPoint.tso1Name}
              </strong>
            </p>

            <DataItems>
              {meta.conversionContractId1.visible && (
                <>
                  <span>Contract ID</span>
                  <span>
                    {conversion.contractId || <EmptyValue label="None" />}
                  </span>
                </>
              )}

              {meta.conversionFormerBalancing1.visible && (
                <>
                  <span>
                    Former Balancing Group/Sub-Balancing <br />
                    Account
                  </span>
                  <span>
                    {conversion.formerBalancing || <EmptyValue label="None" />}
                  </span>
                </>
              )}

              {meta.conversionRuntimes1.visible ? (
                conversion.intervals.map((interval) => (
                  <Fragment key={interval.id}>
                    <span>Max. Capacity, Runtime</span>
                    <span>
                      <FormatValue
                        value={interval.quantity!}
                        label={
                          physicalUnitLabels[auction.networkPoint.physicalUnit]
                        }
                      />
                      , <FormatDate value={interval.runtimeStart!} /> -{' '}
                      <FormatDate value={interval.runtimeEnd!} />
                    </span>
                  </Fragment>
                ))
              ) : (
                <Fragment>
                  <span>Max. Capacity</span>
                  <span>
                    <FormatValue
                      value={conversion.intervals[0].quantity!}
                      label={
                        physicalUnitLabels[auction.networkPoint.physicalUnit]
                      }
                    />
                  </span>
                </Fragment>
              )}
            </DataItems>
          </Fragment>
        ))}

      {!deferTermsAndConditions && (
        <div>
          <Spacer />
          <p>
            <strong>GTCs</strong>
          </p>
          <p>I accepted the GTCs of {auction.networkPoint.tso0Name}.</p>

          {meta.acceptGtcTso1.visible && (
            <p>I accepted the GTCs of {auction.networkPoint.tso1Name}.</p>
          )}

          {meta.specificConditions0.visible && (
            <p>
              I accepted the {auctionConfig.specificConditionsLabel0} of{' '}
              {auction.networkPoint.tso0Name}.
            </p>
          )}

          {meta.specificConditions1.visible && (
            <p>
              I accepted the {auctionConfig.specificConditionsLabel1} of{' '}
              {auction.networkPoint.tso1Name}.
            </p>
          )}
        </div>
      )}

      {incrementalAuction}
    </>
  );
};

export const IncrementalAuctionReview: FC<{
  auction: AuctionDetail;
  referrer: Referrer;
  pageParams: AuctionDetailsPageParams;
}> = ({ auction, referrer, pageParams }) => {
  return (
    <div>
      <Spacer />

      <Alert type="info" title="Incremental Auction Scenario">
        <Stack gap={1}>
          <p>
            This auction is run as part of an incremental capacity process, as
            defined according to Articles 22 to 31 of Commission Regulation (EU)
            2017/459. This means that alternative auctions are simultaneously
            available for different offer levels for the same runtime:
          </p>

          {auction.auctionsInIncrementalAuctionClosure?.length ? (
            <ul>
              {auction.auctionsInIncrementalAuctionClosure.map(
                (auctionInfo) => (
                  <li key={auctionInfo.auctionId}>
                    <Link
                      mode="default-underlined"
                      {...referrer.getLinkProps({
                        pathname: `/transport/auctions/${auctionInfo.auctionId}`,
                        search: pageParams.value.identifier
                          ? `identifier=${pageParams.value.identifier}`
                          : undefined,
                      })}
                      target="_blank"
                    >
                      <strong>
                        Auction ID {auctionInfo.auctionId}:{' '}
                        {auctionInfo.capacityCategory0 &&
                          capacityLabels[auctionInfo.capacityCategory0]}
                        {auctionInfo.capacityCategory0 &&
                        auctionInfo.capacityCategory1
                          ? ' / '
                          : null}
                        {auctionInfo.capacityCategory1 &&
                          capacityLabels[auctionInfo.capacityCategory1]}
                      </strong>
                    </Link>
                  </li>
                )
              )}
            </ul>
          ) : null}

          <p>
            After all the auctions for the different offer levels at this
            network point are closed, the involved TSO(s) and/ or NRA(s) will
            conduct an economic test. Depending on the economic test outcome,
            only one offer level will be successful. In the case where a certain
            shipper did not place a successful bid for the successful offer
            level auction, no capacity is allocated to the shipper.
          </p>

          <p>
            For details concerning the incremental capacity scenario and the
            economic test, please contact the involved TSO(s).
          </p>
        </Stack>
      </Alert>
    </div>
  );
};

export const SharedEditFields: FC<{
  auction: AuctionDetail | FakeAuctionForComfortBid;
  auctionConfig: AuctionConfig | ComfortBidConfig;
  meta: FieldsMeta;
  withinDayRollover?: ReactElement | string;
  values: SharedBiddingFormValues;
  fieldPrefix: string;
}> = ({
  auction,
  auctionConfig,
  meta,
  withinDayRollover,
  values,
  fieldPrefix,
}) => {
  const { setFieldValue } = useFormikContext();
  const { minTablet } = useBreakpoints();

  const balancingGroups0 = values.undiscounted
    ? auctionConfig.undiscountedBalancingGroups0
    : auctionConfig.balancingGroups0;
  const balancingGroups1 = values.undiscounted
    ? auctionConfig.undiscountedBalancingGroups1
    : auctionConfig.balancingGroups1;

  // reset balancing groups whenever undiscounted changes
  useOnChange(() => {
    setFieldValue(`${fieldPrefix}balancingGroup0`, '');
    setFieldValue(`${fieldPrefix}balancingGroup1`, '');
  }, values.undiscounted);

  // reset upgrade deals whenever bundled case changes
  useOnChange(() => {
    setFieldValue(`${fieldPrefix}upgradeDealId0`, '');
    setFieldValue(`${fieldPrefix}upgradeDealId1`, '');
  }, values.upgradeSettings === 'BID_BOTH');

  // sync upgradeMaxCapacity for bundle case
  useEffect(
    () => {
      if (values.upgradeSettings !== 'BID_BOTH') return;
      setFieldValue(
        `${fieldPrefix}upgradeMaxCapacity1`,
        values.upgradeMaxCapacity0
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.upgradeSettings, values.upgradeMaxCapacity0]
  );

  // sync upgradeDealId for bundle case
  useEffect(
    () => {
      if (values.upgradeSettings !== 'BID_BOTH') return;
      setFieldValue(`${fieldPrefix}upgradeDealId1`, values.upgradeDealId0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.upgradeSettings, values.upgradeDealId0]
  );

  const balancingGroup0 = meta.balancingGroup0.visible && (
    <Select
      name={`${fieldPrefix}balancingGroup0`}
      label={`Desired Balancing Group${
        meta.balancingGroup0.required ? '*' : ''
      }`}
      options={[
        {
          value: '',
          label: meta.balancingGroup0.required
            ? 'Please Select'
            : 'No Balancing Group',
        },
        ...(balancingGroups0 ?? []).sort().map((item) => ({
          value: item,
          label: item,
        })),
      ]}
    />
  );

  const portfolioCode0 = meta.portfolioCode0.visible && (
    <Select
      name={`${fieldPrefix}portfolioCode0`}
      label={`Desired Portfolio Code${meta.portfolioCode0.required ? '*' : ''}`}
      options={[
        {
          value: '',
          label: meta.portfolioCode0.required
            ? 'Please Select'
            : 'No Portfolio Code',
        },
        ...auctionConfig.portfolioCodes0!.sort().map((item) => ({
          value: item,
          label: item,
        })),
      ]}
    />
  );

  const balancingGroup1 = meta.balancingGroup1.visible && (
    <Select
      name={`${fieldPrefix}balancingGroup1`}
      label={`Desired Balancing Group${
        meta.balancingGroup1.required ? '*' : ''
      }`}
      options={[
        {
          value: '',
          label: meta.balancingGroup1.required
            ? 'Please Select'
            : 'No Balancing Group',
        },
        ...(balancingGroups1 ?? []).sort().map((item) => ({
          value: item,
          label: item,
        })),
      ]}
    />
  );

  const portfolioCode1 = meta.portfolioCode1.visible && (
    <Select
      name={`${fieldPrefix}portfolioCode1`}
      label={`Desired Portfolio Code${meta.portfolioCode1.required ? '*' : ''}`}
      options={[
        {
          value: '',
          label: meta.portfolioCode1.required
            ? 'Please Select'
            : 'No Portfolio Code',
        },
        ...auctionConfig.portfolioCodes1!.sort().map((item) => ({
          value: item,
          label: item,
        })),
      ]}
    />
  );

  const isBalancingGroupVisible =
    meta.balancingGroup0.visible || meta.balancingGroup1.visible;
  const isPortfolioCodeVisible =
    meta.portfolioCode0.visible || meta.portfolioCode1.visible;
  return (
    <Stack gap={formGap}>
      {(isBalancingGroupVisible || isPortfolioCodeVisible) && (
        <>
          <Spacer />
          <CardDivider />
          <Spacer />
        </>
      )}

      {isBalancingGroupVisible && isPortfolioCodeVisible && (
        <Heading mode="card">Balancing Groups and Portfolio Codes</Heading>
      )}
      {isBalancingGroupVisible && !isPortfolioCodeVisible && (
        <Heading mode="card">Balancing Groups</Heading>
      )}
      {!isBalancingGroupVisible && isPortfolioCodeVisible && (
        <Heading mode="card">Portfolio Codes</Heading>
      )}
      {Boolean(balancingGroup0 || portfolioCode0) &&
        auction.networkPoint.bundled && (
          <Heading mode="card">{auction.networkPoint.tso0Name}</Heading>
        )}
      {balancingGroup0}
      {portfolioCode0}
      <>
        {Boolean(balancingGroup1 || portfolioCode1) && (
          <Heading mode="card">{auction.networkPoint.tso1Name}</Heading>
        )}
      </>
      {balancingGroup1}
      {portfolioCode1}

      {(meta.upgradeSettings.visible ||
        meta.conversionSettings.visible ||
        withinDayRollover) && (
        <>
          <Spacer />
          <CardDivider />
          <Spacer />
          <Stack gap={formGap}>
            <Heading mode="card">Bidding Options</Heading>
            {withinDayRollover}

            {meta.upgradeSettings.visible && (
              <>
                <Radios
                  name={`${fieldPrefix}upgradeSettings`}
                  label="Upgrade of Capacity"
                  info={
                    <>
                      <strong>Germany</strong>: The ability to upgrade
                      interruptible into firm capacity, or firm capacity with
                      interruptible components into firm capacity with fewer
                      interruptible components, depends on the respective TSO
                      capacity product offering. The respective TSO’s TTCs apply
                      for processing capacity upgrades.
                    </>
                  }
                  options={[
                    { value: 'NO_BID', label: 'No Upgrade' },
                    ...(auctionConfig.upgradeSupported0
                      ? [
                          {
                            value: 'BID_TSO_0',
                            label: `Upgrade for ${auction.networkPoint.tso0Name}`,
                          },
                        ]
                      : []),
                    ...(auctionConfig.upgradeSupported1
                      ? [
                          {
                            value: 'BID_TSO_1',
                            label: `Upgrade for ${auction.networkPoint.tso1Name}`,
                          },
                        ]
                      : []),
                    ...(meta.isUpgradePossibleOnBothSides
                      ? [
                          {
                            value: 'BID_BOTH',
                            label: 'Upgrade for Both',
                          },
                        ]
                      : []),
                  ]}
                />

                {values.upgradeSettings === 'BID_TSO_0' && (
                  <>
                    <Input
                      name={`${fieldPrefix}upgradeMaxCapacity0`}
                      label="Maximal Capacity to be Upgraded*"
                      type="number"
                      placeholder="E.g. 1"
                      unit={
                        physicalUnitLabels[auction.networkPoint.physicalUnit]
                      }
                    />

                    <Select
                      name={`${fieldPrefix}upgradeDealId0`}
                      label="Deal ID"
                      options={[
                        {
                          value: '',
                          label: 'Old Contract / No Deal ID',
                        },
                        ...(auctionConfig.upgradableDeals0 ?? [])
                          .sort()
                          .map((deal) => ({
                            value: deal.dealId,
                            label: deal.dealId,
                          })),
                      ]}
                    />

                    {meta.upgradeContractId0.visible && (
                      <Input
                        name={`${fieldPrefix}upgradeContractId0`}
                        label={`Contract ID${
                          meta.upgradeContractId0.required ? '*' : ''
                        }`}
                      />
                    )}

                    {meta.upgradeFormerBalancing0.visible && (
                      <Input
                        name={`${fieldPrefix}upgradeFormerBalancing0`}
                        label={`Former Balancing Group/Sub-Balancing Account${
                          meta.upgradeFormerBalancing0.required ? '*' : ''
                        }`}
                        placeholder="E.g. Group or Account Code"
                        info="If the capacity contract is already brought into a balancing group or sub-balancing account, the code of this group or account has to be entered."
                      />
                    )}
                  </>
                )}

                {values.upgradeSettings === 'BID_TSO_1' && (
                  <>
                    <Input
                      name={`${fieldPrefix}upgradeMaxCapacity1`}
                      label="Maximal Capacity to be Upgraded*"
                      type="number"
                      placeholder="E.g. 1"
                      unit={
                        physicalUnitLabels[auction.networkPoint.physicalUnit]
                      }
                    />

                    <Select
                      name={`${fieldPrefix}upgradeDealId1`}
                      label="Deal ID"
                      options={[
                        {
                          value: '',
                          label: 'Old Contract / No Deal ID',
                        },
                        ...(auctionConfig.upgradableDeals1 ?? [])
                          .sort()
                          .map((deal) => ({
                            value: deal.dealId,
                            label: deal.dealId,
                          })),
                      ]}
                    />

                    {meta.upgradeContractId1.visible && (
                      <Input
                        name={`${fieldPrefix}upgradeContractId1`}
                        label={`Contract ID${
                          meta.upgradeContractId1.required ? '*' : ''
                        }`}
                      />
                    )}

                    {meta.upgradeFormerBalancing1.visible && (
                      <Input
                        name={`${fieldPrefix}upgradeFormerBalancing1`}
                        label={`Former Balancing Group/Sub-Balancing Account${
                          meta.upgradeFormerBalancing1.required ? '*' : ''
                        }`}
                        placeholder="E.g. Group or Account Code"
                        info="If the capacity contract is already brought into a balancing group or sub-balancing account, the code of this group or account has to be entered."
                      />
                    )}
                  </>
                )}

                {values.upgradeSettings === 'BID_BOTH' && (
                  <>
                    <Input
                      name={`${fieldPrefix}upgradeMaxCapacity0`}
                      label="Maximal Capacity to be Upgraded*"
                      type="number"
                      placeholder="E.g. 1"
                      unit={
                        physicalUnitLabels[auction.networkPoint.physicalUnit]
                      }
                    />

                    <Select
                      name={`${fieldPrefix}upgradeDealId0`}
                      label="Deal ID"
                      options={[
                        {
                          value: '',
                          label: 'Old Contract / No Deal ID',
                        },
                        ...(auctionConfig.upgradableDealsBundled ?? [])
                          .sort()
                          .map((deal) => ({
                            value: deal.dealId,
                            label: deal.dealId,
                          })),
                      ]}
                    />

                    <Stack
                      flow="column"
                      alignItems="stretch"
                      templateColumns={minTablet ? '1fr 1fr' : '1fr'}
                      gap={formGap}
                    >
                      {(meta.upgradeContractId0.visible ||
                        meta.upgradeFormerBalancing0.visible) && (
                        <Card>
                          <Stack gap={formGap}>
                            <strong>{auction.networkPoint.tso0Name}</strong>
                            {meta.upgradeContractId0.visible && (
                              <Input
                                name={`${fieldPrefix}upgradeContractId0`}
                                label={`Contract ID${
                                  meta.upgradeContractId0.required ? '*' : ''
                                }`}
                              />
                            )}

                            {meta.upgradeFormerBalancing0.visible && (
                              <Input
                                name={`${fieldPrefix}upgradeFormerBalancing0`}
                                label={`Former Balancing Group/Sub-Balancing Account${
                                  meta.upgradeFormerBalancing0.required
                                    ? '*'
                                    : ''
                                }`}
                                placeholder="E.g. Group or Account Code"
                                info="If the capacity contract is already brought into a balancing group or sub-balancing account, the code of this group or account has to be entered."
                              />
                            )}
                          </Stack>
                        </Card>
                      )}

                      {(meta.upgradeContractId1.visible ||
                        meta.upgradeFormerBalancing1.visible) && (
                        <Card>
                          <Stack gap={formGap}>
                            <strong>{auction.networkPoint.tso1Name}</strong>
                            {meta.upgradeContractId1.visible && (
                              <Input
                                name={`${fieldPrefix}upgradeContractId1`}
                                label={`Contract ID${
                                  meta.upgradeContractId1.required ? '*' : ''
                                }`}
                              />
                            )}

                            {meta.upgradeFormerBalancing1.visible && (
                              <Input
                                name={`${fieldPrefix}upgradeFormerBalancing1`}
                                label={`Former Balancing Group/Sub-Balancing Account${
                                  meta.upgradeFormerBalancing1.required
                                    ? '*'
                                    : ''
                                }`}
                                placeholder="E.g. Group or Account Code"
                                info="If the capacity contract is already brought into a balancing group or sub-balancing account, the code of this group or account has to be entered."
                              />
                            )}
                          </Stack>
                        </Card>
                      )}
                    </Stack>
                  </>
                )}
              </>
            )}
            {meta.conversionSettings.visible && (
              <>
                <Spacer />
                <CardDivider />
                <Spacer />
                <Heading mode="card">Conversion</Heading>

                <Radios
                  name={`${fieldPrefix}conversionSettings`}
                  label="Unbundled to Bundled Contract"
                  info={
                    <Stack gap={1}>
                      <p>
                        Depending on the involved TSOs, the unbundled to bundled
                        conversion may only be offered on one side of the bundle
                        via the PRISMA platform.
                      </p>
                      <p>
                        If offered on both sides the conversion can only be
                        performed on one side of the bundle.
                      </p>
                      <p>
                        In case the unbundled to bundled conversion is not
                        offered for a TSO, please contact the concerned TSO
                        directly.
                      </p>
                    </Stack>
                  }
                  options={[
                    { value: 'NO_BID', label: 'No Conversion' },
                    ...(auctionConfig.conversionSupported0
                      ? [
                          {
                            value: 'BID_TSO_0',
                            label: `Conversion for ${auction.networkPoint.tso0Name}`,
                          },
                        ]
                      : []),
                    ...(auctionConfig.conversionSupported1
                      ? [
                          {
                            value: 'BID_TSO_1',
                            label: `Conversion for ${auction.networkPoint.tso1Name}`,
                          },
                        ]
                      : []),
                  ]}
                />

                {values.conversionSettings === 'BID_TSO_0' && (
                  <Conversion
                    meta={meta}
                    index="0"
                    auction={auction}
                    auctionConfig={auctionConfig}
                    fieldPrefix={fieldPrefix}
                    values={values}
                  />
                )}

                {values.conversionSettings === 'BID_TSO_1' && (
                  <Conversion
                    meta={meta}
                    index="1"
                    auction={auction}
                    auctionConfig={auctionConfig}
                    fieldPrefix={fieldPrefix}
                    values={values}
                  />
                )}
              </>
            )}
          </Stack>
        </>
      )}
      <Spacer />
      {meta.acceptGtcTso0.visible && (
        <>
          <CardDivider />
          <Spacer />
          <Heading mode="card">General Terms and Conditions</Heading>
          <AcceptGtc
            name={`${fieldPrefix}acceptGtcTso0`}
            operatorName={auction.networkPoint.tso0Name}
            link={auctionConfig.tsoGtcUrl0}
            withUnderlinedLinks
          />
          {meta.acceptGtcTso1.visible && (
            <AcceptGtc
              name={`${fieldPrefix}acceptGtcTso1`}
              operatorName={auction.networkPoint.tso1Name!}
              link={auctionConfig.tsoGtcUrl1!}
              withUnderlinedLinks
            />
          )}
          {meta.specificConditions0.visible && (
            <AcceptSpecificCondition
              name={`${fieldPrefix}specificConditions0`}
              operatorName={auction.networkPoint.tso0Name}
              label={auctionConfig.specificConditionsLabel0!}
              note={auctionConfig.specificConditionsNote0!}
              withUnderlinedLinks
            />
          )}
          {meta.specificConditions1.visible && (
            <AcceptSpecificCondition
              name={`${fieldPrefix}specificConditions1`}
              operatorName={auction.networkPoint.tso1Name!}
              label={auctionConfig.specificConditionsLabel1!}
              note={auctionConfig.specificConditionsNote1!}
              withUnderlinedLinks
            />
          )}
        </>
      )}
    </Stack>
  );
};

const Conversion: FC<{
  meta: FieldsMeta;
  index: '0' | '1';
  auction: AuctionDetail | FakeAuctionForComfortBid;
  auctionConfig: AuctionConfig | ComfortBidConfig;
  fieldPrefix: string;
  values: SharedBiddingFormValues;
}> = ({ meta, index, auction, auctionConfig, fieldPrefix, values }) => {
  const configMultipleKey = `multipleConversionsPerBid${index}` as const;
  const configMultiple = auctionConfig[configMultipleKey];

  const metaContractIdKey = `conversionContractId${index}` as const;
  const metaContractId = meta[metaContractIdKey];

  const metaFormerBalancingKey = `conversionFormerBalancing${index}` as const;
  const metaFormerBalancing = meta[metaFormerBalancingKey];

  const metaRuntimesKey = `conversionRuntimes${index}` as const;
  const metaRuntime = meta[metaRuntimesKey];

  const conversionsKey = `conversions${index}` as const;
  const conversions = values[conversionsKey];

  const Wrapper = configMultiple ? Card : Fragment;

  return (
    <Stack gap={formGap}>
      <FieldArray
        name={`${fieldPrefix}${conversionsKey}`}
        render={(conversionsArrayHelpers) => (
          <>
            {conversions.map((conversion, conversionIndex) => (
              <Wrapper key={conversion.id}>
                {configMultiple && (
                  <Stack flow="column" templateColumns="1fr auto" gap={1}>
                    <strong>Conversion #{conversionIndex + 1}</strong>

                    {conversionIndex !== 0 && (
                      <Button
                        data-testid={`remove-conversion-${conversionIndex}`}
                        mode="link"
                        onClick={() =>
                          conversionsArrayHelpers.remove(conversionIndex)
                        }
                      >
                        <FontAwesomeIcon icon={faMinusCircle} aria-hidden />{' '}
                        Remove
                      </Button>
                    )}
                  </Stack>
                )}

                <Stack gap={formGap}>
                  {metaContractId.visible && (
                    <Input
                      name={`${fieldPrefix}${conversionsKey}[${conversionIndex}].contractId`}
                      label={`Contract ID${metaContractId.required ? '*' : ''}`}
                    />
                  )}

                  {metaFormerBalancing.visible && (
                    <Input
                      name={`${fieldPrefix}${conversionsKey}[${conversionIndex}].formerBalancing`}
                      label={`Former Balancing Group/Sub-Balancing Account${
                        metaFormerBalancing.required ? '*' : ''
                      }`}
                      placeholder="E.g. Group or Account Code"
                      info="If the capacity contract is already brought into a balancing group or sub-balancing account, the code of this group or account has to be entered."
                    />
                  )}

                  <FieldArray
                    name={`${fieldPrefix}${conversionsKey}[${conversionIndex}].intervals`}
                    render={(intervalsArrayHelpers) => (
                      <>
                        {conversions[conversionIndex].intervals.map(
                          (interval, intervalIndex) => (
                            <Card key={interval.id}>
                              {metaRuntime.visible && (
                                <Stack
                                  flow="column"
                                  templateColumns="1fr auto"
                                  gap={1}
                                >
                                  <strong>Conversion Runtime</strong>

                                  {intervalIndex !== 0 && (
                                    <Button
                                      data-testid={`remove-conversion-interval-${intervalIndex}`}
                                      mode="link"
                                      onClick={() =>
                                        intervalsArrayHelpers.remove(
                                          intervalIndex
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faMinusCircle}
                                        aria-hidden
                                      />{' '}
                                      Remove
                                    </Button>
                                  )}
                                </Stack>
                              )}

                              <Stack gap={formGap}>
                                <Input
                                  name={`${fieldPrefix}${conversionsKey}[${conversionIndex}].intervals[${intervalIndex}].quantity`}
                                  label="Maximal Capacity to be Converted*"
                                  unit={
                                    physicalUnitLabels[
                                      auction.networkPoint.physicalUnit
                                    ]
                                  }
                                  type="number"
                                  placeholder="E.g. 1"
                                />
                                {metaRuntime.visible && (
                                  <DateRangeInput
                                    fromName={`${fieldPrefix}${conversionsKey}[${conversionIndex}].intervals[${intervalIndex}].runtimeStart`}
                                    toName={`${fieldPrefix}${conversionsKey}[${conversionIndex}].intervals[${intervalIndex}].runtimeEnd`}
                                    time="gas-month"
                                    groupLabel="Runtime*"
                                    fromLabel="Runtime Start*"
                                    toLabel="Runtime End*"
                                    stackedLabel={false}
                                  />
                                )}
                              </Stack>
                            </Card>
                          )
                        )}

                        {metaRuntime.visible && (
                          <Stack flow="column" justifyContent="end">
                            <Button
                              mode="link"
                              onClick={() => {
                                const interval: ConversionIntervalValues = {
                                  id: uuid(),
                                  quantity: null,
                                  runtimeStart: auction.runtime.start,
                                  runtimeEnd: auction.runtime.end,
                                };
                                intervalsArrayHelpers.push(interval);
                              }}
                              data-testid={`add-conversion-runtime-${conversionIndex}`}
                            >
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                aria-hidden
                              />{' '}
                              Add Conversion Runtime
                            </Button>
                          </Stack>
                        )}
                      </>
                    )}
                  />
                </Stack>
              </Wrapper>
            ))}

            {configMultiple && (
              <Stack flow="column" justifyContent="end">
                <Button
                  mode="link"
                  onClick={() => {
                    const conversion: ConversionValues = {
                      id: uuid(),
                      contractId: null,
                      formerBalancing: null,
                      intervals: [
                        {
                          id: uuid(),
                          quantity: null,
                          runtimeStart: auction.runtime.start,
                          runtimeEnd: auction.runtime.end,
                        },
                      ],
                    };
                    conversionsArrayHelpers.push(conversion);
                  }}
                  data-testid="add-conversion"
                >
                  <FontAwesomeIcon icon={faPlusCircle} aria-hidden /> Add
                  Conversion
                </Button>
              </Stack>
            )}
          </>
        )}
      />
    </Stack>
  );
};

type ValidateOptions = {
  auction: AuctionDetail | FakeAuctionForComfortBid;
  maxQuantity: number | null;
  meta: FieldsMeta;
  values: {
    upgradeSettings: 'NO_BID' | 'BID_TSO_0' | 'BID_TSO_1' | 'BID_BOTH';
    conversionSettings: 'NO_BID' | 'BID_TSO_0' | 'BID_TSO_1';
  };
};

function getConversionIntervalsSchema({
  conversionRuntimes,
  required,
  maxQuantity,
  auction,
}: {
  required: boolean;
  conversionRuntimes: boolean;
  maxQuantity: number | null;
  auction: AuctionDetail | FakeAuctionForComfortBid;
}) {
  const baseSchema = z.object({
    id: z.string(),
    quantity: z
      .number()
      .int('{label} cannot contain decimals.')
      .min(1, '{label} must be greater than or equal to 1.')
      .nullable()
      .transform(requiredOutputWhen(required))
      .refine(
        (value) => {
          if (value === null) return true;
          if (maxQuantity === null) return true;
          return value <= maxQuantity;
        },
        `You cannot convert more than the ${maxQuantity?.toLocaleString(
          'en-GB',
          exactFormat
        )} ${physicalUnitLabels[auction.networkPoint.physicalUnit]} you bid.`
      ),
    // runtime is not relevant in the base case, but mentioned here for the typings
    runtimeStart: z.string().nullable(),
    runtimeEnd: z.string().nullable(),
  });

  const runtimeSchema = z.object({
    runtimeStart: z
      .string()
      .nullable()
      .transform(requiredOutputWhen(required))
      .refine(
        (value) =>
          value === null
            ? true
            : !isBefore(new Date(value), new Date(auction.runtime.start)),
        'The runtime start cannot be earlier than the runtime start of the auction.'
      ),
    runtimeEnd: z
      .string()
      .nullable()
      .transform(requiredOutputWhen(required))
      .refine(
        (value) =>
          value === null
            ? true
            : !isAfter(new Date(value), new Date(auction.runtime.end)),
        'The runtime end cannot be later than the runtime end of the auction.'
      ),
  });

  if (conversionRuntimes) {
    return z
      .array(runtimeSchema)
      .superRefine(validateConversionIntervals)
      .and(z.array(baseSchema));
  }

  return z.array(baseSchema);
}

export function getSharedFieldsSchema({
  auction,
  maxQuantity,
  meta,
  values,
}: ValidateOptions) {
  const conversionSchema0 = z.object({
    id: z.string().or(z.number()),
    contractId: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.conversionContractId0.visible &&
            meta.conversionContractId0.required
        )
      ),
    formerBalancing: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.conversionFormerBalancing0.visible &&
            meta.conversionFormerBalancing0.required
        )
      ),
    intervals: getConversionIntervalsSchema({
      required: values.conversionSettings === 'BID_TSO_0',
      conversionRuntimes: meta.conversionRuntimes0.visible,
      auction,
      maxQuantity,
    }),
  });

  const conversionSchema1 = z.object({
    id: z.string().or(z.number()),
    contractId: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.conversionContractId1.visible &&
            meta.conversionContractId1.required
        )
      ),
    formerBalancing: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.conversionFormerBalancing1.visible &&
            meta.conversionFormerBalancing1.required
        )
      ),
    intervals: getConversionIntervalsSchema({
      required: values.conversionSettings === 'BID_TSO_1',
      conversionRuntimes: meta.conversionRuntimes1.visible,
      auction,
      maxQuantity,
    }),
  });

  const schema = z.object({
    priceCurrency: z.enum(['TSO_0', 'TSO_1']),
    undiscounted: z.boolean(),
    upgradeSettings: z.enum(['NO_BID', 'BID_TSO_0', 'BID_TSO_1', 'BID_BOTH']),
    conversionSettings: z.enum(['NO_BID', 'BID_TSO_0', 'BID_TSO_1']).refine(
      () =>
        !Boolean(
          // conversion and upgrade on exit:
          (values.conversionSettings === 'BID_TSO_0' &&
            (values.upgradeSettings === 'BID_TSO_0' ||
              values.upgradeSettings === 'BID_BOTH')) ||
            // conversion and upgrade on entry:
            (values.conversionSettings === 'BID_TSO_1' &&
              (values.upgradeSettings === 'BID_TSO_1' ||
                values.upgradeSettings === 'BID_BOTH'))
        ),
      'A conversion is not possible as this side is already used in an upgrade of capacity.'
    ),
    upgradeDealId0: z.string().nullable(),
    upgradeDealId1: z.string().nullable(),
    balancingGroup0: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.balancingGroup0.visible && meta.balancingGroup0.required
        )
      ),
    portfolioCode0: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.portfolioCode0.visible && meta.portfolioCode0.required
        )
      ),
    balancingGroup1: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.balancingGroup1.visible && meta.balancingGroup1.required
        )
      ),
    portfolioCode1: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.portfolioCode1.visible && meta.portfolioCode1.required
        )
      ),
    upgradeMaxCapacity0: z
      .number()
      .int('{label} cannot contain decimals.')
      .min(1, '{label} must be greater than or equal to 1.')
      .max(
        auction.marketable
          ? Number(auction.marketable.originalValue.value)
          : Infinity,
        'You must not convert more than is marketable by the auction.'
      )
      .nullable()
      .transform(requiredOutputWhen(meta.upgradeMaxCapacity0.visible)),
    upgradeMaxCapacity1: z
      .number()
      .int('{label} cannot contain decimals.')
      .min(1, '{label} must be greater than or equal to 1.')
      .max(
        auction.marketable
          ? Number(auction.marketable.originalValue.value)
          : Infinity,
        'You must not convert more than is marketable by the auction.'
      )
      .nullable()
      .transform(requiredOutputWhen(meta.upgradeMaxCapacity1.visible)),
    upgradeContractId0: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.upgradeContractId0.visible && meta.upgradeContractId0.required
        )
      ),
    upgradeFormerBalancing0: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.upgradeFormerBalancing0.visible &&
            meta.upgradeFormerBalancing0.required
        )
      ),
    upgradeContractId1: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.upgradeContractId1.visible && meta.upgradeContractId1.required
        )
      ),
    upgradeFormerBalancing1: z
      .string()
      .nullable()
      .transform(
        requiredOutputWhen(
          meta.upgradeFormerBalancing1.visible &&
            meta.upgradeFormerBalancing1.required
        )
      ),
    conversions0: z.array(conversionSchema0),
    conversions1: z.array(conversionSchema1),
    acceptGtcTso0: z
      .boolean()
      .refine(
        (value) => (meta.acceptGtcTso0.visible ? value : true),
        'Please accept {label}.'
      ),
    acceptGtcTso1: z
      .boolean()
      .refine(
        (value) => (meta.acceptGtcTso1.visible ? value : true),
        'Please accept {label}.'
      ),
    specificConditions0: z
      .boolean()
      .refine(
        (value) => (meta.specificConditions0.visible ? value : true),
        'Please accept {label}.'
      ),
    specificConditions1: z
      .boolean()
      .refine(
        (value) => (meta.specificConditions1.visible ? value : true),
        'Please accept {label}.'
      ),
  });

  return schema;
}

type SharedBiddingFormValues = z.input<
  ReturnType<typeof getSharedFieldsSchema>
>;
type ValidSharedBiddingFormValues = z.output<
  ReturnType<typeof getSharedFieldsSchema>
>;

type ConversionValues = (
  | SharedBiddingFormValues['conversions0']
  | SharedBiddingFormValues['conversions1']
)[number];

type ConversionIntervalValues = ConversionValues['intervals'][number];

function validateConversionIntervals(
  intervals: {
    runtimeStart: string | null;
    runtimeEnd: string | null;
  }[],
  ctx: z.RefinementCtx
) {
  intervals.forEach((currentInterval, index) => {
    if (index === 0) return; // ignore first interval
    const prevInterval = intervals[index - 1];

    if (!currentInterval.runtimeStart || !prevInterval.runtimeEnd) return; // ignore if not filled out, yet

    const isValid = !isBefore(
      new Date(currentInterval.runtimeStart),
      new Date(prevInterval.runtimeEnd)
    );
    if (isValid) return;

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'The runtime start cannot be earlier than the runtime end of the previous interval.',
      path: [index, 'runtimeStart'],
    });
  });
}

export function getDefaultSharedFormValues(
  auction: AuctionDetail | FakeAuctionForComfortBid
): SharedBiddingFormValues {
  return {
    priceCurrency: 'TSO_0',
    undiscounted: false,
    balancingGroup0: '',
    portfolioCode0: '',
    balancingGroup1: '',
    portfolioCode1: '',
    upgradeSettings: 'NO_BID',
    upgradeMaxCapacity0: null,
    upgradeDealId0: '',
    upgradeContractId0: '',
    upgradeFormerBalancing0: '',
    upgradeMaxCapacity1: null,
    upgradeDealId1: '',
    upgradeContractId1: '',
    upgradeFormerBalancing1: '',
    conversionSettings: 'NO_BID',
    conversions0: [
      {
        id: uuid(),
        contractId: null,
        formerBalancing: null,
        intervals: [
          {
            id: uuid(),
            quantity: null,
            runtimeStart: auction.runtime.start,
            runtimeEnd: auction.runtime.end,
          },
        ],
      },
    ],
    conversions1: [
      {
        id: uuid(),
        contractId: null,
        formerBalancing: null,
        intervals: [
          {
            id: uuid(),
            quantity: null,
            runtimeStart: auction.runtime.start,
            runtimeEnd: auction.runtime.end,
          },
        ],
      },
    ],
    acceptGtcTso0: false,
    acceptGtcTso1: false,
    specificConditions0: false,
    specificConditions1: false,
  };
}

export type BaseOrder = {
  minQuantity?: Quantity | null; // only in short term
  maxQuantity?: Quantity | null; // only in short term
  price?: Price | null; // only in short term
  steps?: AuctionOrderStep[] | null; // only long term
  withinDayRollover?: boolean | null; // only in short term
  balancingGroup0?: string | null;
  balancingGroup1?: string | null;
  portfolioCode0?: string | null;
  portfolioCode1?: string | null;
  upgrade0?: CapacityUpgrade | null;
  upgrade1?: CapacityUpgrade | null;
  conversions?: PotentiallyBundledConversion[] | null;
  undiscounted?: boolean | null;
};

export function getPrefilledSharedFormValues(
  auction: AuctionDetail | FakeAuctionForComfortBid,
  order: BaseOrder
): StrictOmit<SharedBiddingFormValues, 'priceCurrency'> {
  return {
    undiscounted: order.undiscounted ?? false,
    balancingGroup0: order.balancingGroup0 ?? '',
    portfolioCode0: order.portfolioCode0 ?? '',
    balancingGroup1: order.balancingGroup1 ?? '',
    portfolioCode1: order.portfolioCode1 ?? '',
    upgradeSettings:
      order.upgrade0 && order.upgrade1
        ? 'BID_BOTH'
        : order.upgrade0
          ? 'BID_TSO_0'
          : order.upgrade1
            ? 'BID_TSO_1'
            : 'NO_BID',
    upgradeMaxCapacity0: order.upgrade0
      ? Number(order.upgrade0.maxQuantity.originalValue.value)
      : null,
    upgradeDealId0: order.upgrade0 ? String(order.upgrade0.oldDealId) : '',
    upgradeContractId0: order.upgrade0?.legalContractId ?? '',
    upgradeFormerBalancing0: order.upgrade0?.oldBalancingGroup ?? '',
    upgradeMaxCapacity1: order.upgrade1
      ? Number(order.upgrade1.maxQuantity.originalValue.value)
      : null,
    upgradeDealId1: order.upgrade1 ? String(order.upgrade1.oldDealId) : '',
    upgradeContractId1: order.upgrade1?.legalContractId ?? '',
    upgradeFormerBalancing1: order.upgrade1?.oldBalancingGroup ?? '',
    conversionSettings:
      order.conversions && order.conversions[0]?.conversion0
        ? 'BID_TSO_0'
        : order.conversions && order.conversions[0]?.conversion1
          ? 'BID_TSO_1'
          : 'NO_BID',
    conversions0:
      order.conversions && order.conversions[0]?.conversion0
        ? order.conversions.map((conversion) => ({
            id: conversion.conversion0!.id,
            contractId: conversion.conversion0!.contractId ?? null,
            formerBalancing: conversion.conversion0!.balancingGroup ?? null,
            intervals: conversion.conversion0!.intervals.map((item) => ({
              id: uuid(),
              quantity: Number(item.amount.originalValue.value),
              runtimeStart: item.interval?.start ?? auction.runtime.start,
              runtimeEnd: item.interval?.end ?? auction.runtime.end,
            })) ?? [
              {
                id: uuid(),
                quantity: null,
                runtimeStart: auction.runtime.start,
                runtimeEnd: auction.runtime.end,
              },
            ],
          }))
        : [
            {
              id: uuid(),
              contractId: null,
              formerBalancing: null,
              intervals: [
                {
                  id: uuid(),
                  quantity: null,
                  runtimeStart: auction.runtime.start,
                  runtimeEnd: auction.runtime.end,
                },
              ],
            },
          ],
    conversions1:
      order.conversions && order.conversions[0]?.conversion1
        ? order.conversions.map((conversion) => ({
            id: conversion.conversion1!.id,
            contractId: conversion.conversion1!.contractId ?? null,
            formerBalancing: conversion.conversion1!.balancingGroup ?? null,
            intervals: conversion.conversion1!.intervals.map((item) => ({
              id: uuid(),
              quantity: Number(item.amount.originalValue.value),
              runtimeStart: item.interval?.start ?? auction.runtime.start,
              runtimeEnd: item.interval?.end ?? auction.runtime.end,
            })) ?? [
              {
                id: uuid(),
                quantity: null,
                runtimeStart: auction.runtime.start,
                runtimeEnd: auction.runtime.end,
              },
            ],
          }))
        : [
            {
              id: uuid(),
              contractId: null,
              formerBalancing: null,
              intervals: [
                {
                  id: uuid(),
                  quantity: null,
                  runtimeStart: auction.runtime.start,
                  runtimeEnd: auction.runtime.end,
                },
              ],
            },
          ],
    acceptGtcTso0: false,
    acceptGtcTso1: false,
    specificConditions0: false,
    specificConditions1: false,
  };
}

export type SharedSubmitBidData = {
  undiscounted: true | null;
  balancingGroup0: string | null;
  balancingGroup1: string | null;
  portfolioCode0: string | null;
  portfolioCode1: string | null;
  upgrade0: WriteCapacityUpgrade | null;
  upgrade1: WriteCapacityUpgrade | null;
  conversions: WritePotentiallyBundledConversion[] | null;
  originOrderId: number | null;
};

export function buildSharedSubmitData({
  values,
  auction,
  auctionConfig,
}: {
  values: SharedBiddingFormValues;
  auction: AuctionDetail | FakeAuctionForComfortBid;
  auctionConfig: AuctionConfig | ComfortBidConfig;
}): StrictOmit<SharedSubmitBidData, 'originOrderId'> {
  return {
    undiscounted: values.undiscounted || null,
    balancingGroup0: values.balancingGroup0 || null,
    balancingGroup1: values.balancingGroup1 || null,
    portfolioCode0: values.portfolioCode0 || null,
    portfolioCode1: values.portfolioCode1 || null,
    upgrade0:
      values.upgradeSettings === 'BID_TSO_0' ||
      values.upgradeSettings === 'BID_BOTH'
        ? {
            legalContractId: values.upgradeContractId0 || null,
            oldBalancingGroup: values.upgradeFormerBalancing0 || null,
            oldDealId: values.upgradeDealId0
              ? parseInt(values.upgradeDealId0)
              : null,
            maxQuantity: {
              originalValue: {
                value: values.upgradeMaxCapacity0!.toString(),
                floatingPointNumber: true,
              },
              originalUnit:
                physicalUnitLabels[auction.networkPoint.physicalUnit],
            },
          }
        : null,
    upgrade1:
      values.upgradeSettings === 'BID_TSO_1' ||
      values.upgradeSettings === 'BID_BOTH'
        ? {
            legalContractId: values.upgradeContractId1 || null,
            oldBalancingGroup: values.upgradeFormerBalancing1 || null,
            oldDealId: values.upgradeDealId1
              ? parseInt(values.upgradeDealId1)
              : null,
            maxQuantity: {
              originalValue: {
                value: values.upgradeMaxCapacity1!.toString(),
                floatingPointNumber: true,
              },
              originalUnit:
                physicalUnitLabels[auction.networkPoint.physicalUnit],
            },
          }
        : null,
    conversions:
      values.conversionSettings === 'BID_TSO_0'
        ? values.conversions0.map((conversion) => ({
            conversion0: {
              contractId: conversion.contractId || null,
              balancingGroup: conversion.formerBalancing || null,
              intervals: auctionConfig.runtimeForConversionSupported0
                ? conversion.intervals.map((item) => ({
                    amount: {
                      originalValue: {
                        value: item.quantity!.toString(),
                        floatingPointNumber: true,
                      },
                      originalUnit:
                        physicalUnitLabels[auction.networkPoint.physicalUnit],
                    },
                    interval: {
                      start: item.runtimeStart!,
                      end: item.runtimeEnd!,
                    },
                  }))
                : [
                    {
                      amount: {
                        originalValue: {
                          value: conversion.intervals[0].quantity!.toString(),
                          floatingPointNumber: true,
                        },
                        originalUnit:
                          physicalUnitLabels[auction.networkPoint.physicalUnit],
                      },
                    },
                  ],
            },
            conversion1: null,
          }))
        : values.conversionSettings === 'BID_TSO_1'
          ? values.conversions1.map((conversion) => ({
              conversion0: null,
              conversion1: {
                contractId: conversion.contractId || null,
                balancingGroup: conversion.formerBalancing || null,
                intervals: auctionConfig.runtimeForConversionSupported1
                  ? conversion.intervals.map((item) => ({
                      amount: {
                        originalValue: {
                          value: item.quantity!.toString(),
                          floatingPointNumber: true,
                        },
                        originalUnit:
                          physicalUnitLabels[auction.networkPoint.physicalUnit],
                      },
                      interval: {
                        start: item.runtimeStart!,
                        end: item.runtimeEnd!,
                      },
                    }))
                  : [
                      {
                        amount: {
                          originalValue: {
                            value: conversion.intervals[0].quantity!.toString(),
                            floatingPointNumber: true,
                          },
                          originalUnit:
                            physicalUnitLabels[
                              auction.networkPoint.physicalUnit
                            ],
                        },
                      },
                    ],
              },
            }))
          : [],
  };
}
