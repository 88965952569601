import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Transaction } from 'src/apis/surrender-service/types';
import { SurrenderRootRequest } from 'src/apis/surrender-service/use-surrender-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type RequestOptions = {
  url: string;
};

export function useTransaction(surrenderRoot: SurrenderRootRequest) {
  const { transactionId } = useParams<{ transactionId: string }>();

  const transaction = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<Transaction>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!surrenderRoot.response) return;

    if (!surrenderRoot.response.data._links.getTransaction)
      throw new Forbidden();

    const url = urlTemplate
      .parse(surrenderRoot.response.data._links.getTransaction.href)
      .expand({ transactionId });

    transaction.execute({ url });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surrenderRoot.response, transactionId]);

  return transaction;
}

export type TransactionRequest = ReturnType<typeof useTransaction>;
