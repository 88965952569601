import { FC, lazy } from 'react';
import { useAuthorizationService } from 'src/apis/authorization-service/use-authorization-service';
import { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';

const Page = lazy(() => import('./page'));

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const InviteUser: FC<Props> = ({ organisationService }) => {
  const authorizationService = useAuthorizationService();

  if (!authorizationService.response) return <PageSpinner />;

  return (
    <Page
      organisationService={organisationService}
      authorizationService={authorizationService}
    />
  );
};
