import { orderBy } from 'lodash';
import { GroupedFormOption } from 'src/components/form/checkbox-group';
import { FormOption } from 'src/components/form/form-option';
import { Colors } from 'src/styles';
import { css } from 'styled-components';

export type SortByOptions = 'label' | 'value' | 'manual';

export function applySortBy<T extends FormOption<string | null>>(
  sortBy: SortByOptions,
  options: T[]
) {
  switch (sortBy) {
    case 'manual':
      return options;
    case 'label':
      return orderBy(
        options,
        // place empty value on top, no matter what the actual label is
        [(item) => (item.value ? item.label.toLowerCase() : '')],
        ['asc']
      );
    case 'value':
      return orderBy(
        options,
        // place empty value on top, no matter what the actual label is
        [(item) => (item.value ? item.value.toLowerCase() : '')],
        ['asc']
      );
  }
}

export function applyGroupSortBy<T extends GroupedFormOption<string>>(
  sortBy: SortByOptions,
  options: T[]
) {
  switch (sortBy) {
    case 'manual':
      return options;
    case 'label':
    case 'value': // groups have no value, so we treat it as label
      return orderBy(options, [(item) => item.label.toLowerCase()], ['asc']);
  }
}

export const selectOptionBaseStyle = css`
  color: ${Colors.brand};
  word-break: break-word;
  margin: 0.1rem;
  border-radius: 0.4rem;
  user-select: none;
  padding: 1rem;
  padding-left: 2rem;

  /* see https://github.com/ariakit/ariakit/issues/845#issuecomment-785493623 */
  pointer-events: initial !important;
`;
