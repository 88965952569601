import { useEffect } from 'react';
import {
  ShipperAssignmentsOverviewParams,
  ShipperAssignmentsResponse,
} from 'src/apis/assignment-service/types';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type Props = {
  url: string;
  params: ShipperAssignmentsOverviewParams;
};

export function useShipperAssignments({ url, params }: Props) {
  const shipperAssignments = useAxios(
    (axios, baseConfig, { url, params }: Props) =>
      axios.request<ShipperAssignmentsResponse>({
        ...baseConfig,
        url,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    const templatedUrl = urlTemplate.parse(url).expand({});

    shipperAssignments.execute({
      url: templatedUrl,
      params,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params]);

  return shipperAssignments;
}

export type ShipperAssignmentsRequest = ReturnType<
  typeof useShipperAssignments
>;
